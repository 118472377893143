import { useField, useFormikContext } from "formik";
import React from "react";

export default function SharedDropdown({
  name,
  label,
  data,
  className,
  optionLabel,
  onItemAdd,
  ...props
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    const selectedValue = e.target.value;

    // Update the field value
    field.onChange(e);

    // If onItemAdd is provided and a value is selected, call it
    if (onItemAdd && selectedValue) {
      onItemAdd(selectedValue);

      // Reset the dropdown after adding the item
      setTimeout(() => setFieldValue(name, ""), 0);
    }
  };

  return (
    <div className="flex gap-1 flex-col">
      <label className="text-sm" htmlFor={name}>
        {label}
      </label>
      <select
        id={name}
        name={name}
        value={field.value}
        className={`rounded-md ${className}`}
        onChange={handleChange}
        {...props}
      >
        <option value="">{optionLabel}</option>
        {data &&
          data.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
      </select>
      {meta.touched && meta.error ? (
        <p className="max-w-2xl text-sm leading-6 text-red-500">{meta.error}</p>
      ) : null}
    </div>
  );
}
