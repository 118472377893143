import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Filter from "./components/Filter";
import { FunnelIcon, ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@apollo/client";
import CheckEligibilityModal from "./components/CheckEligibilityModal";
import EligibilityHeader from "./components/EligibilityHeader";
import EligibilityResponseModal from "./components/EligibilityResponseModal";
import eligibility from "../../gql/eligibility";
import AppErrorAlert from "../../components/AppErrorAlert";
// import { ToastContainer } from "react-toastify";
import DownloadExcel from "../../components/DownloadExcel";
import Pagination from "../../components/Pagination";
import FilterModal from "./components/requestEligibility/FilterModal";
import RequestEligibilityModal from "./components/requestEligibility/RequestEligibilityModal";

import "./styles/eligibility.css";
import "../Beneficiary/styles/BeneficiaryList.css";
import { Chip, Tooltip } from "@mui/material";
import AppModal from "../../components/AppModal";
import EligibilityJsonViewModal from "./EligibilityJsonViewModal";
import { BiSolidFileJson } from "react-icons/bi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BsFiletypeJson } from "react-icons/bs";

import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function EligibilityList(props) {
  const navigate = useNavigate();

  const [errorData, setErrorData] = useState([]);
  const [eligibilityLoading, setEligibilityLoading] = useState(false);

  const [excelData, setExcelData] = useState([
    { id: 1, name: "John Doe", email: "john.doe@example.com" },
    { id: 2, name: "Jane Doe", email: "jane.doe@example.com" },
    { id: 3, name: "Bob Smith", email: "bob.smith@example.com" },
  ]);

  // const [from_date, setFromDate] = useState(
  //   currentDate.toISOString().substring(0, 10)
  // );

  // const [to_date, setToDate] = useState(
  //   currentDate.toISOString().substring(0, 10)
  // );
  const currentDate = new Date();

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );

  const [open, setOpen] = useState(false);
  const [selectedItem, setSeelectedItem] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [filterDate, setFilterDate] = useState({
    from: fromDate,
    to: toDate,
  });

  const [item, setItem] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showEligibilityModal, setShowEligibilityModal] = useState(false);
  const [showEligibilityResponseModal, setShowEligibilityResponseModal] =
    useState(false);

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(
    +JSON?.parse(localStorage?.getItem("eligibilityPageNo")) || 1
  );

  const [refetchFilterVariables, setRefetchFilterVariables] = useState({
    limit: 10,
    from_date: fromDate,
    to_date: toDate,
  });

  const { loading, error, data, refetch } = useQuery(
    eligibility.GET_ELIGIBILITY_TRANSACTIONS,
    {
      variables: {
        input: {
          from_date: fromDate,
          to_date: toDate,
          limit: 10,
          page: pageNo,
        },
      },
      // fetchPolicy: "cache-and-network",
      fetchPolicy: "no-cache",
    }
  );

  const {
    loading: excelDataLoading,
    error: excelDataError,
    data: excelDataResponse,
    refetch: refetchExcelDataResponse,
  } = useQuery(eligibility.GET_TRANSACTIONS_IN_EXCEL, {
    variables: {
      input: {
        from_date: currentDate.toISOString().substring(0, 10),
        to_date: currentDate.toISOString().substring(0, 10),
      },
    },
    skip: true,
  });

  useEffect(() => {
    if (excelDataResponse) {
      setExcelData(
        JSON.parse(excelDataResponse?.listVerificationTransactionsExcel?.data)
      );
    }
  }, [excelDataResponse]);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;

  // const onPageChange = (currentPage) => {
  //   setPageNo(currentPage);
  //   let filterInput = {
  //     ...refetchFilterVariables,
  //     page: currentPage,
  //   };
  //   refetch({
  //     input: filterInput,
  //   });
  // };

  const onPageChange = async (currentPage) => {
    const eligibilityFilterDate = JSON.parse(
      localStorage.getItem("eligibilityFilterDate")
    );
    setPageNo(currentPage);

    // Function to remove empty keys
    const removeEmptyKeys = (obj) => {
      const newObj = {};
      for (const key in obj) {
        if (obj[key] !== "") {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    };

    // Remove empty keys from claimsFilterDate
    const filteredEligibilityFilterDate = removeEmptyKeys(
      eligibilityFilterDate
    );

    let filterInput = {
      ...filteredEligibilityFilterDate,
      page: currentPage,
      limit: limit,
    };

    try {
      setEligibilityLoading(true);
      await refetch({
        input: filterInput,
      });
    } finally {
      setEligibilityLoading(false);
    }
  };

  const checkEligibility = (el) => {
    if (el.site_eligibility == "eligible") {
      return <label className="badge badge-success">Eligible</label>;
    } else if (el.site_eligibility == "not-active") {
      return <label className="badge badge-info">{el.site_eligibility}</label>;
    } else if (!el.site_eligibility) {
      return <label className="badge badge-danger">unknown</label>;
    } else {
      return <label className="badge badge-info">{el.site_eligibility}</label>;
    }
  };

  const getColor = (eligibility) => {
    if (eligibility === "out-network") {
      return "primary";
    } else if (eligibility === "not-active") {
      return "secondary";
    } else if (eligibility === "eligible") {
      return "success";
    } else if (eligibility === "complete") {
      return "success";
    } else if (eligibility === "error") {
      return "error";
    } else {
      return "secondary";
    }
  };

  const tableHeaders = [
    { name: "SQ #" },
    { name: "Transaction Date" },
    { name: "Beneficiary Name" },
    { name: "Purpose" },
    { name: "Payer" },
    // { name: "Eligibility ID" },
    { name: "Site Eligibility" },
    { name: "Status" },
    // { name: "JSON" },
    { name: "Action" },
  ];

  const onDataRowClicked = (eligibilityItem) => {
    navigate("/eligibility/details", { state: { item: eligibilityItem } });
    // setItem(eligibilityItem);
    // setShowEligibilityResponseModal(true);
  };

  const formatDateTime = (time) => {
    const dateTime = new Date(time);
    const dateOnly = dateTime.toLocaleDateString();
    const timeOnly = dateTime.toLocaleTimeString();
    return `${dateOnly} ${timeOnly}`;
  };

  const tooltipText = (el) => {
    if (el?.site_eligibility === "eligible") {
      return "Eligible";
    } else if (el?.site_eligibility === "not-active") {
      return "Member's insurance policy is not active";
    } else if (el?.site_eligibility === "not-covered") {
      return "Policy does not cover the requested services";
    } else if (el?.site_eligibility === "not-direct-billing") {
      return "Patient is not covered on direct billing basis";
    } else if (el?.site_eligibility === "out-network") {
      return "Provider outside member Network";
    } else if (el?.site_eligibility === "limit-exhausted") {
      return "Patient policy/benefit limit is exhausted";
    } else if (el?.site_eligibility === "coverage-suspended") {
      return "Patient coverage is suspended";
    } else if (el?.site_eligibility === "provider-contract-suspended") {
      return "Provider contract is suspended";
    } else {
      // Default case
      return "Unknown eligibility status";
    }
  };

  const downloadExcelFile = (fileName) => {
    refetchExcelDataResponse({
      input: {
        // limit: limit,
        // page: pageNo,
        from_date: filterDate?.from,
        to_date: filterDate?.to,
      },
    }).then((res) => {
      const data = JSON.parse(
        res?.data?.listVerificationTransactionsExcel?.data
      );
      const workbook = XLSX.utils.book_new();
      const sheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const fileData = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(fileData, `${fileName}.xlsx`);
    });
  };

  return (
    <>
      <div class="eligibility_container">
        <div class="eligibility-page">
          <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
            <div className="">
              <Tooltip
                placement="right"
                title="Keep track of your Eligibility Transactions"
                className="my-auto !mt-2"
              >
                <span className="font-semibold text-black/70 text-lg ">
                  Eligibility Transactions
                  <span className="italic text-lg ml-1">
                    ({data?.listVerificationTransactions?.total || 0}{" "}
                    <span className="text-xs font-bold italic">Entries</span>)
                  </span>
                </span>
              </Tooltip>
            </div>
            <div className="flex gap-3 flex-col md:flex-row">
              {/* <DownloadExcel
                data={excelData}
                fileName={"EligibilityTransactions"}
              /> */}

              <Button
                variant="outlined"
                size="small"
                onClick={() => downloadExcelFile("Eligibilities")}
                className="!border-primary !text-primary hover:!bg-primary/10"
                startIcon={<FileDownloadOutlinedIcon />}
              >
                Download
              </Button>

              <Button
                variant="contained"
                size="small"
                onClick={() => navigate("/beneficiary/form")}
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                startIcon={<AddIcon />}
              >
                Create Beneficary
              </Button>

              <Button
                variant="contained"
                size="small"
                onClick={() => setShowEligibilityModal(true)}
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                startIcon={<AddIcon />}
              >
                Request Eligibility
              </Button>

              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowFilter(() => !showFilter)}
                className="!border-primary !text-primary hover:!bg-primary/10"
                startIcon={<FilterAltOutlinedIcon />}
              >
                Apply Filter
              </Button>
            </div>
            <RequestEligibilityModal
              showEligibilityModal={showEligibilityModal}
              setShowEligibilityModal={setShowEligibilityModal}
              errorData={errorData}
              setErrorData={setErrorData}
              refetch={refetch}
              fromDate={fromDate}
              toDate={toDate}
            />
          </div>
        </div>

        <div class="">
          {/* <div class="search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search"
            />
          </div> */}

          <FilterModal
            refetch={refetch}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setPageNo={setPageNo}
            setLimit={setLimit}
            // refetchExcelDataResponse={refetchExcelDataResponse}
            setFilterDate={setFilterDate}
            fromDate={fromDate}
            toDate={toDate}
            pageNo={pageNo}
          />
        </div>

        <div className="super-git-table !p-0 !m-0">
          <table className="table-super-git !p-0">
            <thead className="!p-0">
              <tr className="!p-0">
                {tableHeaders.map((el, index) => {
                  return (
                    <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                      {el.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.listVerificationTransactions?.data?.map((el, index) => {
                return (
                  <tr
                    className="!p-0"
                    key={"eligibilitiesData_" + index}
                    onClick={() => setSeelectedItem(el)}
                  >
                    {/* 1 */}
                    <td className="!p-1 !text-sm">
                      {pageNo * limit + index + 1 - limit}
                    </td>

                    {/* 2 */}
                    <td className="!p-1 !text-sm">
                      {formatDateTime(el?.transaction_date)}
                    </td>

                    {/* 3 */}
                    <td className="!p-0 capitalize text-start !text-sm">
                      {el?.patient_name.length > 100 ? (
                        <Tooltip title={el?.patient_name}>
                          <span>{el?.patient_name.slice(0, 100)}...</span>
                        </Tooltip>
                      ) : (
                        el?.patient_name
                      )}
                    </td>

                    {/* 4 */}
                    <td className="!p-1 !text-sm">{el?.purpose}</td>

                    {/* 5 */}
                    <td className="!p-1 capitalize !text-sm">
                      {el?.payer_name.length > 100 ? (
                        <Tooltip title={el?.payer_name}>
                          <span>{el?.payer_name.slice(0, 100)}...</span>
                        </Tooltip>
                      ) : (
                        el?.payer_name
                      )}
                    </td>

                    {/* 6 */}
                    <td
                      className="!p-1 capitalize !text-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={tooltipText(el)}
                    >
                      {el?.site_eligibility ? (
                        <Chip
                          label={el?.site_eligibility}
                          color={getColor(el?.site_eligibility)}
                          size="small"
                          className="uppercase !text-xs "
                        />
                      ) : (
                        ""
                      )}
                    </td>

                    {/* 7 */}
                    <td className="!p-1 capitalize !text-sm">
                      {el?.status ? (
                        <Chip
                          label={el?.status}
                          color={getColor(el?.status)}
                          size="small"
                          className="uppercase !text-xs "
                        />
                      ) : (
                        ""
                      )}
                    </td>

                    {/* 8 */}
                    <td className="user-action flex gap-2 justify-end items-center h-full mx-2 my-2.5 !p-1">
                      <Tooltip title="View">
                        <div
                          onClick={() => onDataRowClicked(el)}
                          className="text-xl cursor-pointer"
                        >
                          <MdOutlineRemoveRedEye />
                        </div>
                      </Tooltip>
                      <Tooltip title="JSON View">
                        <div
                          onClick={handleClickOpen}
                          className="text-xl cursor-pointer"
                        >
                          <BsFiletypeJson />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div>
          <Pagination
            currentPage={pageNo}
            totalPages={data?.listVerificationTransactions?.pages}
            onPageChange={onPageChange}
          />
        </div>
        <EligibilityResponseModal
          showEligibilityResponseModal={showEligibilityResponseModal}
          setShowEligibilityResponseModal={setShowEligibilityResponseModal}
          item={item}
        />
        <EligibilityJsonViewModal
          setOpen={setOpen}
          open={open}
          handleClose={handleClose}
          selectedItem={selectedItem}
        />
      </div>
      <AppModal isOpen={loading || excelDataLoading || eligibilityLoading} />
    </>
  );
}

export default EligibilityList;

// {
//   /* <div class="   flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
//             <div class="eligibility-title">
//               <span>Eligibility Transactions</span>
//               <div class="track-eligibility">
//                 <span>Keep track of your Eligibility Transactions</span>
//               </div>
//             </div>

//             <div class="create-eligibility mt-3 md:mt-0 search-filter">
//             <DownloadExcel
//               data={excelData}
//               fileName={"EligibilityTransactions"}
//             />
//             <div
//               class="create-btn"
//               onClick={() => navigate("/beneficiary/form")}
//             >
//               <span>Add Beneficary</span>
//             </div>
//             <div
//               class="create-btn"
//               onClick={() => setShowEligibilityModal(true)}
//             >
//               <span>Request Eligibility</span>
//             </div>
//             <div class="apply-filter">
//               <div class="btn" onClick={() => setShowFilter(() => !showFilter)}>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="20"
//                   height="20"
//                   viewBox="0 0 20 20"
//                   fill="none"
//                 >
//                   <path
//                     d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
//                     fill="black"
//                   />
//                 </svg>
//                 Apply Filter
//               </div>
//             </div>
//             <RequestEligibilityModal
//               showEligibilityModal={showEligibilityModal}
//               setShowEligibilityModal={setShowEligibilityModal}
//               errorData={errorData}
//               setErrorData={setErrorData}
//               refetch={refetch}
//               fromDate={from_date}
//               toDate={to_date}
//             />
//           </div>

//           </div> */
// }

// {
//   /* Eligibility */
// }
// {
//   /* <div class="super-git-table">
//           <table class="table-super-git">
//             <thead>
//               <tr>
//                 {tableHeaders.map((el, index) => {
//                   return <th key={"tableHeaders_" + index}>{el.name}</th>;
//                 })}
//               </tr>
//             </thead>
//             <tbody>
//               {data?.listVerificationTransactions?.data?.map((el, index) => {
//                 return (
//                   <tr
//                     key={"eligibilitiesData_" + index}
//                     onClick={() => setSeelectedItem(el)}
//                   >
//                     <td>{pageNo * limit + index + 1 - limit}</td>
//                     <td>{formatDateTime(el?.transaction_date)}</td>
//                     <td>{el?.patient_name}</td>
//                     <td>{el?.purpose}</td>
//                     <td>{el?.payer_name}</td>
//                     <td
//                       data-toggle="tooltip"
//                       data-placement="top"
//                       title={tooltipText(el)}
//                     >
//                       <Chip
//                         label={el?.site_eligibility || "Unknown Eligibility"}
//                         color={getColor(el?.site_eligibility || "unknown")}
//                       />
//                     </td>
//                     <td>
//                       <Chip
//                         label={el?.status || "Unknown Status"}
//                         style={{
//                           backgroundColor:
//                             el?.status === "error" ? "#f44336" : "#2E7D32",
//                           color: "white",
//                         }}
//                       />
//                     </td>

//                     <td className="user-action flex gap-2  justify-end items-center h-full my-auto mx-2">
//                       <Tooltip title="View">
//                         <div
//                           onClick={() => onDataRowClicked(el)}
//                           className="text-xl"
//                         >
//                           <MdOutlineRemoveRedEye />
//                         </div>
//                       </Tooltip>
//                       <Tooltip title="JSON View">
//                         <div onClick={handleClickOpen} className="text-xl">
//                           <BsFiletypeJson />
//                         </div>
//                       </Tooltip>
//                     </td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div> */
// }
