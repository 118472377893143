import { useField, useFormikContext } from "formik";
import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function SharedSearchDropdown({
  name,
  label,
  data,
  className,
  optionLabel,
  onItemAdd,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (event, newValue) => {
    helpers.setValue(newValue ? newValue.value : "");

    if (onItemAdd && newValue) {
      onItemAdd(newValue.value);
    }
  };

  const selectedOption = data
    ? data.find((item) => item.value === field.value)
    : null;

  return (
    <div className="flex gap-1 flex-col w-full">
      <label className="text-sm" htmlFor={name}>
        {label}
      </label>
      <Autocomplete
        id={name}
        options={data || []}
        className="!p-[2px] "
        getOptionLabel={(option) => option.label || ""}
        value={selectedOption}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={optionLabel}
            className={`${className} !border-[#6b7280]`}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            {...props}
          />
        )}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "0.375rem",
          },
        }}
      />
    </div>
  );
}
