import DragNdrop from "./DragNDrop";
import { Button, IconButton, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useEffect } from "react";
import priceList from "../../gql/priceList";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import AppModal from "../../components/AppModal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { styled } from "@mui/material/styles";
import { UploadExcelFile } from "../HIS/components/ApiMethods";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ImportPayerModal({
  showImportPriceListModal,
  setShowImportPriceListModal,
  payersData,
  refetchPriceList,
  pageNo,
  limit,
}) {
  const [file, setFileData] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  };

  const handleFile = (file) => {
    setLoadingFile(true);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const [headers, ...rows] = data;
      const keys = headers.map((header) => header.trim());
      const formattedRows = rows.map((row) => {
        const formattedRow = {};
        row.forEach((value, index) => {
          const key = keys[index];
          formattedRow[key] =
            key === "UnitPrice" || key === "factor" ? parseFloat(value) : value;
        });
        setLoadingFile(false);
        return formattedRow;
      });
      setLoadingFile(false);
      setFileData(formattedRows);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };
  const [createReq, { data, loading, error }] = useMutation(
    priceList.UPLOAD_PRICE_LIST,
    {
      update(proxy, result) {
        onSuccessfulCreateRequestResponse(result.data);
      },
      onError(error) {
        onCreateResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulCreateRequestResponse = (data) => {
    if (data?.uploadPriceListExcel) {
      toast.success(data?.uploadPriceListExcel?.message);
      formik.resetForm();
      setShowImportPriceListModal(false);
      refetchPriceList({
        input: {
          limit: limit,
          page: pageNo,
        },
      });
    } else {
    }
  };

  const onCreateResponseError = (error) => {
    toast.error(`${error?.message || "Error"}`);
  };

  const formik = useFormik({
    initialValues: {
      payer_id: "",
      effective_date: "",
      payerListFile: null,
      file: [],
    },

    validationSchema: () => {
      return Yup.object().shape({
        payer_id: Yup.string().required("Payer required"),
        effective_date: Yup.date().required("Effective Date required"),
        file: Yup.array()
          .max(4000, "File array length should not be more than 4000")
          .nullable(),
        payerListFile: Yup.mixed()
          .required("Excel file required")
          .test(
            "fileFormat",
            "Invalid file format. Please upload an Excel file.",
            (value) => {
              if (value instanceof File) {
                return (
                  value.type === "application/vnd.ms-excel" ||
                  value.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                );
              }
              return false;
            }
          ),
      });
    },

    onSubmit: (values) => {
      setShowImportPriceListModal(false);
      delete values.payerListFile;

      createReq({
        variables: {
          input: values,
        },
      }).then(() => {
        UploadExcelFile({ setShowLoader: setLoadingFile, data: values });
      });
    },
  });

  useEffect(() => {
    if (formik.values?.payerListFile) {
      handleFile(formik.values?.payerListFile);
    } else {
      setFileData([]);
    }
  }, [formik.values?.payerListFile]);

  useEffect(() => {
    formik.setFieldValue("file", file);
  }, [file]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => setShowImportPriceListModal(false)}
        aria-labelledby="customized-dialog-title"
        open={showImportPriceListModal}
        PaperProps={{
          className:
            "w-full !max-w-[750px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl ",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Upload Price List
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowImportPriceListModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="benefeciary-create">
            <div className="personal-data flex flex-col gap-4  !shadow-none !border-none">
              <div className="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="flex flex-col gap-1">
                  <label htmlFor="payer_id">Payer</label>
                  <select
                    id="payer_id"
                    name="payer_id"
                    onChange={formik.handleChange}
                    value={formik.values.payer_id}
                    autoComplete="payer_id"
                  >
                    <option value={""}>Select Payer</option>
                    {payersData?.map((category, index) => (
                      <optgroup
                        label={
                          category?.name_en
                            ? category?.name_en + ` (${category?.license_id})`
                            : ""
                        }
                        key={category.id}
                      >
                        {category.payers.map((insurance, index) => (
                          <option key={index} value={insurance.id}>
                            {insurance?.name_en} ({insurance?.license_id})
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                  {formik.errors.payer_id && formik.touched.payer_id ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.payer_id}
                    </p>
                  ) : null}
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="effective_date">Effective Date</label>
                  <input
                    placeholder="Enter Effective Date"
                    type="date"
                    name="effective_date"
                    value={formik.values?.effective_date?.substring(0, 10)}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "effective_date",
                        formatDate(e.target.value)
                      )
                    }
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    id="effective_date"
                    className="effective_date"
                  />

                  {formik.errors.effective_date &&
                  formik.touched.effective_date ? (
                    <p className=" max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.effective_date}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="section">
                <DragNdrop formik={formik} width="100%" height="100%" />
                {formik.errors.payerListFile && formik.touched.payerListFile ? (
                  <p className=" max-w-2xl text-sm leading-6 text-red-500">
                    {formik.errors.payerListFile}
                  </p>
                ) : null}

                {formik.errors.file && formik.touched.file ? (
                  <p className=" max-w-2xl text-sm leading-6 text-red-500">
                    {formik.errors.file}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            size="small"
            disabled={loadingFile}
            onClick={() => {
              formik.handleSubmit();
            }}
            className="!text-primary hover:!bg-primary/20"
          >
            {loading || loadingFile ? "..." : "Upload"}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <AppModal isOpen={loading || loadingFile} />
    </div>
  );
}
