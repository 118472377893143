import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useContext, useEffect } from "react";
import Filter from "./components/Filter";
import beneficiary from "../../gql/beneficiary";
import EditBeneficiaryModal from "./components/EditBeneficiaryModal";

import AddBeneficiaryModal from "./components/AddBeneficiaryModal";
import ConformationModal from "../../components/ConformationModal";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import { appContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import DownloadExcel from "../../components/DownloadExcel";
import FilterModal from "./components/modals/FilterModal";

import "./styles/BeneficiaryList.css";
import BeneficiaryDetailModal from "./components/BeneficiaryDetailModal";
import AppModal from "../../components/AppModal";
import { BsGenderMale, BsGenderFemale } from "react-icons/bs";
import { FaGenderless } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { TbEditCircle } from "react-icons/tb";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CustomPagination from "../HIS/components/CustomPagination";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  getPatientList,
  getPatientListDropdown,
} from "../HIS/components/ApiMethods";

function BeneficiaryList(props) {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState(false);
  const [selectedDetailID, setSelectedDetailID] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [excelData, setExcelData] = useState([
    { id: 1, name: "John Doe", email: "john.doe@example.com" },
    { id: 2, name: "Jane Doe", email: "jane.doe@example.com" },
    { id: 3, name: "Bob Smith", email: "bob.smith@example.com" },
  ]);
  const { permissions } = useContext(appContext);
  const [item, setItem] = useState({
    id: 11,
    document_id: 22,
    firsr_name: "zain",
    middle_name: "khan",
    full_name: "zain khan",
    family_name: "Royal",
    beneficiary_file_id: "11",
    dob: "2000",
    eHealth_id: "2030",
    nationality: "Pak",
    residency_type: "93",
    document_type: "344",
    contact_number: "432",
    martial_status: "salman",
    gender: "salman45",
    blood_group: "a",
    preferred_language: "234",
    emergency_number: "34",
    email: "3030",
    address_line: "",
    street_line: "34",
    city: "93",
    state: "344",
    country: "432",
    postal_code: "34",
    nphies_id: "93",
    providers_id: "344",
    is_new_born: true,
  });

  const [showDetails, setShowDetails] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    _id: 0,
    Address: "a",
    IqamaExpiry: "arbi@gmail.com",
    StartDate: "name",
    Department: "salman45",
    Gender: "2345",
    StatusId: 1,
  });

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(
    +JSON?.parse(localStorage?.getItem("beneficiariesPageNo"))
      ? +JSON?.parse(localStorage?.getItem("beneficiariesPageNo"))
      : 1
  );

  const {
    loading: excelDataLoading,
    error: excelDataError,
    data: excelDataResponse,
    refetch: refetchExcelDataResponse,
  } = useQuery(beneficiary.GET_PATIENTS_IN_EXCEL, {
    variables: { filters: { limit: limit, page: pageNo } },
    skip: true,
  });

  useEffect(() => {
    getPatientListDropdown(setLoading, setPatientList, {
      page: 1,
      perPage: 10,
    });
  }, []);
  console.log(patientList);

  useEffect(() => {
    if (excelDataResponse) {
      setExcelData(JSON.parse(excelDataResponse?.listPatientsExcel?.data));
    }
  }, [excelDataResponse]);

  const [delEntry, {}] = useMutation(beneficiary.DEL_BENEFICIARY, {
    update(proxy, result) {
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulRequestResponse = (data) => {
    if (data.deletePatient) {
      toast.success("Successfully Deleted!");
      refetch();
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error.message}`);
  };

  const { loading, error, data, refetch } = useQuery(
    beneficiary.GET_ALL_PATIENTS,
    {
      variables: { filters: { limit: limit, page: pageNo } },
    }
  );

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
  }, [error]);
  const onPageChange = (newPage, newRowsPerPage) => {
    getPatientListDropdown(setLoading, setPatientList, {
      page: newPage,
      perPage: newRowsPerPage,
    });
  };

  // const onPageChange = (currentPage) => {
  //   setPageNo(currentPage);
  //   refetch({ filters: { limit: limit, page: currentPage } });
  //   // refetchExcelDataResponse({ filters: { limit: limit, page: currentPage } });
  // };

  // if (error) return <p>Error: {error.message}</p>;

  const onClickDelete = (item) => {
    setCurrentItem(item);
    setShowDeleteAlert(true);
  };

  const deleteEntry = () => {
    delEntry({ variables: { input: { ID: currentItem._id } } });
    setShowDeleteAlert(false);
  };

  const onClickEdit = (el) => {
    navigate("/beneficiary/edit/form", { state: { item: el } });
    // setCurrentItem(el);
    // setShowEditModal(true);
  };

  const tableHeaders = [
    { name: "SQ #" },
    { name: "Full Name" },
    { name: "File No." },
    { name: "Document Type" },
    { name: "Document #" },
    { name: "Contact No." },
    { name: "Age" },
    // { name: "Actions" },
  ];

  const documentTypes = [
    { id: "VP", name: "VISA" },
    { id: "PPN", name: "Passport" },
    { id: "PRC", name: "Permanent Resident Card Number" },
    { id: "MR", name: "Medical record number" },
    { id: "NI", name: "National Card" },
  ];

  const handleViewClick = (el) => {
    setItem(el);
    setShowDetails(true);
    navigate("/beneficiary/details", { state: { item: el } });
  };

  function calculateAge(dateOfBirth) {
    // Parse the date of birth string into a Date object
    const dob = new Date(dateOfBirth);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - dob.getFullYear();

    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      // If not, subtract 1 from the age
      return age - 1;
    }

    // Otherwise, return the calculated age
    return age;
  }

  const downloadExcelFile = (fileName) => {
    refetchExcelDataResponse({ filters: { limit: limit, page: pageNo } }).then(
      (res) => {
        const data = JSON.parse(res?.data?.listPatientsExcel?.data);
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const fileData = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(fileData, `${fileName}.xlsx`);
      }
    );
  };

  return (
    <div className="superGit_container !p-0">
      <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
        <div className="">
          <Tooltip
            placement="right"
            title="Keep track of your beneficiaries accounts"
            className="my-auto !mt-2"
          >
            <span className="font-semibold text-black/70 text-lg ">
              BENEFICIARIES
              <span className="italic text-lg ml-1">
                ({patientList?.data?.length || 0}
                <span className="text-xs font-bold italic">Entries</span>)
              </span>
            </span>
          </Tooltip>
        </div>
        <div className="flex gap-3 flex-col md:flex-row">
          {/* <DownloadExcel data={excelData} fileName={"Beneficiaries"} /> */}
          {/* <Button
            variant="outlined"
            onClick={() => downloadExcelFile("Beneficiaries")}
            className="!border-primary !text-primary hover:!bg-primary/10"
            startIcon={<FileDownloadOutlinedIcon />}
            size="small"
          >
            Download
          </Button> */}
          <Button
            variant="contained"
            onClick={() =>
              navigate("/beneficiary/form", { state: { name: "b-form" } })
            }
            style={{
              backgroundImage:
                "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
            }}
            startIcon={<AddIcon />}
            size="small"
          >
            Create Beneficiary
          </Button>
          <Button
            variant="outlined"
            onClick={() => setShowFilter(() => !showFilter)}
            className="!border-primary !text-primary hover:!bg-primary/10"
            startIcon={<FilterAltOutlinedIcon />}
            size="small"
          >
            Apply Filter
          </Button>
        </div>
      </div>

      {/* <div className="search-filter"> */}
      {/* <div className="search">
          <input type="search" name="search" id="search" placeholder="Search" />
        </div> */}

      <FilterModal
        refetch={refetch}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        setPageNo={setPageNo}
        setLimit={setLimit}
        pageNo={pageNo}
        // refetchExcelDataResponse={refetchExcelDataResponse}
      />
      {/* </div> */}
      <div className="super-git-table !p-0 !m-0">
        <table className="table-super-git !p-0">
          <thead className="!p-0">
            <tr className="!p-0">
              {tableHeaders.map((el, index) => {
                return (
                  <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {patientList?.data?.map((el, index) => {
              return (
                <tr className="!p-0" key={"beneficiariesData_" + index}>
                  <td className="!p-1 !text-sm">
                    {pageNo * limit + index + 1 - limit}
                  </td>
                  <td className="!p-1 !text-sm capitalize text-start">
                    {el?.full_name?.length > 100 ? (
                      <Tooltip title={el?.full_name}>
                        <span>{el?.full_name.slice(0, 100)}...</span>
                      </Tooltip>
                    ) : (
                      el?.full_name
                    )}
                  </td>
                  <td className="!p-1 !text-sm">{el.file_no}</td>
                  <td className="!p-1 !text-sm">
                    {el?.document_type
                      ? documentTypes.find((doc) => doc.id === el.document_type)
                          ?.name
                      : ""}
                  </td>

                  <td className="!p-1 !text-sm">{el.document_id}</td>
                  <td className="!p-1 !text-sm">{el.contact}</td>

                  <td className="!p-1 !text-sm">
                    <div className="flex gap-2 justify-center">
                      <div>{calculateAge(el.dob)}</div>
                      <div className="mt-1">
                        {el.gender === "male" ? (
                          <BsGenderMale />
                        ) : el.gender === "female" ? (
                          <BsGenderFemale />
                        ) : (
                          <FaGenderless />
                        )}
                      </div>
                    </div>
                  </td>
                  {/* <td>{el.providers_name_en}</td> */}
                  {/* <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                    <Tooltip title="View">
                      <div
                        onClick={() => {
                          handleClickOpen();
                          setSelectedDetailID(el.id);
                        }}
                        className="text-xl cursor-pointer"
                        // style={{ cursor: "pointer" }}
                      >
                        <MdOutlineRemoveRedEye />
                      </div>
                    </Tooltip>

                    <Tooltip title="Edit">
                      <div
                        onClick={() => onClickEdit(el)}
                        className="text-xl cursor-pointer"
                      >
                        <TbEditCircle />
                      </div>
                    </Tooltip>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        {/* <Pagination
          currentPage={pageNo}
          totalPages={data?.listPatients?.pages}
          onPageChange={onPageChange}
        /> */}
        <CustomPagination
          currentPage={patientList?.page || 1}
          rowsPerPage={patientList?.perPage || 5}
          totalRecords={patientList?.totalCount || 0}
          onPageChange={onPageChange}
        />
      </div>
      <AddBeneficiaryModal
        item={currentItem}
        showBeneficiaryModal={showEditModal}
        setShowBeneficiaryModal={setShowEditModal}
        refetch={refetch}
      />
      <ConformationModal
        open={showDeleteAlert}
        setOpen={setShowDeleteAlert}
        onDelete={deleteEntry}
      />

      <BeneficiaryDetailModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        selectedDetailID={selectedDetailID}
        setSelectedDetailID={setSelectedDetailID}
      />
      <AppModal isOpen={loading} />
    </div>
  );
}

export default BeneficiaryList;

// {/* <div className="benefeciaries-title">
//     <span>Beneficiaries</span>
//     <div className="track-beneficiaries">
//       <span>Keep track of your beneficiaries accounts</span>
//     </div>
//   </div>
//   <div className="create-benefeciaries mt-3 md:mt-0 search-filter">

//     <div
//       className="create-btn"
//       onClick={() => navigate("/beneficiary/form")}
//     >
//       <span>Create Beneficiary</span>
//     </div>

//     <div className="apply-filter">
//       <div
//         className="btn"
//         onClick={() => setShowFilter(() => !showFilter)}
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="20"
//           height="20"
//           viewBox="0 0 20 20"
//           fill="none"
//         >
//           <path
//             d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
//             fill="black"
//           />
//         </svg>
//         Apply Filter
//       </div>
//     </div>
//   </div> */}

//     {/* {permissions.includes("edit-patient") && (
//               <span
//                 className="view"
//                 onClick={() => handleViewClick(el)}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                 >
//                   <path
//                     d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.79 17.5 12 17.5C8.21 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
//                     fill="#4D4D53"
//                   />
//                 </svg>
//               </span>
//             )} */}

//                 {/* {permissions.includes("edit-patient") && (
//               <span className="edit" onClick={() => onClickEdit(el)}>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                 >
//                   <path
//                     d="M2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19L2.99878 17.25ZM5.91878 19H4.99878V18.08L14.0588 9.02L14.9788 9.94L5.91878 19ZM20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3C17.3988 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63Z"
//                     fill="#4D4D53"
//                   />
//                 </svg>
//               </span>
//             )} */}

//             {/* {permissions.includes("edit-patient") && (
//               <span
//                 className="delete"
//                 onClick={() => onClickDelete(el)}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                 >
//                   <path
//                     d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
//                     fill="#4D4D53"
//                   />
//                 </svg>
//               </span>
//             )} */}
