import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import toast from "react-hot-toast";
import AppModal from "../../../components/AppModal";
import { Button, Tooltip } from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useLocation, useNavigate } from "react-router-dom";

function PatientDetailForm({ setChiReqRecord }) {
  const [chiLoading, setChiLoading] = useState(false);
  const location = useLocation();
  const Statename = location?.state?.name;

  const navigate = useNavigate();
  const addData = async (values) => {
    setChiLoading(true);
    try {
      const token = localStorage.getItem("token");
      const tokenWithoutQuotes = token.replace(/"/g, "");
      const response = await fetch(
        `https://api.super-git.com/api/check_his_insurance?id=${values.nationalId}&type=${values.systemType}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenWithoutQuotes}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setChiLoading(false);

        // Use the json method to parse the response body as JSON

        const result = await response.json();

        const unescapedString = result?.data?.replace(/\\"/g, '"');

        // Parse the string as JSON

        const jsonObject = JSON.parse(unescapedString);
        if (jsonObject.ApiStatus === "Fail") {
          toast.error(jsonObject.ErrorDescription);
          setChiReqRecord(null);
        } else if (jsonObject.ApiStatus === "Success") {
          setChiReqRecord(jsonObject);
          toast.success("Data Retrieve Successfully!");
        }
      } else {
        setChiLoading(false);
        throw new Error("Response Issue");
      }
    } catch (error) {
      setChiLoading(false);
      console.log(error);
    }
  };

  // const systemTypes = [
  //   { id: "HIDP", name: "Nationals And Residents" },
  //   { id: "VIDP", name: "Visitors" },
  //   { id: "SCTH", name: "Tourists" },
  //   { id: "UIDP", name: "Premium Residents" },
  //   { id: "HUIDP", name: "Hajj & Umrah Visitors" },
  // ];

  const systemTypes = [
    { id: 1, name: "Nationals And Residents" },
    { id: 2, name: "Visitors" },
    { id: 3, name: "Tourists" },
    { id: 4, name: "Premium Residents" },
    { id: 5, name: "Hajj & Umrah Visitors" },
  ];

  const validationSchema = Yup.object().shape({
    systemType: Yup.number().required("System Type is required"),
    nationalId: Yup.number().required("National Id is required"),
  });

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <Formik
        initialValues={{
          systemType: 1,
          nationalId: "",
        }}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          addData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,

          /* and other goodies */
        }) => (
          <div class="patient-details !mb-3">
            {/* <div class="details-title">
              <div class="note-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 4H14.82C14.4 2.84 13.3 2 12 2C10.7 2 9.6 2.84 9.18 4H5C3.9 4 3 4.9 3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM12 3.75C12.41 3.75 12.75 4.09 12.75 4.5C12.75 4.91 12.41 5.25 12 5.25C11.59 5.25 11.25 4.91 11.25 4.5C11.25 4.09 11.59 3.75 12 3.75ZM19 20H5V6H19V20Z"
                    fill="#F5793B"
                  />
                  <path
                    d="M15.08 12.03L12.96 9.91L7 15.86V18H9.1L15.08 12.03Z"
                    fill="#F5793B"
                  />
                  <path
                    d="M16.85 10.27C17.05 10.07 17.05 9.76 16.85 9.56L15.44 8.15C15.24 7.95 14.93 7.95 14.73 8.15L13.67 9.21L15.79 11.33L16.85 10.27Z"
                    fill="#F5793B"
                  />
                </svg>
              </div>
              <div>
                <span>Search for Patient Details</span>
              </div>
            </div> */}

            <div class="benefeciary-top !p-0 !bg-transparent !shadow-none !border-none !mb-2">
              {Statename === "b-form" && (
                <div class="back" onClick={handleGoBack}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                      fill="#4D4D53"
                    />
                  </svg>
                </div>
              )}
              <div className="">
                <Tooltip
                  placement="right"
                  title="Keep track of your Beneficiary account"
                  className="my-auto !mt-2"
                >
                  <span className="font-semibold text-black/70 text-lg ">
                    {Statename === "b-form"
                      ? "Create New Beneficiary"
                      : "Create New Patient"}
                    <br />
                  </span>
                </Tooltip>
              </div>
            </div>

            <div class="details-fields grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-3">
              <div className="flex gap-1 flex-col">
                <label className="text-sm">System Type*</label>

                <select
                  id="systemType"
                  name="systemType"
                  value={values.systemType}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg"
                >
                  <option value={""}>Select System Type</option>
                  {systemTypes.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errors.systemType && touched.systemType ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.systemType}
                  </p>
                ) : null}
              </div>

              <div className="flex gap-1 flex-col">
                <label className="text-sm">National ID or Iqama*</label>
                <input
                  placeholder="Enter National ID or Iqama"
                  type="text"
                  name="nationalId"
                  value={values.nationalId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="national-id"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />

                {errors.nationalId && touched.nationalId ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.nationalId}
                  </p>
                ) : null}
              </div>

              {/* <div class="inquire-btn" onClick={handleSubmit}>
                  <span>Inquire CCHI</span>
                </div> */}

              <div className="flex items-end mb-0.5">
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  size="small"
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    height: "40px",
                    marginBottom:
                      errors && Object.keys(errors).length > 0 ? "28px" : "0",
                  }}
                  startIcon={<PersonSearchIcon />}
                  className=""
                >
                  Inquire CCHI
                </Button>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <AppModal isOpen={chiLoading} />
    </>
  );
}

export default PatientDetailForm;
