import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ContextProvider, { appContext } from "../context/ContextProvider";
// import BeneficiaryForm from "../pages/Beneficiary/components/AddBeneficiaryPersonalDataForm";

import Dashboard from "../pages/Dashboard/Dashboard";
import PreAuth from "../pages/PreAuth";
import Claims from "../pages/Claims";
import Physician from "../pages/Physicians";
import PriceList from "../pages/PriceList";
import PreAuthList from "../pages/PreAuth/PreAuthList";
import PreAuthForm from "../pages/PreAuth/components/PreAuthForm";
import Payments from "../pages/Payments";
import PaymentsList from "../pages/Payments/PaymentsList";
import Eligibility from "../pages/Eligibility";
import EligibilityList from "../pages/Eligibility/EligibilityList";
import Beneficiary from "../pages/Beneficiary";
import BeneficiaryForm from "../pages/Beneficiary/BeneficiaryForm";
import BeneficiaryList from "../pages/Beneficiary/BeneficiaryList";
import BeneficiaryDetails from "../pages/Beneficiary/BeneficiaryDetails";
import EligibilityDetails from "../pages/Eligibility/components/responseEligbility/EligibilityDetails";
import PreAuthDetails from "../pages/PreAuth/components/PreAuthDetails";
import ClaimsList from "../pages/Claims/ClaimsList";
import SubmitClaims from "../pages/Claims/SubmitClaims";
import ClaimsForm from "../pages/Claims/components/ClaimsForm";
import ClaimsDetails from "../pages/Claims/components/ClaimsDetails";
import EditBeneficiaryForm from "../pages/Beneficiary/EditBeneficiaryForm";
import PhysiciansList from "../pages/Physicians/PhysiciansList";
import PhysiciansForm from "../pages/Physicians/PhysiciansForm";
import PriceListMain from "../pages/PriceList/PriceListMain";
import PriceListSpecificPayer from "../pages/PriceList/PriceListSpecificPayer";
import ClaimHistoryList from "../pages/Claims/ClaimHistoryList";
import ClaimUploadList from "../pages/Claims/ClaimUploadList";
// import MainDash from "../pages/Dashboard/components/MainDash/MainDash";
import HIS from "../pages/HIS";
import Patient from "../pages/HIS/Patient";
import Practitioner from "../pages/HIS/Practitioner";
import NewAppointment from "../pages/HIS/components/NewAppointment";
import InvoiceMain from "../pages/HIS/components/InvoiceMain";
import ShowInvoice from "../pages/HIS/components/ShowInvoice";
import CashTable from "../pages/HIS/components/CashTable";
import InsuranceTable from "../pages/HIS/components/InsuranceTable";
import NewAppointmentMain from "../pages/HIS/components/NewAppointmentMain";
import InsurancePolicyMain from "../pages/HIS/components/InsurancePolicyMain";
import InsurancePolicyForm from "../pages/HIS/components/InsurancePolicyForm";
import EmrForm from "../pages/HIS/components/EmrForm";
import PatientAppoinmentHistory from "../pages/HIS/components/PatientAppoinmentHistory";
import HisPayers from "../pages/HIS/HisPayers";
import PayersForm from "../pages/HIS/components/PayersForm";

function Router(props) {
  const { user, setUser, setToken, setPermissions, permissions } =
    useContext(appContext);
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />}>
        {/* <Route index element={<MainDash />} /> */}
      </Route>
      <Route path="beneficiary" element={<Beneficiary />}>
        <Route index element={<BeneficiaryList />} />
        <Route path="form" element={<BeneficiaryForm />} />
        <Route path="edit/form" element={<EditBeneficiaryForm />} />
        <Route path="details" element={<BeneficiaryDetails />} />
      </Route>
      <Route path="eligibility" element={<Eligibility />}>
        <Route index element={<EligibilityList />} />
        <Route path="details" element={<EligibilityDetails />} />
      </Route>
      <Route path="preauth" element={<PreAuth />}>
        <Route index element={<PreAuthList />} />
        <Route path="form" element={<PreAuthForm />} />
        <Route path="details" element={<PreAuthDetails />} />
        <Route path="details/:id" element={<PreAuthDetails />} />
        <Route path="edit/form/:id" element={<PreAuthForm />} />
        <Route path="edit/form/:id/:followUp" element={<PreAuthForm />} />
      </Route>
      <Route path="claims" element={<Claims />}>
        <Route index element={<ClaimsList />} />
        <Route path="submit" element={<SubmitClaims />} />
        <Route path="form" element={<ClaimsForm />} />
        <Route path="edit/form/:id" element={<ClaimsForm />} />
        <Route path="edit/form/:id/:followUp" element={<ClaimsForm />} />
        <Route path="details" element={<ClaimsDetails />} />
        <Route path="details/:id" element={<ClaimsDetails />} />
        <Route path="history" element={<ClaimHistoryList />} />
        <Route path="uploads" element={<ClaimUploadList />} />
      </Route>
      <Route path="physicians" element={<Physician />}>
        <Route index element={<PhysiciansList />} />
        <Route path="form" element={<PhysiciansForm />} />
        <Route path="edit/form/:id" element={<PhysiciansForm />} />
      </Route>
      <Route path="price-list" element={<PriceList />}>
        <Route index element={<PriceListMain />} />
        <Route
          path="specific/:payerId/:effectiveDate/:payerName"
          element={<PriceListSpecificPayer />}
        />
      </Route>
      <Route path="payments" element={<Payments />}>
        <Route index element={<PaymentsList />} />
      </Route>

      <Route path="his" element={<HIS />}>
        <Route element={<Patient />} index />
        <Route element={<EmrForm />} path="emr" />
        <Route element={<Patient />} path="patient" />
        <Route element={<HisPayers />} path="payers" />
        <Route element={<PayersForm />} path="payers_form" />
      </Route>
      <Route path="receptionist" element={<HIS />}>
        <Route element={<NewAppointment />} path="create-visit" />
        <Route element={<NewAppointmentMain />} path="visits" />
        <Route element={<InvoiceMain />} path="invoice" />
        <Route element={<ShowInvoice />} path="show-invoice" />
        <Route element={<CashTable />} path="cash_table" />
        <Route element={<InsuranceTable />} path="insurance_table" />
        <Route element={<InsurancePolicyMain />} path="insurance_policy" />
        <Route element={<InsurancePolicyForm />} path="insurance_policy_form" />
      </Route>

      <Route path="*" element={<Navigate to="dashboard" />} />
    </Routes>
  );
}

export default Router;
