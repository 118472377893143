import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Chip,
  Box,
  Divider,
  Button,
} from "@mui/material";
import {
  FaCalendarAlt,
  FaUser,
  FaStethoscope,
  FaInfoCircle,
  FaAllergies,
  FaDiagnoses,
  FaUserMd,
  FaHospital,
  FaSyringe,
  FaDoorOpen,
  FaArrowLeft,
  FaPlus,
} from "react-icons/fa";

import { IconButton } from "@mui/material";
import UcafForm from "./UcafForm";
import DcafForm from "./DcafForm";

const formatDateTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(dateTimeString).toLocaleDateString(undefined, options);
};

const EmrCard = ({ entry, setData }) => {
  const [showUCAF, setShowUCAF] = useState(false);
  const [showDCAF, setShowDCAF] = useState(false);
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "completed":
        return "#4caf50";
      case "pending":
        return "#2196f3";
      case "cancelled":
        return "#f44336";
      case "booked":
        return "#9e9e9e";
      case "waitlisted":
        return "#ff9800";
      default:
        return "#9e9e9e";
    }
  };

  return (
    <Card
      sx={{ borderRadius: 2, boxShadow: "0 2px 4px rgba(0,0,0,0.1)", mb: 2 }}
    >
      <CardHeader
        title={
          <Typography variant="h6" fontWeight="bold">
            <IconButton
              onClick={() => setData(null)}
              aria-label="go back"
              className="!mr-2 !w-[30px] !h-[30px] !text-white !bg-red-400 !rounded-full"
            >
              <FaArrowLeft />
            </IconButton>
            EMR Entry
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2">
            {formatDateTime(entry?.date || new Date())}
          </Typography>
        }
        action={
          // <Chip
          //   label={entry?.status || "N/A"}
          //   size="small"
          //   sx={{
          //     backgroundColor: getStatusColor(entry?.status),
          //     color: "white",
          //     fontWeight: "bold",
          //   }}
          // />
          <div className="pt-[15px] pr-[15px]">
            <Button
              variant="contained"
              onClick={() => setShowUCAF(true)}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<FaPlus />}
              size="small"
            >
              UCAF
            </Button>
            {/* <Button
              variant="contained"
              onClick={() => setShowDCAF(true)}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<FaPlus />}
              size="small"
            >
              DCAF
            </Button> */}
            <UcafForm
              showUCAF={showUCAF}
              setShowUCAF={setShowUCAF}
              item={entry}
            />
            <DcafForm
              showDCAF={showDCAF}
              setShowDCAF={setShowDCAF}
              item={entry}
            />
          </div>
        }
        sx={{ borderBottom: "1px solid #e0e0e0", padding: 1 }}
      />
      <CardContent sx={{ padding: 1.5 }}>
        <DataSection
          title="Patient Information"
          icon={<FaUser />}
          data={[
            { label: "ID", value: entry?.patientID },
            { label: "Readmission", value: entry?.readmission },
            { label: "Service", value: entry?.serviceType },
            { label: "State", value: entry?.state },
            { label: "Street", value: entry?.street },
          ]}
        />
        <DataSection
          title="Items"
          icon={<FaInfoCircle />}
          data={entry?.items
            ?.map((item, index) => [
              { label: `Item ${index + 1} Name`, value: item?.name },
              {
                label: `Item ${index + 1} Standard Code`,
                value: item?.standardCode,
              },
              {
                label: `Item ${index + 1} Standard Code Description`,
                value: item?.standardCodeDescription,
              },
            ])
            ?.flat()}
        />

        <DataSection
          title="Supporting Information"
          icon={<FaInfoCircle />}
          data={[
            { label: "Category", value: entry?.supportingInfos[0]?.category },
            { label: "Code", value: entry?.supportingInfos[0]?.code },
            { label: "Value", value: entry?.supportingInfos[0]?.value },
          ]}
        />
        <DataSection
          title="Care Team"
          icon={<FaUserMd />}
          data={[
            { label: "Role", value: entry?.careTeamsArr[0]?.care_team_role },
            { label: "Name", value: entry?.careTeamsArr[0]?.practitionerName },
            {
              label: "Qualification",
              value: entry?.careTeamsArr[0]?.qualification,
            },
          ]}
        />
        <DataSection
          title="Diagnosis"
          icon={<FaDiagnoses />}
          data={[
            {
              label: "Description",
              value: entry?.diagnosisArr[0]?.codeDescription,
            },
            {
              label: "On Admission",
              value: entry?.diagnosisArr[0]?.on_admission,
            },
            { label: "Type", value: entry?.diagnosisArr[0]?.type },
          ]}
        />
        <DataSection
          title="Allergy Intolerance"
          icon={<FaAllergies />}
          data={[
            {
              label: "Category",
              value: entry?.allergyIntolerance[0]?.category,
            },
            { label: "Type", value: entry?.allergyIntolerance[0]?.type },
            {
              label: "Onset",
              value: formatDateTime(
                entry?.allergyIntolerance[0]?.onsetDateTime
              ),
            },
            {
              label: "Recorded",
              value: formatDateTime(entry?.allergyIntolerance[0]?.recordedDate),
            },
          ]}
        />
        <DataSection
          title="Encounter"
          icon={<FaHospital />}
          data={[
            { label: "Class", value: entry?.encounterClass },
            { label: "Priority", value: entry?.encounterPriority },
          ]}
        />
        <DataSection
          title="Immunization"
          icon={<FaSyringe />}
          data={[
            {
              label: "Occurrence",
              value: formatDateTime(entry?.immunization?.occurrenceDateTime),
            },
          ]}
        />
        <DataSection
          title="Accident Information"
          icon={<FaInfoCircle />}
          data={[
            { label: "Type", value: entry?.accidentType },
            { label: "Admin Source", value: entry?.adminSource },
          ]}
        />
        <DataSection
          title="Discharge"
          icon={<FaDoorOpen />}
          data={[{ label: "Disposition", value: entry?.dischargeDisposition }]}
        />
      </CardContent>
    </Card>
  );
};

const DataSection = ({ title, icon, data }) => (
  <Box sx={{ mb: 1 }}>
    <Typography
      variant="subtitle2"
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        mb: 1,
        color: "#333",
      }}
    >
      {React.cloneElement(icon, { style: { marginRight: 8, fontSize: 20 } })}
      {title}
    </Typography>
    <Grid container spacing={1}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", color: "#555" }}
            >
              {item.label}:
            </Typography>
            <Typography variant="body2" sx={{ color: "#666" }}>
              {item.value || "N/A"}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
    <Divider sx={{ mt: 1 }} />
  </Box>
);

export default EmrCard;
