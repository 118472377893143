import React, { useState } from "react";
import { Card, CardHeader, CardContent, CardActions } from "@mui/material";
import { Chip, Tooltip, IconButton, Typography } from "@mui/material";
import {
  MdOutlineRemoveRedEye,
  MdOutlineEdit,
  MdOutlineDelete,
} from "react-icons/md";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import {
  getAppoinmentsByID,
  getAppoinmentsList,
  updateAppointment,
} from "./ApiMethods";
import AppLoader from "../../../components/AppLoader";

export const formatDateTime = (isoDateString) => {
  const dateObj = new Date(isoDateString);
  const formattedDate = dateObj.toLocaleDateString();
  const formattedTime = dateObj.toLocaleTimeString();
  return `${formattedDate} ${formattedTime}`;
};
const SmallEmrCard = ({ emr, setData }) => {
  const getStatusSeverity = (status) => {
    switch (status.toLowerCase()) {
      case "fulfilled":
        return "success";
      case "pending":
        return "info";
      case "cancelled":
        return "error";
      case "booked":
        return "default";
      case "waitlisted":
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <>
      <Card className="shadow-md hover:shadow-lg transition-shadow duration-300 p-1">
        <CardHeader
          title={
            <Typography variant="h6" component="div" sx={{ fontSize: ".8rem" }}>
              {formatDateTime(emr?.created_at)}
            </Typography>
          }
          action={
            <Chip
              label={emr?.status}
              color={getStatusSeverity(emr?.status)}
              size="small"
              className="!mr-[2px]"
            />
          }
          className="bg-gray-100 p-2"
        />
        <CardContent className="text-xs">
          <div className="flex justify-between">
            <span className="font-semibold">Doctor Name:</span>
            <span>
              {emr?.careTeamsArr && emr?.careTeamsArr[0]?.practitionerName}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Care Team Role </span>
            <span>{emr?.careTeamsArr[0]?.care_team_role}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Readmission:</span>
            <span>{emr?.readmission}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">serviceType:</span>
            <span>{emr?.serviceType}</span>
          </div>
        </CardContent>

        <CardActions className="flex justify-end">
          <Tooltip title="View">
            <IconButton className="!mt-[15px] !p-0">
              <MdOutlineRemoveRedEye
                size={25}
                className="text-black"
                onClick={() => setData(emr)}
              />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );
};

export default SmallEmrCard;
