import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  getAppoinmentsByID,
  getAppoinmentsList,
  getPractitionersList,
  GetTodayAppointment,
} from "./ApiMethods";
import PatientCard from "./PatientCard";
import AppLoader from "../../../components/AppLoader";
import { Button, Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

export default function PatientAppoinmentHistory({
  data,
  patientID,
  setAppointmentList,
  app,
  doctor,
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [Doctor, SetDoctors] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [appointments, setAppointments] = useState();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    GetTodayAppointment(setLoading, setAppointments);
  }, []);

  const fetchAppointments = useCallback(
    async (reset = false) => {
      if (loading || (!hasMore && !reset)) return;

      setLoading(true);
      try {
        const currentPage = reset ? 1 : page;
        if (patientID) {
          const result = await getAppoinmentsByID(
            setLoading,
            {
              status: status ? status : "All",
              patient_id: patientID,
              page: currentPage,
              perPage: 3,
            },
            undefined
          );

          if (result?.data?.length) {
            setAppointments((prev) =>
              reset ? result.data : [...prev, ...result.data]
            );
            setPage((prev) => (reset ? 2 : prev + 1));
            setHasMore(result.data.length === 3);
          } else {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    },
    [page, loading, hasMore, patientID, status]
  );

  const lastAppointmentRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchAppointments();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, fetchAppointments]
  );

  useEffect(() => {
    if (patientID) {
      fetchAppointments(true);
    }
  }, [status, patientID]);

  useEffect(() => {
    setAppointments(data?.data || []);
  }, [data]);

  const getAppByStatus = (newStatus) => {
    setStatus(newStatus);
    setPage(1);
    setAppointments([]);
    setHasMore(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className={`my-2 flex justify-end`}>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ className: "p-4 w-64" }}
        >
          <div className="grid grid-cols-1 gap-2">
            {["All", "Fulfilled", "Cancelled", "Pending", "Waitlisted"].map(
              (statusOption) => (
                <Button
                  key={statusOption}
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => {
                    getAppByStatus(statusOption);
                    setOpen(false);
                  }}
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                  }}
                  className="flex w-full justify-center !rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 !normal-case"
                >
                  {statusOption.toUpperCase()}
                </Button>
              )
            )}
          </div>
        </Drawer>

        <div
          className={`flex justify-center items-center gap-2 ${
            app ? "w-1/2" : "w-auto"
          }`}
        >
          {app && (
            <Button
              variant="contained"
              type="button"
              size="small"
              onClick={() => navigate("/receptionist/create-visit")}
              className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#f14f3e] to-[#fab768] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case"
            >
              CREATE Visit
            </Button>
          )}
          <Button
            variant="outlined"
            type="button"
            size="small"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={() => setOpen(true)}
            className="flex w-full justify-center rounded-md px-3 !border-primary !text-primary  hover:!bg-primary/10 py-1.5 text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case"
          >
            APPLY FILTER
          </Button>
        </div>
      </div>

      <div
        ref={scrollRef}
        className="flex-grow overflow-y-auto"
        style={{ height: "calc(100vh - 100px)" }}
      >
        <div className="grid grid-cols-1 gap-3">
          {appointments?.length > 0 ? (
            appointments?.map((appointment, index) => (
              <div
                key={appointment.id}
                ref={
                  index === appointments.length - 1 ? lastAppointmentRef : null
                }
              >
                <PatientCard
                  appointment={appointment}
                  setAppointmentList={setAppointmentList}
                  patientID={patientID}
                  Doctors={Doctor}
                  doctor={doctor}
                  fetchAppointments={fetchAppointments}
                />
              </div>
            ))
          ) : (
            <div className="flex w-full h-[40vh] items-center justify-center">
              <h1 className="text-2xl font-bold text-center">No Visit Found</h1>
            </div>
          )}
        </div>
      </div>

      {loading && (
        <div className="flex w-full h-[40vh] items-center justify-center">
          <AppLoader setShowLoader={setLoading} showLoader={loading} />
        </div>
      )}
    </div>
  );
}
