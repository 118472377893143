import React, { useState } from "react";
import { Card, CardHeader, CardContent, CardActions } from "@mui/material";
import { Chip, Tooltip, IconButton, Typography } from "@mui/material";
import {
  MdOutlineRemoveRedEye,
  MdOutlineEdit,
  MdOutlineDelete,
} from "react-icons/md";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import {
  getAppoinmentsByID,
  getAppoinmentsList,
  updateAppointment,
} from "./ApiMethods";
import AppLoader from "../../../components/AppLoader";
import toast from "react-hot-toast";

export const formatDateTime = (isoDateString) => {
  const dateObj = new Date(isoDateString);
  const formattedDate = dateObj.toLocaleDateString();
  const formattedTime = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${formattedDate} ${formattedTime}`;
};

const PatientCard = ({
  appointment,
  setAppApiloading,
  setAppointmentList,
  patientID,
  Doctors,
  doctor,
  fetchAppointments,
}) => {
  const [open, setOpen] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(appointment.status);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStatusSeverity = (status) => {
    switch (status.toLowerCase()) {
      case "fulfilled":
        return "success";
      case "paid":
        return "success";
      case "pending":
        return "info";
      case "cancelled":
        return "error";
      case "unpaid":
        return "error";
      case "booked":
        return "default";
      case "waitlisted":
        return "warning";
      default:
        return "default";
    }
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setAppointmentId(appointment.visit_id);
    handleClickOpen();
  };

  function getDoctorName(id) {
    return Doctors?.data?.find((doc) => doc?.practitioner_id === id).name;
  }

  const data = [
    { value: "Waitlisted", label: "Waitlisted" },
    { value: "Fulfilled", label: "Fulfilled" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  return (
    <>
      <Card
        className="shadow-sm hover:shadow-md transition-shadow duration-200 rounded-md"
        style={{
          border: "none",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          padding: "8px",
        }}
      >
        <CardHeader
          title={
            <div className="flex gap-4 items-center ">
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontSize: "1rem", fontWeight: "700", color: "#333" }}
              >
                {appointment?.participant}
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontSize: "0.8rem", fontWeight: "500", color: "#333" }}
              >
                {formatDateTime(appointment?.created_at)}
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontSize: "0.8rem", fontWeight: "500", color: "#333" }}
              >
                <span className=" font-semibold text-[14px]">Token # </span>
                {appointment?.token?.token_number}
              </Typography>
            </div>
          }
          action={
            <div className="flex gap-1">
              <Chip
                label={appointment.payment_status}
                color={
                  appointment?.payment_status === "Paid" ? "success" : "error"
                }
                size="small"
                className="!mr-[2px] rounded"
                style={{ fontSize: "0.7rem", padding: "2px 4px" }}
              />
              <Chip
                label={appointment.status}
                color={getStatusSeverity(appointment.status)}
                size="small"
                className="!mr-[2px] rounded"
                style={{ fontSize: "0.7rem", padding: "2px 4px" }}
              />
            </div>
          }
          className="p-1"
          sx={{ padding: "4px 8px" }}
        />
        <CardContent
          className="text-sm p-2 flex justify-between "
          sx={{ padding: "4px 8px" }}
        >
          <div className="flex flex-col justify-center gap-2 mb-1">
            <span
              className="font-semibold text-gray-600"
              style={{ fontSize: "1rem" }}
            >
              Doctor
            </span>
            <span className="text-gray-800" style={{ fontSize: "1rem" }}>
              {getDoctorName(appointment?.practitioner_id)}
            </span>
          </div>
          <div className="flex flex-col justify-center gap-2 mb-1">
            <span
              className="font-semibold text-gray-600"
              style={{ fontSize: "1rem" }}
            >
              Type
            </span>
            <span className="text-gray-800" style={{ fontSize: "1rem" }}>
              {appointment?.appointment_type?.type}
            </span>
          </div>
          <div className="flex flex-col justify-center gap-2 mb-1">
            <span
              className="font-semibold text-gray-600"
              style={{ fontSize: "1rem" }}
            >
              Comment
            </span>
            <span className="text-gray-800" style={{ fontSize: "1rem" }}>
              {appointment?.comment}
            </span>
          </div>
          <div className="flex flex-col justify-center gap-2 mb-1">
            <span
              className="font-semibold text-gray-600"
              style={{ fontSize: "1rem" }}
            >
              Episode Type
            </span>
            <span className="text-gray-800" style={{ fontSize: "1rem" }}>
              {appointment?.episode_type}
            </span>
          </div>
          <div className="flex flex-col justify-center gap-2 mb-1">
            <span
              className="font-semibold text-gray-600"
              style={{ fontSize: "1rem" }}
            >
              Episode No
            </span>
            <span className="text-gray-800" style={{ fontSize: "1rem" }}>
              {appointment?.episode_id}
            </span>
          </div>
        </CardContent>

        <CardActions
          className="flex justify-between p-1"
          sx={{ padding: "4px 8px" }}
        >
          <div className="flex gap-2 items-center w-full">
            <label
              className="text-xs font-medium text-gray-600"
              style={{ fontSize: "0.7rem" }}
            >
              Change Status
            </label>
            <select
              id="Status"
              name="Status"
              value={status}
              className="rounded p-1 text-xs border-gray-300 bg-gray-50"
              style={{ fontSize: "0.7rem", padding: "2px 4px", flexGrow: 1 }}
              onChange={(e) => handleStatusChange(e.target.value)}
              disabled={
                appointment?.status === "Cancelled" ||
                appointment?.status === "Fulfilled"
              }
            >
              <option value="Pending">Pending</option>
              {data.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {doctor && appointment?.payment_status === "Paid" ? (
              <Tooltip title="Create EMR">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="cursor-pointer hover:!text-primary"
                  onClick={() => {
                    if (patientID) {
                      navigate("/his/emr", {
                        state: {
                          patientID: patientID,
                          appointment: appointment,
                        },
                      });
                    } else {
                      toast?.error("Please Select Patient First");
                    }
                  }}
                >
                  <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zM14 9V3.5L18.5 8H15c-.55 0-1-.45-1-1zM13 16h-2v-2H9v-2h2V9h2v3h2v2h-2v2z" />
                </svg>
              </Tooltip>
            ) : null}

            {(appointment?.payment_status === "UnPaid" && !doctor) ||
            appointment?.items?.some(
              (itm) => itm?.status === "UnPaid" && !doctor
            ) ? (
              <Tooltip title="Pay Bill">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="cursor-pointer hover:!text-primary"
                  onClick={() =>
                    navigate("/receptionist/create-visit", {
                      state: { appointment: appointment },
                    })
                  }
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 8h10v2H6V8zm0 4h10v2H6v-2zm0 4h7v2H6v-2z" />
                  <circle cx="18" cy="8" r="2.5" />
                  <text
                    x="17.2"
                    y="9"
                    fontFamily="Arial"
                    fontSize="4"
                    fill="white"
                  >
                    $
                  </text>
                </svg>
              </Tooltip>
            ) : null}
          </div>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to change the appointment status for appointment no{" "}
            {appointment?.appointment_id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateAppointment(setLoading, {
                status: status,
                visit_id: appointmentId,
              });
              handleClose();
              window?.location?.reload();
              setOpen(false);
            }}
          >
            Confirm
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <AppLoader setShowLoader={setLoading} showLoader={loading} />
    </>
  );
};

export default PatientCard;
