import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import dashboard from "../../gql/dashboard";
import { useQuery } from "@apollo/client";
import DashboardFilter from "./DashboardFilter";
import AppModal from "../../components/AppModal";
import Button from "@mui/material/Button";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { Tooltip } from "@mui/material";
import {
  BiLineChart,
  BiCheckCircle,
  BiXCircle,
  BiDotsHorizontalRounded,
  BiTime,
  BiStopCircle,
  BiError,
} from "react-icons/bi";
import DashboardChart from "./DashboardChart";
import { useFormik } from "formik";
import ptpa from "../../gql/ptpa";
import { hisLogin } from "../HIS/components/ApiMethods";

// const stats = [
//   {
//     name: "Number of Claims",
//     value: "405,091",
//     change: "+4.75%",
//     changeType: "positive",
//     icon: "beneficary.png",
//   },
//   {
//     name: "Gross Amount",
//     value: "12,787",
//     change: "+54.02%",
//     changeType: "positive",
//     icon: "payer.png",
//   },
//   {
//     name: "Discount",
//     value: "245,988",
//     change: "-1.39%",
//     changeType: "negative",
//     icon: "patient.png",
//   },
//   {
//     name: "Patient Share",
//     value: "30,156",
//     change: "+10.18%",
//     changeType: "positive",
//     icon: "expense.png",
//   },
//   {
//     name: "Net Amount",
//     value: "30,156",
//     change: "+10.18%",
//     changeType: "positive",
//     icon: "expense.png",
//   },
//   {
//     name: "VAT Amount",
//     value: "30,156",
//     change: "+10.18%",
//     changeType: "positive",
//     icon: "expense.png",
//   },
// ];

// const Beneficiary = [
//   {
//     name: "Total Beneficiaries",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },
// ];

// const eligibility = [
//   {
//     name: "Total Eligibilities",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Active Eligibilities",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Non Active Eligibilities",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },
// ];

// const claims = [
//   {
//     name: "Total Claims",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Accepted Claims",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Rejected Claims",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Partial Claims",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Pending Claims",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },
// ];

// const preauth = [
//   {
//     name: "Total Pre Auth",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Accepted Pre Auth",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Rejected Pre Auth",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Partial Pre Auth",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },

//   {
//     name: "Pending Pre Auth",
//     value: "0",
//     change: "0",
//     changeType: "positive",
//   },
// ];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function Dashboard(props) {
  const currentDate = new Date();
  const [showFilter, setShowFilter] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [eligibilityData, setEligibilityData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [preauthData, setPreAuthData] = useState([]);

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );
  const { loading, error, data, refetch } = useQuery(
    dashboard.DASHBOARD_STATS,
    {
      variables: { filters: { from_date: fromDate, to_date: toDate } },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (data?.getDashboardStats?.data) {
      setBeneficiaryData([
        {
          name: "Beneficiaries",
          value: data?.getDashboardStats?.data?.patients || 0,
          change: "0",
          changeType: "positive",
          clr: "#7CD2FC",
          icon: BiLineChart,
        },
        {
          name: "Eligibilities",
          value: data?.getDashboardStats?.data?.total_verification || 0,
          change: "0",
          changeType: "positive",
          clr: "#7CD2FC",
          icon: BiLineChart,
        },

        {
          name: "Eligibles",
          value: data?.getDashboardStats?.data?.active_verification || 0,
          change: "0",
          changeType: "positive",
          clr: "#84EDAF",
          icon: BiCheckCircle,
        },

        {
          name: "Not Actives",
          value: data?.getDashboardStats?.data?.not_active_verification || 0,
          change: "0",
          changeType: "positive",
          clr: "#DC262680",
          icon: BiXCircle,
        },
      ]);

      setEligibilityData([
        {
          name: "Eligibilities",
          value: data?.getDashboardStats?.data?.total_verification || 0,
          change: "0",
          changeType: "positive",
          clr: "#7CD2FC",
        },

        {
          name: "Eligibles",
          value: data?.getDashboardStats?.data?.active_verification || 0,
          change: "0",
          changeType: "positive",
          clr: "#84EDAF",
        },

        {
          name: "Not Actives",
          value: data?.getDashboardStats?.data?.not_active_verification || 0,
          change: "0",
          changeType: "positive",
          clr: "#DC262680",
        },
      ]);

      setClaimData([
        {
          name: "Total Claims",
          value: data?.getDashboardStats?.data?.total_claims || 0,
          change: "0",
          changeType: "positive",
          clr: "#7CD2FC",
          icon: BiLineChart, // Icon component
        },
        {
          name: "Approved",
          value: data?.getDashboardStats?.data?.accepted_claims || 0,
          change: "0",
          changeType: "positive",
          clr: "#84EDAF",
          icon: FiThumbsUp, // Icon component
        },
        {
          name: "Rejected",
          value: data?.getDashboardStats?.data?.rejected_claims || 0,
          change: "0",
          changeType: "positive",
          clr: "#DC262680",
          icon: FiThumbsDown, // Icon component
        },
        {
          name: "Partials",
          value: data?.getDashboardStats?.data?.partial_claims || 0,
          change: "0",
          changeType: "positive",
          clr: "#9C27B080",
          icon: BiCheckCircle, // Icon component
        },
        {
          name: "Queued",
          value: data?.getDashboardStats?.data?.pending_claims || 0,
          change: "0",
          changeType: "positive",
          clr: "#ED6C0280",
          icon: BiTime, // Icon component
        },
        {
          name: "Cancels",
          value: data?.getDashboardStats?.data?.cancelled_claims || 0,
          change: "0",
          changeType: "positive",
          clr: "#FF00FF80",
          icon: BiXCircle, // Alternative Icon component
        },
        {
          name: "Errors",
          value: data?.getDashboardStats?.data?.errored_claims || 0,
          change: "0",
          changeType: "positive",
          clr: "#FC997480",
          icon: BiError, // Icon component
        },
      ]);

      setPreAuthData([
        {
          name: "Total PreAuth",
          value: data?.getDashboardStats?.data?.total_pre_auth || 0,
          change: "0",
          changeType: "positive",
          clr: "#7CD2FC", // Lighter shade with 50% opacity
          icon: BiLineChart,
        },
        {
          name: "Approved",
          value: data?.getDashboardStats?.data?.accepted_pre_auth || 0,
          change: "0",
          changeType: "positive",
          clr: "#84EDAF", // Lighter shade with 50% opacity
          icon: FiThumbsUp,
        },
        {
          name: "Rejected",
          value: data?.getDashboardStats?.data?.rejected_pre_auth || 0,
          change: "0",
          changeType: "positive",
          clr: "#DC262680", // Lighter shade with 50% opacity
          icon: FiThumbsDown,
        },
        {
          name: "Partials",
          value: data?.getDashboardStats?.data?.partial_pre_auth || 0,
          change: "0",
          changeType: "positive",
          clr: "#9C27B080", // Lighter shade with 50% opacity
          icon: BiCheckCircle,
        },
        {
          name: "Queued",
          value: data?.getDashboardStats?.data?.pending_pre_auth || 0,
          change: "0",
          changeType: "positive",
          clr: "#ED6C0280", // Lighter shade with 50% opacity
          icon: BiTime,
        },
        {
          name: "Errors",
          value: data?.getDashboardStats?.data?.errored_pre_auth || 0,
          change: "0",
          changeType: "positive",
          clr: "#FC997480", // Lighter shade with 50% opacity
          icon: BiError,
        },
      ]);
    }
  }, [data?.getDashboardStats?.data]);

  function formatDate(inputDate) {
    // Create a new Date object from the input date string
    var date = new Date(inputDate);

    // Define months array
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the components of the date
    var year = date.getFullYear();
    var month = months[date.getMonth()];
    var day = date.getDate();

    // Format the date
    var formattedDate =
      month + " " + (day < 10 ? "0" + day : day) + ", " + year;

    // Return the formatted date
    return formattedDate;
  }

  const formik = useFormik({
    initialValues: {
      from_date: `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}-01`,
      to_date: `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}-${new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate()}`,
    },
    onSubmit: (values, { setSubmitting }) => {
      refetch({
        filters: Object.keys(values).reduce((acc, key) => {
          const value = values[key];
          if (value !== "" && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      });
    },
  });

  const {
    loading: loadingPayers,
    error: errorPayer,
    data: payersData,
    refetch: refetchPayers,
  } = useQuery(ptpa.GET_ALL_PAYERS);

  React.useEffect(() => {
    if (payersData) {
      localStorage.setItem(
        "payersData",
        JSON.stringify(payersData.listPayersWithTpa.data)
      );
    }
  }, [payersData]);

  return (
    data?.getDashboardStats?.data && (
      <div className="row">
        <div className="flex gap-3 flex-col md:flex-row justify-between">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your Dashboard"
              className="my-auto"
            >
              <span className="font-semibold text-black/70 text-xl ">
                Dashboard
              </span>
            </Tooltip>
          </div>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setShowFilter(() => !showFilter)}
            className="!border-primary !text-primary hover:!bg-primary/10"
            startIcon={<FilterAltOutlinedIcon />}
          >
            Apply Filter
          </Button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="overflow- py-2">
            <div className="mt-1 mb-2 flex justify-between">
              <div className=" text-base font-medium text-gray-600">
                Beneficiaries & Eligibilities
              </div>

              <div className="text-base  text-gray-600">
                <span className="font-medium">Filter By: </span>{" "}
                {formatDate(formik.values.from_date)} -{" "}
                {formatDate(formik.values.to_date)}
              </div>
            </div>
            <div className="">
              <dl className="flex flex-wrap ">
                {beneficiaryData.map((stat, statIdx) => (
                  <div
                    class="w-full max-w-full px-1 mb-6 sm:w-1/2 sm:flex-none xl:mb-2 xl:w-1/4"
                    key={statIdx}
                  >
                    <div class="relative flex flex-col min-w-0 break-words bg-gray-50 shadow-sm rounded-md bg-clip-border">
                      <div class="flex-auto px-1.5 py-1.5">
                        <div class="flex flex-row -mx-3">
                          <div class="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p class="mb-0 font-sans font-medium text-gray-600 leading-normal text-sm">
                                {stat?.name}
                              </p>
                              <h5 class="mb-0 font-bold">
                                {stat?.value}
                                {/* <span class="leading-normal text-sm font-weight-bolder text-lime-500">
                                +55%
                              </span> */}
                              </h5>
                            </div>
                          </div>
                          <div class="px-1 text-right basis-1/3">
                            <div class=" ">
                              {stat.icon && (
                                <stat.icon
                                  className="text-2xl relative top-1 m-auto"
                                  style={{ color: stat?.clr }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-2 mb-2 text-base font-medium text-gray-600">
              Pre Authorization
            </div>
            <div className="">
              <dl className="flex flex-wrap">
                {preauthData.map((stat, statIdx) => (
                  <div
                    class="w-full max-w-full px-1 mb-6 sm:w-1/2 sm:flex-none xl:mb-2 xl:w-1/4"
                    key={statIdx}
                  >
                    <div class="relative flex flex-col min-w-0 break-words bg-gray-50 shadow-sm rounded-md bg-clip-border">
                      <div class="flex-auto  px-1.5 py-1.5">
                        <div class="flex flex-row -mx-3">
                          <div class="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p class="mb-0 font-sans font-medium text-gray-600 leading-normal text-sm">
                                {stat?.name}
                              </p>
                              <h5 class="mb-0 font-bold">
                                {stat?.value}
                                {/* <span class="leading-normal text-sm font-weight-bolder text-lime-500">
                                +55%
                              </span> */}
                              </h5>
                            </div>
                          </div>
                          <div class="px-1 text-right basis-1/3">
                            <div class=" ">
                              {stat.icon && (
                                <stat.icon
                                  className="text-2xl relative top-1 m-auto"
                                  style={{ color: stat?.clr }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-2 mb-2 text-base font-medium text-gray-600">
              Claims
            </div>
            <div className="">
              <dl className="flex flex-wrap ">
                {claimData.map((stat, statIdx) => (
                  <div
                    class="w-full max-w-full px-1 mb-6 sm:w-1/2 sm:flex-none xl:mb-2 xl:w-1/4"
                    key={statIdx}
                  >
                    <div class="relative flex flex-col min-w-0 break-words bg-gray-50 shadow-sm rounded-md bg-clip-border">
                      <div class="flex-auto  px-1.5 py-1.5">
                        <div class="flex flex-row -mx-3">
                          <div class="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p class="mb-0 font-sans font-medium text-gray-600 leading-normal text-sm">
                                {stat?.name}
                              </p>
                              <h5 class="mb-0 font-bold">
                                {stat?.value}
                                {/* <span class="leading-normal text-sm font-weight-bolder text-lime-500">
                                +55%
                              </span> */}
                              </h5>
                            </div>
                          </div>
                          <div class="px-1 text-right basis-1/3">
                            <div class=" ">
                              {stat.icon && (
                                <stat.icon
                                  className="text-2xl relative top-1 m-auto"
                                  style={{ color: stat?.clr }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          <div className=" ">
            <DashboardChart apiData={data?.getDashboardStats?.data} />
          </div>
        </div>
        <DashboardFilter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          formik={formik}
        />

        <AppModal isOpen={loading || loadingPayers} />
      </div>
    )
  );
}

export default Dashboard;

{
  /* <div className="mt-4 mb-2 text-lg font-medium text-gray-600">
Pre Authorization
</div>
<div className="mb-4">
<dl className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 lg:px-2 xl:px-0 border-0 lg:border border-gray-900/10 gap-2">
  {preauthData.map((stat, statIdx) => (
    <div
      key={stat.name}
      className={classNames(
        "flex items-baseline justify-between py-2.5 px-3.5 border-b-4 border-x border-t"
      )}
      style={{ borderColor: stat?.clr }}
    >
      
      <dt
        className={classNames("text-base font-medium ")}
        style={{ color: stat?.clr }}
      >
        {stat.name}
      </dt>

      <dd
        className={classNames("text-2xl font-semibold")}
        style={{ color: stat?.clr }}
      >
        {stat?.value}
      </dd>

      
    </div>
  ))}
</dl>
</div> */
}

{
  /* <div className="flex justify-between w-full mt-4">
      <dd className="text-sm font-medium text-gray-500 leading-6 ">
        {stat.changeType === "negative"
          ? "Decrease By"
          : "Increase By"}
      </dd>
      <dd
        className={classNames(
          stat.changeType === "negative"
            ? "text-#DC262680-600"
            : "text-#84EDAF-700",
          "text-sm font-medium my-auto"
        )}
      >
        0
      </dd>
    </div> */
}

{
  /* <div className="mt-4 mb-2 text-xl font-medium text-gray-600">
            Eligibilities
          </div>
          <div className="mb-4">
            <dl className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 lg:px-2 xl:px-0 border-0 lg:border border-gray-900/10 gap-2">
              {eligibilityData.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    "flex items-baseline justify-between py-2.5 px-3.5 border-b-4 border-x border-t"
                  )}
                  style={{ borderColor: stat?.clr }}
                >
                  <dt
                    className={classNames("text-base font-medium ")}
                    style={{ color: stat?.clr }}
                  >
                    {stat.name}
                  </dt>

                  <dd
                    className={classNames("text-2xl font-semibold")}
                    style={{ color: stat?.clr }}
                  >
                    {stat?.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div> */
}
