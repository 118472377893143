import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Button, Dialog, Grid, IconButton } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  CreateAppointmentApi,
  createCashInvoice,
  createInsuranceInvoice,
  createNewInvoice,
  getCompanyList,
  getCustomerList,
  getInvoiceData,
  getitemsList,
  getPatientListDropdown,
  getPractitionersList,
  getPriceListByID,
  getslots,
  updateAppoinmentStatus,
  updateEmrItemsStatus,
} from "../components/ApiMethods";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppLoader from "../../../components/AppLoader";
import SharedInput from "../components/SharedInput";
import SharedDropdown from "../components/SharedDropdown";
import SharedSearchDropdown from "./SharedSearchDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import priceList from "../../../gql/priceList";
import { BiSearch, BiX } from "react-icons/bi";
import _, { values } from "lodash";
import { FaArrowLeft } from "react-icons/fa";
import { items } from "../../PreAuth/components/request/BodySites";

function NewAppointment() {
  const [open, setOpen] = useState(false);
  const [payerId, setPayerID] = useState("2117");
  const formikRef = useRef(null);

  const [scrollTop, setScrollTop] = useState(0);
  const [ref, setRef] = useState("");
  const [patientType, setPatientType] = useState(null);
  const navigate = useNavigate();
  const [priceListId, setPriceListId] = useState("0");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [slotsList, setSlotList] = useState(null);
  const location = useLocation();
  const appointmentBillToPay = location?.state?.appointment;
  console.log(appointmentBillToPay);

  const {
    loading: loadingSearchPriceListCode,
    error: errorSearchPriceListCode,
    data: dataSearchPriceListCode,
    refetch: refetchSearchPriceListCode,
  } = useQuery(priceList.SEARCH_PRICE_LIST_CODE, {
    variables: {
      input: "",
      payerId: payerId,
    },
    skip: true,
  });
  const handleSearch = (term) => {
    refetchSearchPriceListCode({
      input: term,
      payerId: payerId,
    }).then((data) => {
      setSearchResult(data?.data?.searchPriceListCode?.data || []);
    });
  };

  function changeSlotsList(list) {
    return list?.slots.map((slot, index) => ({
      value: slot,
      label: slot,
    }));
  }

  const getNationality = (formik) => {
    const patient = patientList?.data?.find((item) => item.id === ref);

    return patient ? patient.nationality : null;
  };

  function calculateShares(formikProps, maxLimit) {
    const items = formikProps?.values?.items || [];
    let totalPatientShare = 0;
    let totalCompanyShare = 0;
    let totalPatientTax = 0;
    let totalCompanyTax = 0;
    let totaldiscountedAmount = 0;

    items.forEach((itm) => {
      const unitPrice = itm?.unit_price || 0;
      const quantity = itm?.quantity || 0;
      const discountAmount = itm?.discount_amount || 0;
      const patientSharePercentage = itm?.patient_share || 20;
      const tax_check = itm?.tax_check;

      const totalAmount = unitPrice * quantity;
      let discountedAmount;

      if (itm?.discount_type === "fixed") {
        discountedAmount = totalAmount - discountAmount;
        totaldiscountedAmount += discountAmount;
      } else {
        const discountValue = (totalAmount * discountAmount) / 100;
        totaldiscountedAmount += discountValue;
        discountedAmount = totalAmount - discountValue;
      }

      const fullPatientShare =
        (discountedAmount * patientSharePercentage) / 100;
      let currentPatientShare = 0;
      let currentCompanyShare = 0;

      if (totalPatientShare < maxLimit) {
        const remainingLimit = maxLimit - totalPatientShare;
        if (fullPatientShare <= remainingLimit) {
          currentPatientShare = fullPatientShare;
        } else {
          currentPatientShare = remainingLimit;
        }
        totalPatientShare += currentPatientShare;
      }

      currentCompanyShare = discountedAmount - currentPatientShare;
      totalCompanyShare += currentCompanyShare;

      if (tax_check) {
        const patientTax = currentPatientShare * 0.15;
        totalPatientTax += patientTax;
        const companyTax = currentCompanyShare * 0.15;
        totalCompanyTax += companyTax;
      }

      console.log(`Discounted Amount: ${discountedAmount.toFixed(2)}`);
      console.log(`Current Patient Share: ${currentPatientShare.toFixed(2)}`);
      console.log(`Current Company Share: ${currentCompanyShare.toFixed(2)}`);
      console.log(`Total Patient Share: ${totalPatientShare.toFixed(2)}`);
      console.log(`Total Company Share: ${totalCompanyShare.toFixed(2)}`);
      console.log(`Total Patient Tax: ${totalPatientTax.toFixed(2)}`);
      console.log(`Total Company Tax: ${totalCompanyTax.toFixed(2)}`);
      console.log("---");
    });

    return {
      patientShareTotal: parseFloat(totalPatientShare.toFixed(2)),
      companyShareTotal: parseFloat(totalCompanyShare.toFixed(2)),
      patientTaxTotal: parseFloat(totalPatientTax.toFixed(2)),
      companyTaxTotal: parseFloat(totalCompanyTax.toFixed(2)),
      discountedAmount: totaldiscountedAmount,
    };
  }
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.length >= 3) {
      debouncedSearch(newSearchTerm);
    } else {
      setSearchResult([]);
    }
  };
  const debouncedSearch = _.debounce(handleSearch, 1000);

  const handleSelectSearchItem = (formik, item) => {
    const newItem = {
      ...item,
      quantity: 1,
      discount_type: "fixed",
      discount_amount: 0,
      tax_check: false,
      ...(formik?.values?.AppoinmentType === "Insurance" && {
        patient_share: 20,
      }),
    };
    formik.setFieldValue("items", [...formik.values.items, newItem]);

    setSearchResult([]);
    setSearchTerm("");
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const topPosition = scrollTop === 0 ? "top-10" : "top-0";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getuser = localStorage.getItem("user");
  const user = JSON.parse(getuser);

  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [priceListData, setPriceListData] = useState(null);
  const [paidToday, setPaidToday] = useState(true);
  const serviceCategories = [
    { label: "Consultation", value: "Consultation" },
    { label: "Follow up", value: "Follow up" },
    { label: "Treatment", value: "Treatment" },
    { label: "Procedure", value: "Procedure" },
    { label: "Check up", value: "Check up" },
    { label: "Immunization", value: "Immunization" },
    { label: "Laboratory", value: "Laboratory" },
    { label: "Radiology", value: "Radiology" },
  ];

  const serviceType = [
    { label: "General, speciality", value: "General, speciality" },
    { label: "Routine, post-operative", value: "Routine, post-operative" },
    {
      label: "Physical, chemo, radiation therapy",
      value: "Physical, chemo, radiation therapy",
    },
    { label: "Diagnostic, surgical", value: "Diagnostic, surgical" },
    {
      label: "Annual physical, well-child check",
      value: "Annual physical, well-child check",
    },
    {
      label: "Vaccination, booster shots",
      value: "Vaccination, booster shots",
    },
    { label: "Blood test, urinalysis", value: "Blood test, urinalysis" },
    { label: "X-ray, ultrasound", value: "X-ray, ultrasound" },
  ];

  const appointmentTypes = [
    { label: "Routine checkup", value: "Routine checkup" },
    { label: "Follow up", value: "Follow up" },
    { label: "Consultation", value: "Consultation" },
    { label: "Emergency", value: "Emergency" },
    { label: "Procedure", value: "Procedure" },
    { label: "Therapy", value: "Therapy" },
    { label: "Immunization", value: "Immunization" },
    { label: "Surgical", value: "Surgical" },
    { label: "Diagnostic test", value: "Diagnostic test" },
  ];

  const paymentMethods = [
    { label: "Mada", value: "Mada" },
    { label: "Cash", value: "cash" },
    { label: "Bank", value: "bank" },
  ];
  const reasons = [
    { label: "Annual physical exam", value: "Annual physical exam" },
    { label: "Symptom evaluation", value: "Symptom evaluation" },
    {
      label: "Chronic disease management",
      value: "Chronic disease management",
    },
    { label: "Post-operative follow up", value: "Post-operative follow up" },
    { label: "Medication review", value: "Medication review" },
    { label: "Lab test result", value: "Lab test result" },
    { label: "Therapy session", value: "Therapy session" },
    { label: "Procedure scheduling", value: "Procedure scheduling" },
    { label: "Emergency", value: "Emergency" },
  ];

  const AppoinmentTypeValues = [
    { value: "Cash", label: "Cash" },
    { value: "Insurance", label: "Insurance" },
  ];

  const medicalSpecialties = [
    { value: "Allergy & Immunology", label: "Allergy & Immunology" },
    { value: "Anesthesiology", label: "Anesthesiology" },
    { value: "Cardiology", label: "Cardiology" },
    { value: "Dermatology", label: "Dermatology" },
    { value: "Endocrinology", label: "Endocrinology" },
    { value: "Gastroenterology", label: "Gastroenterology" },
    {
      value: "General Practice/Family Medicine",
      label: "General Practice/Family Medicine",
    },
    { value: "Geriatrics", label: "Geriatrics" },
    { value: "Hematology", label: "Hematology" },
    { value: "Infectious Disease", label: "Infectious Disease" },
    { value: "Internal Medicine", label: "Internal Medicine" },
    { value: "Nephrology", label: "Nephrology" },
    { value: "Neurology", label: "Neurology" },
    { value: "Obstetrics & Gynecology", label: "Obstetrics & Gynecology" },
    { value: "Oncology", label: "Oncology" },
    { value: "Ophthalmology", label: "Ophthalmology" },
    { value: "Orthopedics", label: "Orthopedics" },
    { value: "Otolaryngology (ENT)", label: "Otolaryngology (ENT)" },
    { value: "Pediatrics", label: "Pediatrics" },
    {
      value: "Physical Medicine & Rehabilitation",
      label: "Physical Medicine & Rehabilitation",
    },
    { value: "Psychiatry", label: "Psychiatry" },
    { value: "Pulmonology", label: "Pulmonology" },
    { value: "Radiology", label: "Radiology" },
    { value: "Rheumatology", label: "Rheumatology" },
    { value: "Surgery", label: "Surgery" },
    { value: "Urology", label: "Urology" },
  ];
  console.log(appointmentBillToPay);

  useEffect(() => {
    getCustomerList(setLoading, setPatientList);
    getPractitionersList(setLoading, setDoctorList);
    // getitemsList(setLoading, setItemsList);
    fetchPriceList(priceListId);
    getCompanyList(setLoading, setCompanyList);
  }, []);
  const payersData = JSON.parse(localStorage.getItem("payersData"));

  const [validate, setValidate] = useState("Cash");
  const [maxLimit, setMaxLimit] = useState("100");

  useEffect(() => {
    if (patientList?.data && appointmentBillToPay?.patient?.patient_id) {
      setRef(
        patientList.data.find(
          (patient) =>
            patient.his_refrence === appointmentBillToPay?.patient?.patient_id
        )?.id
      );
      const selectedPatient = patientList?.data?.find(
        (patient) =>
          patient.his_refrence === appointmentBillToPay?.patient?.patient_id
      );
      setPatientType(selectedPatient.beneficiary_type);
    }
  }, [patientList, appointmentBillToPay]);

  const initialValues = {
    AppoinmentType: appointmentBillToPay
      ? appointmentBillToPay?.patient?.beneficiary_type
      : "Cash",
    service_categories: appointmentBillToPay
      ? appointmentBillToPay?.service_categories
      : "",
    service_type: "",
    visit_type: appointmentBillToPay
      ? appointmentBillToPay?.visit_type?.type
      : "",
    reasons: "",
    speciality: appointmentBillToPay
      ? appointmentBillToPay?.specialty?.specialty
      : "",
    visit_date: appointmentBillToPay
      ? appointmentBillToPay?.visit_date
      : new Date().toISOString().split("T")[0],
    slots: appointmentBillToPay ? appointmentBillToPay?.start : "",
    // end: appointmentBillToPay
    //   ? appointmentBillToPay?.end
    //   : new Date().toISOString().split("T")[0],
    customer_id: appointmentBillToPay
      ? appointmentBillToPay?.patient?.patient_id
      : "",
    practitioner_id: appointmentBillToPay
      ? appointmentBillToPay?.practitioner_id
      : "",
    comment: appointmentBillToPay ? appointmentBillToPay?.comment : "",
    date: new Date().toISOString().split("T")[0],
    payment_method: appointmentBillToPay?.payment_method
      ? appointmentBillToPay?.payment_method
      : "",
    description: "",
    paid: new Date().toISOString().split("T")[0] ? true : false,
    branch_id: 1,
    business_id: 1,
    invoice_type: "sales",
    // tax_check: false,
    items:
      appointmentBillToPay?.items?.filter(
        (item) => item?.status === "UnPaid"
      ) || [],
    company_id: payerId,
  };

  const validationSchema = yup.object().shape({
    AppoinmentType: paidToday
      ? yup.string().required("Appointment Type is required")
      : null,
    // service_categories: yup.string().required("Service Category is required"),
    // service_type: yup.string().required("Service Type is required"),
    visit_type: yup.string().required("Appointment Type is required"),
    // reasons: yup.string().required("Reason is required"),
    speciality: yup.string().required("Speciality is required"),
    payment_method: paidToday
      ? yup.string().required("Payment method is required")
      : null,
    visit_date: yup.string().required("Start date is required"),
    // end: yup.string().required("End date is required"),
    customer_id: yup.string().required("Patient is required"),
    practitioner_id: yup.string().required("Practitioner is required"),
    comment: yup.string(),
    date: paidToday ? yup.string().required("Date is required ") : null,
    description: paidToday
      ? yup.string().required("Description is required ")
      : null,
    paid: paidToday ? yup.string().required("Paid status is required ") : null,
    items: paidToday
      ? yup.array().min(1, "At least one item is required")
      : null,

    // company_id:
    //   validate === "Insurance" && paidToday
    //     ? yup.string().required("Company id required")
    //     : yup.string().nullable(),
    // items: paidToday
    //   ? yup
    //       .array()
    //       .of(
    //         yup.object().shape({
    //           id: yup.number().required("Item ID is required"),
    //           patient_share: yup
    //             .number()
    //             .min(0, "Patient share must be non-negative")
    //             .required("Patient share is required"),
    //           quantity: yup
    //             .number()
    //             .integer()
    //             .min(1, "Quantity must be at least 1")
    //             .required("Quantity is required"),
    //           discount_type: yup.string().required("Discount type is required"),
    //           discount_amount: yup
    //             .number()
    //             .min(0, "Discount amount must be non-negative")
    //             .required("Discount amount is required"),
    //           tax_type: yup.string().required("Tax type is required"),
    //           tax_amount: yup
    //             .number()
    //             .min(0, "Tax amount must be non-negative")
    //             .required("Tax amount is required"),
    //         })
    //       )
    //       .min(1, "At least one item is required ")
    //   : null,
  });

  function CalculateTax(formikProps) {
    const items = formikProps?.values?.items || [];

    const taxableAmount = items
      .filter((itm) => itm?.tax_check)
      .map((itm) => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .filter((itm) => itm?.tax_check)
      .map((itm) => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === "percentage") {
          discount =
            (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) /
            100;
        } else if (discountType === "fixed") {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxableAmountAfterDiscount = Math.max(
      taxableAmount - totalDiscount,
      0
    );

    const taxAmount = (taxableAmountAfterDiscount * 15) / 100;

    return taxAmount;
  }

  function CalculateNetTotal(formikProps) {
    const items = formikProps?.values?.items || [];

    const totalAmount = items
      .map((itm) => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .map((itm) => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === "percentage") {
          discount =
            (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) /
            100;
        } else if (discountType === "fixed") {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxAmount = CalculateTax(formikProps);

    const netTotal = Math.max(totalAmount - totalDiscount + taxAmount, 0);
    console.log("Net Total:", netTotal);

    return netTotal;
  }

  useEffect(() => {
    validate === "Cash" && setPayerID("2117");
  }, [validate]);
  console.log(appointmentBillToPay);

  const handleSubmit = async (values, { resetForm }) => {
    const appointmentData = {
      service_category: {
        category: values?.service_categories,
      },
      service_type: { type: values?.service_type },
      specialty: { specialty: values?.speciality },
      visit_type: { type: values?.visit_type },
      reason_code: { code: values?.reasons },
      visit_date: values?.visit_date,
      start: values?.slots,
      participant: { participant: "" },
      comment: values?.comment,
      patient_id: values?.customer_id ? String(values.customer_id) : "",
      practitioner_id: values?.practitioner_id,
      payment_method: values?.payment_method,
      payment_status: paidToday ? "Paid" : "UnPaid",
      items: values?.items.map((item) => ({
        ...item,
        discount_amount: Number(item?.discount_amount),
        name: item?.service_desc,
        item_code: item?.service_code,
        item_type: item?.service_type,
        quantity: Number(values?.quantity),
      })),
    };
    const newItems = values?.items.map((item) => ({
      ...item,
      discount_amount: Number(item?.discount_amount),
      name: item?.service_desc,
      item_code: item?.service_code,
      item_type: item?.service_type,
      quantity: Number(item?.quantity),
      amount: Number(item?.unit_price),
      unit_price: Number(item?.unit_price),
    }));

    const commonData = {
      date: values?.date,
      description: values?.description,
      paid: values?.paid,
      branch_id: values?.providers_branch_id,
      business_id: values?.providers_id,
      items: newItems,
      customer_id: Number(ref),
      payment_method: values?.payment_method,
      tax_type: "percentage",
      tax_amount: 15,
    };

    const invoiceData =
      values.AppoinmentType === "Cash"
        ? {
            ...commonData,
            invoice_type: "sales",
          }
        : {
            ...commonData,
            invoice_type: "credit",
            company_id: Number(payerId),
          };

    try {
      setLoading(true);

      if (paidToday) {
        if (paidToday && appointmentBillToPay) {
          await updateAppoinmentStatus({
            setShowLoader: setLoading,
            data: {
              visit_id: appointmentBillToPay?.visit_id,
              payment_status: "Paid",
            },
          }).then((res) => {
            updateEmrItemsStatus(setLoading, {
              items: commonData?.items,
              visit_id: appointmentBillToPay?.visit_id,
            });
            createNewInvoice(setLoading, invoiceData);
          });
          handleClose();
          resetForm();
          setValidate("");
        } else {
          const appointmentResponse = await CreateAppointmentApi(
            setLoading,
            appointmentData
          ).then(async (res) => {
            await createNewInvoice(setLoading, invoiceData);
            handleClose();
            resetForm();
            setValidate("");
          });

          if (appointmentResponse?.message === "visit created successfully") {
          }
        }
      } else {
        const appointmentResponse = await CreateAppointmentApi(
          setLoading,
          appointmentData
        );
      }
    } catch (error) {
      console.error("Error during appointment or invoice creation:", error);
    } finally {
      navigate("/receptionist/visits");
      setLoading(false);
    }
  };
  const typeOptions = [
    { value: "fixed", label: "Fixed" },
    { value: "percentage", label: "Percentage" },
  ];

  const paid_values = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  const ItemsOptions =
    itemsList?.data?.map((item) => ({
      value: item.ID,
      label: item.name,
      amount: item?.price,
    })) || [];

  const handleAppointmentTypeChange = (e) => {
    const selectedValue = e.target.value;
    formikRef?.current?.setFieldValue("AppoinmentType", selectedValue);
    setValidate(selectedValue);
    formikRef?.current?.setFieldValue("items", []);
    console.log("AppoinmentType set to:", selectedValue);
  };

  const handleCompanySelect = (value) => {
    setPriceListId(value);
    fetchPriceList(value);
  };
  const prevPriceListIdRef = useRef();
  const fetchPriceList = useCallback((id) => {
    if (id && id !== prevPriceListIdRef.current) {
      console.log("Fetching price list for ID:", id);
      getPriceListByID(
        setLoading,
        {
          payer_id: String(id),
        },
        setItemsList
      );
      prevPriceListIdRef.current = id;
    }
  }, []);
  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(formikProps) => (
          <div className="flex h-screen rounded-md bg-gray-100 p-6">
            <div
              className={`${
                validate === "" || !paidToday ? "w-full" : "w-2/3"
              } pr-6 overflow-auto`}
            >
              <h2 className="text-xl font-semibold mb-2 flex items-center gap-1 bg-white shadow-md p-2 rounded-md text-gray-800">
                <div
                  className="flex justify-end items-end cursor-pointer"
                  onClick={() => navigate("/receptionist/visits")}
                >
                  <IconButton
                    aria-label="go back"
                    className="!mr-2 !w-[30px] !h-[30px] !text-white !bg-red-400 !rounded-full"
                  >
                    <FaArrowLeft />
                  </IconButton>
                </div>
                {appointmentBillToPay ? "Pay Bill" : " Create New Visit"}
              </h2>
              <Form onSubmit={formikProps.handleSubmit}>
                {console.log(formikProps?.values)}
                <div className="bg-white rounded-lg shadow-md p-6 mb-2">
                  <div className="grid grid-cols-4 gap-[0.5rem]">
                    <SharedInput
                      name="visit_date"
                      type="date"
                      label="Visit Date"
                      value={formikProps.values.start}
                      onChange={(e) => {
                        formikProps.handleChange(e);

                        const startDate = new Date(e.target.value);
                        const currentDate = new Date();

                        startDate.setHours(0, 0, 0, 0);
                        currentDate.setHours(0, 0, 0, 0);

                        const isInFuture = startDate > currentDate;
                        setPaidToday(!isInFuture);
                        if (
                          formikProps?.values?.practitioner_id &&
                          formikProps?.values?.visit_date
                        ) {
                          getslots(setLoading, setSlotList, {
                            practitioner_id:
                              formikProps?.values?.practitioner_id,
                            visit_date: formikProps?.values?.visit_date,
                          });
                        }
                      }}
                      min={today}
                    />

                    <SharedSearchDropdown
                      name="customer_id"
                      label="Patient"
                      optionLabel="Select Patient"
                      data={patientList?.data?.map((patient) => ({
                        value: patient.his_refrence,
                        label: patient.name,
                        refrence: patient.id,
                      }))}
                      onItemAdd={(value) => {
                        const selectedPatient = patientList?.data?.find(
                          (patient) => patient.his_refrence === value
                        );

                        formikProps.setFieldValue(
                          "AppoinmentType",
                          selectedPatient.beneficiary_type
                        );
                        setValidate(selectedPatient.beneficiary_type);
                        setMaxLimit(selectedPatient.max_limit);
                        setRef(selectedPatient.id);
                      }}
                    />

                    {paidToday && (
                      <div className="flex gap-1 flex-col">
                        {console.log(formikProps?.values)}
                        <label className="text-sm">Patient Type</label>

                        <div className="flex w-full">
                          <button
                            type="button"
                            onClick={() => {
                              formikProps.setFieldValue(
                                "AppoinmentType",
                                "Cash"
                              );
                              setValidate("Cash");
                            }}
                            className={`w-[50%] border p-1.5 transition-all rounded-tl-md rounded-bl-md  !border-r-0  ${
                              formikProps.values.AppoinmentType === "Cash"
                                ? "!bg-primary !border-primary text-white"
                                : null
                            }`}
                          >
                            Cash
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              formikProps.setFieldValue(
                                "AppoinmentType",
                                "Insurance"
                              );
                              setValidate("Insurance");
                            }}
                            className={`w-[50%] border  p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                              formikProps.values.AppoinmentType === "Insurance"
                                ? "!bg-primary !border-primary text-white"
                                : null
                            }`}
                          >
                            Insurance
                          </button>
                        </div>
                        {formikProps.errors.AppoinmentType &&
                        formikProps.touched.AppoinmentType ? (
                          <p className="max-w-2xl text-sm leading-6 text-red-500">
                            {formikProps.errors.AppoinmentType}
                          </p>
                        ) : null}
                      </div>
                    )}

                    <SharedSearchDropdown
                      name="practitioner_id"
                      label="Doctor"
                      optionLabel="Select doctor"
                      data={doctorList?.data?.map((doctor) => ({
                        value: doctor.practitioner_id,
                        label: doctor.name,
                        speciality: doctor?.speciality,
                      }))}
                      onItemAdd={(value) => {
                        if (formikProps?.values?.visit_date) {
                          getslots(setLoading, setSlotList, {
                            practitioner_id: value,
                            visit_date: formikProps?.values?.visit_date,
                          });
                        }
                        const selectedDoctor = doctorList?.data?.find(
                          (doc) => doc.practitioner_id === value
                        );

                        if (selectedDoctor) {
                          formikProps.setFieldValue(
                            "speciality",
                            selectedDoctor.speciality
                          );
                        }
                      }}
                    />
                    {appointmentBillToPay?.start ? (
                      <SharedInput
                        name="slots"
                        label="Slots"
                        disabled="true"
                        value={formikProps?.values?.slots}
                      />
                    ) : (
                      <SharedDropdown
                        name="slots"
                        label="Slots"
                        optionLabel="Select"
                        data={changeSlotsList(slotsList)}
                      />
                    )}
                    <SharedInput
                      name="speciality"
                      type="text"
                      label="Speciality"
                      disabled="true"
                    />
                    {/* <SharedSearchDropdown
                      name="speciality"
                      label="Speciality"
                      optionLabel="Select"
                      data={medicalSpecialties}
                    /> */}
                    <SharedDropdown
                      name="visit_type"
                      label="Visit Type"
                      optionLabel="Select"
                      data={appointmentTypes}
                    />
                    {paidToday && (
                      <SharedDropdown
                        name="payment_method"
                        label="Payment Method"
                        optionLabel="Select"
                        data={paymentMethods}
                      />
                    )}
                    {/* <SharedDropdown
                      name="service_categories"
                      label="Service Categories"
                      optionLabel="Select"
                      data={serviceCategories}
                    />
                    <SharedDropdown
                      name="service_type"
                      label="Service Type"
                      optionLabel="Select"
                      data={serviceType}
                    />

                    <SharedDropdown
                      name="reasons"
                      label="Reasons"
                      optionLabel="Select"
                      data={reasons}
                    /> */}

                    <SharedInput name="comment" label="Comment" />
                  </div>
                </div>

                {formikProps.values.AppoinmentType !== "" && paidToday && (
                  <div className="bg-white rounded-lg shadow-md p-6 mb-2">
                    <div
                      className={`grid ${
                        validate === "Insurance" ? "grid-cols-4" : "grid-cols-3"
                      } gap-[0.5rem]`}
                    >
                      <SharedInput name="date" label="Date" type="date" />
                      <SharedInput name="description" label="Description" />

                      {/*     <div className="flex gap-1 flex-col">
                        <label className="text-sm">Paid</label>

                      <div className="flex w-full">
                          <button
                            type="button"
                            onClick={() => {
                              formikProps.setFieldValue("paid", false);
                            }}
                            className={`w-[50%] border p-2 transition-all rounded-tl-md rounded-bl-md  !border-r-0  ${
                              formikProps.values.paid === false
                                ? "!bg-primary !border-primary text-white"
                                : null
                            }`}
                          >
                            False
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              formikProps.setFieldValue("paid", true);
                            }}
                            className={`w-[50%] border  p-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                              formikProps.values.paid === true
                                ? "!bg-primary !border-primary text-white"
                                : null
                            }`}
                          >
                            True
                          </button>
                        </div>
                        {formikProps.errors.AppoinmentType &&
                        formikProps.touched.AppoinmentType ? (
                          <p className="max-w-2xl text-sm leading-6 text-red-500">
                            {formikProps.errors.AppoinmentType}
                          </p>
                        ) : null}
                      </div> */}

                      {formikProps.values.AppoinmentType === "Insurance" &&
                        paidToday && (
                          <div className="flex flex-col gap-1">
                            <label htmlFor="payer_id">Payer*</label>
                            <select
                              id="payer"
                              name="payer"
                              onChange={(event) => {
                                formikProps.handleChange(event);
                                const selectedPayerId = event.target.value;
                                setPayerID(selectedPayerId);
                              }}
                              value={formikProps.values.payer}
                              autoComplete="payer"
                              className="w-full border rounded-lg"
                            >
                              <option value={""}>Select Payer</option>
                              {payersData?.map((category, index) => (
                                <optgroup
                                  label={
                                    category?.name_en
                                      ? category?.name_en +
                                        ` (${category?.license_id})`
                                      : ""
                                  }
                                  key={category.id}
                                >
                                  {category.payers.map((insurance, index) => (
                                    <option key={index} value={insurance.id}>
                                      {insurance?.name_en} (
                                      {insurance?.license_id})
                                    </option>
                                  ))}
                                </optgroup>
                              ))}
                            </select>
                            {formikProps.errors.payer &&
                            formikProps.touched.payer ? (
                              <p className="max-w-2xl text-sm leading-6 text-red-500">
                                {formikProps.errors.payer}
                              </p>
                            ) : null}
                          </div>
                        )}
                    </div>
                  </div>
                )}

                {formikProps.values.AppoinmentType !== "" && paidToday && (
                  <div className="bg-white rounded-lg shadow-md p-6 mb-2">
                    <h3 className="text-xl font-semibold mb-4 text-gray-700">
                      Items
                    </h3>
                    {/* <div className="flex gap-4 items-end mb-4">
                      <SharedSearchDropdown
                        label="Add Item"
                        name="tempItem"
                        placeholder="Select item"
                        className="flex-grow !w-full"
                        data={ItemsOptions}
                        optionLabel="Select an item"
                      />
                      <Button
                        variant="contained"
                        onClick={() => {
                          const selectedValue = formikProps.values.tempItem;
                          if (selectedValue) {
                            const newItem = {
                              id: Number(selectedValue),
                              quantity: 1,
                              itemAmout: getAmount(Number(selectedValue)),
                              patient_share: 20,
                              discount_type: "fixed",
                              discount_amount: 0,
                              tax_type: "percentage",
                              tax_amount: 15,
                            };
                            formikProps.setFieldValue("items", [
                              ...formikProps.values.items,
                              newItem,
                            ]);
                            formikProps.setFieldValue("tempItem", "");
                          }
                        }}
                        style={{
                          backgroundImage:
                            "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                        }}
                        className="px-6 py-2 w-[20%] text-white font-semibold rounded-md shadow-sm hover:shadow-md h-[40px]  transition-all duration-300"
                      >
                        Add
                      </Button>
                    </div> */}
                    {/* <div className="overflow-x-auto">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                          <tr>
                            <th className="px-4 py-2">Item</th>
                            <th className="px-4 py-2">Qty</th>
                            {validate === "Insurance" && (
                              <th className="px-4 py-2">Patient Share</th>
                            )}
                            <th className="px-4 py-2">Discount</th>
                            <th className="px-4 py-2">Tax</th>

                            <th className="px-4 py-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formikProps.values.items.map((item, index) => (
                            <tr key={index} className="bg-white border-b">
                              <td className="px-4 py-2">{getLabel(item)}</td>
                              <td className="px-4 py-2">
                                <SharedInput
                                  type="number"
                                  name={`items[${index}].quantity`}
                                  value={item.quantity}
                                  className="!w-20"
                                />
                              </td>
                              {validate === "Insurance" && (
                                <td className="px-4 py-2">
                                  <SharedInput
                                    type="number"
                                    name={`items[${index}].patient_share`}
                                    value={item.patient_share}
                                    className="!w-24"
                                  />
                                </td>
                              )}
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <SharedDropdown
                                    name={`items[${index}].discount_type`}
                                    value={item.discount_type}
                                    data={typeOptions}
                                    optionLabel="select"
                                    disabled={true}
                                    className="!w-24"
                                  />
                                  <SharedInput
                                    type="number"
                                    name={`items[${index}].discount_amount`}
                                    value={item.discount_amount}
                                    className="!w-20"
                                  />
                                </div>
                              </td>
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <SharedDropdown
                                    name={`items[${index}].tax_type`}
                                    data={typeOptions}
                                    value={item.tax_type}
                                    optionLabel="select"
                                    disabled={true}
                                    className="!w-24"
                                  />
                                  <SharedInput
                                    type="number"
                                    name={`items[${index}].tax_amount`}
                                    disabled={true}
                                    value={item.tax_amount}
                                    className="!w-20"
                                  />
                                </div>
                              </td>

                              <td className="px-4 py-2">
                                <Button
                                  color="error"
                                  onClick={() => {
                                    const newItems =
                                      formikProps.values.items.filter(
                                        (_, i) => i !== index
                                      );
                                    formikProps.setFieldValue(
                                      "items",
                                      newItems
                                    );
                                  }}
                                >
                                  <RiDeleteBin5Line />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div> */}
                    <div className="field flex flex-col gap-1 relative">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                        <BiSearch className="h-5 w-5 text-gray-400" />
                      </span>
                      <input
                        type="text"
                        placeholder="Search for item by Service Code or Service Description"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        className="block w-full py-2 !px-10 "
                      />
                      {searchTerm.length > 0 && (
                        <span
                          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={() => {
                            setSearchResult([]);
                            setSearchTerm("");
                          }}
                        >
                          <BiX className="h-7 w-7 text-gray-400" />
                        </span>
                      )}
                      {searchResult.length > 0 ? (
                        <ul className="absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto">
                          {searchResult.map((item, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleSelectSearchItem(formikProps, item)
                              }
                              className="py-1 px-3 cursor-pointer hover:bg-gray-100 border-b border-gray-300"
                            >
                              <div className="flex gap-3">
                                <div className="my-auto w-36 font-semibold capitalize ">
                                  {item?.service_type}
                                </div>
                                <div className="pl-3 border-l border-gray-500">
                                  <div className="border-b border-gray-200">
                                    {item?.service_code}
                                    {item?.service_desc}
                                  </div>
                                  <div>
                                    {item?.non_standard_code}
                                    {item?.non_standard_desc}
                                  </div>
                                </div>
                              </div>
                              {/* Assuming item has a 'name' property */}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        searchTerm.length > 3 && (
                          <ul className="absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto">
                            <li className="py-1 px-3 cursor-pointer font-medium text-center text-lg">
                              No Result Found
                            </li>
                          </ul>
                        )
                      )}
                    </div>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Unit Price
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Quantity
                            </th>
                            {formikProps?.values?.AppoinmentType ===
                              "Insurance" && (
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Patient Share
                              </th>
                            )}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Discount
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Tax
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formikProps.values.items.map((item, index) => (
                            <tr key={index} className="bg-white border-b">
                              <td className="px-4 py-2">
                                {item?.service_desc}
                              </td>
                              <td className="px-4 py-2">
                                <SharedInput
                                  type="number"
                                  name={`items[${index}].unit_price`}
                                  value={item.unit_price}
                                  className="!w-20"
                                  min={0}
                                />
                              </td>
                              <td className="px-4 py-2">
                                <SharedInput
                                  type="number"
                                  name={`items[${index}].quantity`}
                                  value={item.quantity}
                                  className="!w-20"
                                  min={0}
                                />
                              </td>
                              {formikProps?.values?.AppoinmentType ===
                              "Insurance" ? (
                                <td className="px-4 py-2">
                                  <SharedInput
                                    name={`items[${index}].patient_share`}
                                    value={item.patient_share}
                                    type="number"
                                    disabled={true}
                                    className="!w-24"
                                  />
                                </td>
                              ) : null}
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <SharedDropdown
                                    name={`items[${index}].discount_type`}
                                    value={item.discount_type}
                                    data={typeOptions}
                                    optionLabel="select"
                                    // disabled={true}
                                    className="!w-24"
                                  />
                                  <SharedInput
                                    type="number"
                                    name={`items[${index}].discount_amount`}
                                    value={item.discount_amount}
                                    className="!w-20"
                                    min={0}
                                  />
                                </div>
                              </td>

                              <td className="px-4 py-2">
                                <div className="flex w-full">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      formikProps.setFieldValue(
                                        `items[${index}].tax_check`,
                                        false
                                      );
                                    }}
                                    className={`w-[50%] border p-2 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                      formikProps.values.items[index]
                                        .tax_check === false
                                        ? "!bg-primary !border-primary text-white"
                                        : ""
                                    }`}
                                  >
                                    False
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      formikProps.setFieldValue(
                                        `items[${index}].tax_check`,
                                        true
                                      );
                                    }}
                                    className={`w-[50%] border p-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                      formikProps.values.items[index]
                                        .tax_check === true
                                        ? "!bg-primary !border-primary text-white"
                                        : ""
                                    }`}
                                  >
                                    True
                                  </button>
                                </div>
                              </td>
                              <td className="px-4 py-2">
                                <Button
                                  color="error"
                                  onClick={() => {
                                    const newItems =
                                      formikProps.values.items.filter(
                                        (_, i) => i !== index
                                      );
                                    formikProps.setFieldValue(
                                      "items",
                                      newItems
                                    );
                                  }}
                                >
                                  <RiDeleteBin5Line />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div className="flex justify-end mt-6">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    className="px-6 py-2 text-white font-semibold rounded-md shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    {appointmentBillToPay ? "Pay" : "Create Visit"}
                  </Button>
                </div>
              </Form>
              <AppLoader setShowLoader={setLoading} showLoader={loading} />
            </div>

            {validate !== "" && paidToday && (
              <div className="w-1/3 bg-white rounded-lg shadow-md p-6 h-fit">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                  Bill Summary
                </h3>
                <div className="space-y-4">
                  {formikProps?.values?.AppoinmentType === "Insurance" && (
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex justify-between">
                        <h4 className="text-lg font-semibold mb-2 text-gray-700">
                          Total Discount:
                        </h4>

                        <span className="font-semibold">
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? formikProps?.values?.items
                                ?.map((itm) => {
                                  const discountAmount =
                                    Number(itm?.discount_amount) || 0;
                                  const discountType = itm?.discount_type;
                                  let discount = 0;

                                  if (discountType === "percentage") {
                                    discount =
                                      (Number(itm.unit_price) *
                                        Number(itm.quantity) *
                                        discountAmount) /
                                      100;
                                  } else if (discountType === "fixed") {
                                    discount = discountAmount;
                                  }

                                  return discount;
                                })
                                .reduce((acc, curr) => acc + curr, 0)
                            : calculateShares(formikProps, 75).discountedAmount}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h4 className="text-lg font-semibold mb-2 text-gray-700">
                      Cash Bill
                    </h4>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-600">Amount:</span>
                        <span className="font-semibold">
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? formikProps?.values?.items
                                .map((itm) => itm?.unit_price * itm?.quantity)
                                .reduce((acc, curr) => acc + curr, 0)
                            : calculateShares(formikProps, 75)
                                .patientShareTotal}
                        </span>
                      </div>
                      {formikProps?.values?.AppoinmentType === "Cash" && (
                        <div className="flex justify-between">
                          <span className="text-gray-600">Discount:</span>
                          <span className="font-semibold">
                            {formikProps?.values?.AppoinmentType === "Cash"
                              ? formikProps?.values?.items
                                  ?.map((itm) => {
                                    const discountAmount =
                                      Number(itm?.discount_amount) || 0;
                                    const discountType = itm?.discount_type;
                                    let discount = 0;

                                    if (discountType === "percentage") {
                                      discount =
                                        (Number(itm.unit_price) *
                                          Number(itm.quantity) *
                                          discountAmount) /
                                        100;
                                    } else if (discountType === "fixed") {
                                      discount = discountAmount;
                                    }

                                    return discount;
                                  })
                                  .reduce((acc, curr) => acc + curr, 0)
                              : calculateShares(formikProps, 75)
                                  .discountedAmount}
                          </span>
                        </div>
                      )}

                      <div className="flex justify-between">
                        <span className="text-gray-600">Tax:</span>
                        <span className="font-semibold">
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? CalculateTax(formikProps)
                            : calculateShares(formikProps, 75).patientTaxTotal}
                        </span>
                      </div>
                      <div className="flex justify-between text-lg font-semibold">
                        <span className="text-gray-600">Net Total:</span>
                        <span>
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? CalculateNetTotal(formikProps)
                            : calculateShares(formikProps, 75)
                                .patientShareTotal +
                              calculateShares(formikProps, 75).patientTaxTotal}
                        </span>
                      </div>
                    </div>
                  </div>

                  {validate === "Insurance" && paidToday && (
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-lg font-semibold mb-2 text-gray-700">
                        Company Bill
                      </h4>
                      <div className="space-y-2">
                        <div className="flex justify-between">
                          <span className="text-gray-600">Amount:</span>
                          <span className="font-semibold">
                            {calculateShares(formikProps, 75).companyShareTotal}
                          </span>
                        </div>
                        {/* <div className="flex justify-between">
                          <span className="text-gray-600">Discount:</span>
                          <span className="font-semibold">
                            {calculateShares(formikProps, 75).discountedAmount}
                          </span>
                        </div> */}
                        <div className="flex justify-between">
                          <span className="text-gray-600">Tax:</span>
                          <span className="font-semibold">
                            {calculateShares(formikProps, 75).companyTaxTotal}
                          </span>
                        </div>
                        <div className="flex justify-between text-lg font-semibold">
                          <span className="text-gray-600">Net Total:</span>
                          <span>
                            {calculateShares(formikProps, 75)
                              .companyShareTotal +
                              calculateShares(formikProps, 75).companyTaxTotal}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <AppLoader setShowLoader={setLoading} showLoader={loading} />
              </div>
            )}
          </div>
        )}
      </Formik>
    </>
  );
}

export default NewAppointment;
