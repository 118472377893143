import React, { useState, useContext, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CgMenuLeftAlt } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { navigation } from "../../router/routes";
import { appContext } from "../../context/ContextProvider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MdOutlinePolicy } from "react-icons/md";
import "./Header.css";
import { Avatar, Button, ClickAwayListener } from "@mui/material";
import { GiPayMoney } from "react-icons/gi";
import {
  EditNotifications,
  LogoutRounded,
  Person2Rounded,
  AccountCircle,
  ListAlt,
  Diversity2Sharp,
} from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { classNames } from "classnames";
import { IoMdArrowDropdown } from "react-icons/io";

function Header(props) {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [active, setActive] = useState("Dashboard");
  const {
    sidebarOpen,
    setSidebarOpen,
    permissions,
    setPermissions,
    setToken,
    user,
    setUser,
  } = useContext(appContext);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [anchorE2, setAnchorE2] = React.useState(null);

  const [menuItems, setMenuItems] = useState([]);

  const handleMenuClick = (event, items) => {
    setAnchorE2(event.currentTarget);
    setMenuItems(items);
  };

  const handleMenuClose = () => {
    setAnchorE2(null);
    setMenuItems([]);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("enter");
    localStorage.clear();
    setToken(null);
    setUser({
      FullName: "",
      Email: "",
      RoleId: "",
    });
    handleClose();
  };

  // const handleOutsideClick = (event) => {
  //   if (anchorEl && !anchorEl.contains(event.target)) {
  //     setAnchorEl(null);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [anchorEl]);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  // linear-gradient(351deg, rgba(233,235,240,1) 45%, rgba(255,255,255,1) 71%)
  return (
    <header>
      <div
        className="header-container !m-0 !shadow-lg"
        style={{
          backgroundColor: "#373d45",
        }}
      >
        <div className="header px-4 lg:px-8">
          <div className="flex gap-3">
            <div
              className="flex xl:hidden cursor-pointer  items-center"
              onClick={() => setToggle(!toggle)}
            >
              <CgMenuLeftAlt className="text-white text-5xl " />
            </div>
            <div>
              <img
                src="/images/supergit-02.png"
                alt="logo"
                className="w-10 lg:w-12"
              />
            </div>
          </div>
          {/* <nav className="header-nav hidden xl:flex items-center gap-2.5 ">
            {navigation.map((item) => {
              return (
                <NavLink
                  key={item.name}
                  to={item.path}
                  onClick={() => setActive(item.name)}
                  className={classNames(
                    item.path == location.pathname.split("/")[1]
                      ? "active"
                      : "",
                    "text-xs lg:text-sm text-white !font-semibold"
                  )}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </nav> */}

          <nav className="header-nav hidden xl:flex items-center gap-2.5">
            {navigation().map((item) => (
              <div key={item.name} className="relative flex items-center">
                <NavLink
                  to={item.path}
                  onClick={(event) => {
                    item.command && item.command();
                    if (item.menu && item.menu.length > 0) {
                      event.preventDefault(); // Prevent navigation if there's a submenu
                      handleMenuClick(event, item.menu);
                    } else {
                      setActive(item.name);
                    }
                  }}
                  className={classNames(
                    item.path === location.pathname.split("/")[1]
                      ? "active"
                      : "",
                    "text-xs lg:text-sm text-white !font-semibold !flex gap-2"
                  )}
                >
                  {item.name}
                  {item.menu && item.menu.length > 0 && (
                    <span className="my-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                      >
                        <path d="M4.5 6L0 0H9L4.5 6Z" fill="#ffffff" />
                      </svg>
                    </span>
                  )}
                </NavLink>
              </div>
            ))}

            <StyledMenu
              id="simple-menu"
              anchorEl={anchorE2}
              keepMounted
              open={Boolean(anchorE2)}
              onClose={handleMenuClose}
            >
              {menuItems.map((menuItem, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    navigate(menuItem.path);
                    handleMenuClose();
                  }}
                >
                  {menuItem?.icon && <menuItem.icon />}
                  {menuItem.name}
                </MenuItem>
              ))}
            </StyledMenu>
          </nav>

          <div
            className={`overlay ${toggle ? "show" : ""}`}
            // onClick={() => setToggle(!toggle)}
          >
            <div className={`sidebar ${toggle ? "open" : ""} !p-0`}>
              <div className="flex justify-between p-2 pb-3 border-b border-b-text-gray-800">
                <img
                  src="/images/supergit-01.png"
                  alt="logo"
                  className="w-10"
                />
                <div
                  className="cursor-pointer icon-container h-8 w-8 rounded-full flex justify-center content-center flex-wrap"
                  onClick={() => setToggle(!toggle)}
                >
                  <IoClose className="icon-close text-2xl" />
                </div>
              </div>
              {/* Add your sidebar content here */}
              <ul className="side-bar-nav mt-3">
                {navigation().map((nav, index) => (
                  <li
                    key={index}
                    className={classNames(
                      nav.name === active ? "active" : "",
                      "font-semibold cursor-pointer text-[16px]"
                    )}
                  >
                    <NavLink
                      key={nav.name}
                      className={
                        (classNames(
                          nav.name === active ? "!text-white" : "!text-black"
                        ),
                        "!flex gap-2")
                      }
                      to={nav.path}
                      onClick={(event) => {
                        nav.command && nav.command();
                        if (nav.menu && nav.menu.length > 0) {
                          event.preventDefault(); // Prevent navigation if there's a submenu
                          handleMenuClick(event, nav.menu);
                        } else {
                          setActive(nav.name);
                        }
                      }}
                    >
                      {nav.name}
                      {nav.menu && nav.menu.length > 0 && (
                        <i className="font-bold">
                          <IoMdArrowDropdown />
                        </i>
                      )}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="user-info flex gap-3 items-center ml-auto mr-0">
            {/* <div className="tasks bg-transparent">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M14.6665 3.66675H16.4998C16.9861 3.66675 17.4524 3.8599 17.7962 4.20372C18.14 4.54754 18.3332 5.01385 18.3332 5.50008V18.3334C18.3332 18.8196 18.14 19.286 17.7962 19.6298C17.4524 19.9736 16.9861 20.1667 16.4998 20.1667H5.49984C5.01361 20.1667 4.54729 19.9736 4.20347 19.6298C3.85966 19.286 3.6665 18.8196 3.6665 18.3334V5.50008C3.6665 5.01385 3.85966 4.54754 4.20347 4.20372C4.54729 3.8599 5.01361 3.66675 5.49984 3.66675H7.33317"
                  stroke="#242429"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.7502 1.83325H8.25016C7.7439 1.83325 7.3335 2.24366 7.3335 2.74992V4.58325C7.3335 5.08951 7.7439 5.49992 8.25016 5.49992H13.7502C14.2564 5.49992 14.6668 5.08951 14.6668 4.58325V2.74992C14.6668 2.24366 14.2564 1.83325 13.7502 1.83325Z"
                  stroke="#242429"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.4065 10.0747L14.4065 10.0747C14.1147 9.77494 13.6412 9.77494 13.3494 10.0749L10.2164 13.2949L8.92524 11.9679L8.92523 11.9679C8.63342 11.6681 8.15996 11.6681 7.86815 11.9679L7.86814 11.9679C7.57723 12.267 7.57722 12.7514 7.86815 13.0504L9.68779 14.9207C9.97959 15.2206 10.4532 15.2206 10.7449 14.9207C10.7449 14.9207 10.7449 14.9207 10.7449 14.9207L14.4065 11.1573C14.6975 10.8583 14.6975 10.3739 14.4065 10.0747Z"
                  fill="#242429"
                  stroke="#242429"
                  strokeWidth="0.1"
                />
              </svg>
            </div>
            <div className="notification bg-transparent">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                  stroke="#242429"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                  stroke="#242429"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokewinejoin="round"
                />
              </svg>
            </div> */}
            {/* <ClickAwayListener onClickAway={handleClose}> */}
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              size="small"
            >
              <div className="user-info-view text-white">
                <div>
                  {/* <img src="images/user-img.png" alt="" /> */}
                  <Avatar className="uppercase" style={{ color: "#373d45" }}>
                    {user?.FullName ? user?.FullName.charAt(0) : "S"}
                  </Avatar>
                </div>
                <div>
                  <div className="user-name text-white">
                    {user?.FullName}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                      >
                        <path d="M4.5 6L0 0H9L4.5 6Z" fill="#ffffff" />
                      </svg>
                    </span>
                  </div>
                  <div>
                    <div className="company-admin !text-[#e9ebf0]">
                      {" "}
                      {user?.RoleName}
                    </div>
                  </div>
                </div>
              </div>
            </Button>
            {/* </ClickAwayListener> */}
            <StyledMenu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {user?.RoleId === 9 ? null : (
                <MenuItem
                  onClick={() => {
                    navigate("/physicians");
                    handleClose();
                  }}
                >
                  <Person2Rounded /> Physicians
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  navigate("/receptionist/insurance_policy");
                  handleClose();
                }}
              >
                <MdOutlinePolicy className="mr-3" />
                Insurance Policy
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/his/payers");
                  handleClose();
                }}
              >
                <GiPayMoney className="mr-3" />
                Payers
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/price-list");
                  handleClose();
                }}
              >
                <ListAlt /> Price List
              </MenuItem>
              {/* <MenuItem onClick={handleClose}>
                <AccountCircle /> Profile
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleLogout();
                }}
                style={{ color: "red", fontWeight: "bold" }}
              >
                <LogoutRounded /> Logout
              </MenuItem>
            </StyledMenu>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
