import { FileUpload, HistoryOutlined } from "@mui/icons-material";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { hisLogin } from "../pages/HIS/components/ApiMethods";
import { useEffect } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ReceiptIcon from "@mui/icons-material/Receipt";

const navigationConfig = {
  6: [
    {
      name: "Dashboard",
      path: "dashboard",
      permission: "view-dashboard",
    },
    {
      name: "Beneficiary",
      path: "beneficiary",
      permission: "view-beneficiary",
    },
    {
      name: "Eligibility",
      path: "eligibility",
      permission: "view-eligibility",
    },
    {
      name: "Pre-Authorization",
      path: "preauth",
      permission: "view-preauth",
    },
    {
      name: "Claims",
      path: "claims",
      permission: "view-claims",
      menu: [
        {
          name: "Transaction",
          path: "claims",
          icon: FormatListBulletedOutlinedIcon,
        },
        {
          name: "Submissions",
          path: "claims/submit",
          icon: PublishedWithChangesOutlinedIcon,
        },
        {
          name: "Excel Uploads",
          path: "claims/uploads",
          icon: FileUpload,
        },
        {
          name: "History",
          path: "claims/history",
          icon: HistoryOutlined,
        },
      ],
    },
    {
      name: "Payments / Reconciliation",
      path: "payments",
      permission: "view-payments",
    },
    {
      name: "HIS",
      path: "his",

      permission: "view-his",
      // menu: [
      //   {
      //     name: "Patient",
      //     path: "his",
      //     icon: PersonIcon,
      //   },
      // ],
    },
    {
      name: "Receptionist",
      path: "receptionist",
      permission: "view-receptionist",
      menu: [
        {
          name: "Create Patient",
          path: "beneficiary/form",
          icon: AddBoxIcon,
        },
        {
          name: "Create Visits",
          path: "receptionist/create-visit",
          icon: EditNoteIcon,
        },
        {
          name: "Invoice",
          path: "receptionist/invoice",
          icon: ReceiptIcon,
        },
      ],
    },
  ],
  9: [
    {
      name: "HIS",
      path: "his",
      // command: hisLogin,
      permission: "view-his",
    },
  ],
  7: [
    {
      name: "Beneficiary",
      path: "beneficiary",
      permission: "view-beneficiary",
    },
    {
      name: "Eligibility",
      path: "eligibility",
      permission: "view-eligibility",
    },
    // {
    //   name: "Receptionist",
    //   path: "receptionist",
    //   permission: "view-receptionist",
    //   menu: [
    //     {
    //       name: "Create Patient",
    //       path: "beneficiary/form",
    //       icon: AddBoxIcon,
    //     },
    //     {
    //       name: "Create Appointments",
    //       path: "receptionist/create-appointment",
    //       icon: EditNoteIcon,
    //     },
    //     {
    //       name: "Invoice",
    //       path: "receptionist/invoice",
    //       icon: ReceiptIcon,
    //     },
    //   ],
    // },
    {
      name: " Visits",
      path: "receptionist/visits",
    },
    {
      name: "Billing",
      path: "receptionist/invoice",
    },
  ],
};

export const getNavigation = () => {
  const user = localStorage.getItem("user");
  const roleId = user ? JSON.parse(user).RoleId : null;
  return roleId && navigationConfig[roleId] ? navigationConfig[roleId] : [];
};

// Export a function that returns the current navigation
export const navigation = () => getNavigation();

// const user = localStorage.getItem("user");
// const role_id = user && JSON.parse(user).RoleId;

// export const navigation = navigationConfig[role_id && role_id];

// if (role_id && role_id === 6) {
//   navigation.push(
//     {
//       name: "Dashboard",
//       path: "dashboard",
//       permission: "view-dashboard",
//     },
//     {
//       name: "Beneficiary",
//       path: "beneficiary",
//       permission: "view-beneficiary",
//     },
//     {
//       name: "Eligibility",
//       path: "eligibility",
//       permission: "view-eligibility",
//     },
//     // {
//     //   name: "Providers",
//     //   path: "providers",
//     //   permission: "view-providers",
//     // },
//     {
//       name: "Pre-Authorization",
//       path: "preauth",
//       permission: "view-preauth",
//     },
//     {
//       name: "Claims",
//       path: "claims",
//       permission: "view-claims",
//       menu: [
//         {
//           name: "Transaction",
//           path: "claims",
//           icon: FormatListBulletedOutlinedIcon,
//         },
//         {
//           name: "Submissions",
//           path: "claims/submit",
//           icon: PublishedWithChangesOutlinedIcon,
//         },
//         {
//           name: "Excel Uploads",
//           path: "claims/uploads",
//           icon: FileUpload,
//         },
//         {
//           name: "History",
//           path: "claims/history",
//           icon: HistoryOutlined,
//         },
//       ],
//     },
//     {
//       name: "Payments / Reconciliation",
//       path: "payments",
//       permission: "view-payments",
//     },
//     {
//       name: "HIS",
//       path: "his",
//       command: () => {
//         hisLogin();
//       },
//       permission: "view-his",
//       menu: [
//         {
//           name: "Patient",
//           path: "his/patient",
//           icon: PersonIcon,
//         },
//       ],
//     },
//     {
//       name: "ERP",
//       path: "erp",
//       command: () => {
//         emrLogin();
//       },
//       permission: "view-erp",
//       menu: [
//         {
//           name: "Invoice",
//           path: "erp/invoice",
//           icon: DescriptionOutlinedIcon,
//         },
//         {
//           name: "Report",
//           path: "erp/report",
//           icon: ReceiptLongOutlinedIcon,
//         },
//       ],
//     }
//   );
// }

// else if (role_id && role_id === 8) {
//   navigation.push({
//     name: "ERP",
//     path: "erp",
//     command: () => {
//       emrLogin();
//     },
//     permission: "view-erp",
//     menu: [
//       {
//         name: "Invoice",
//         path: "erp/invoice",
//         icon: DescriptionOutlinedIcon,
//       },
//       {
//         name: "Report",
//         path: "erp/report",
//         icon: ReceiptLongOutlinedIcon,
//       },
//     ],
//   });
// }

// else if (role_id && role_id === 9) {
//   navigation.push({
//     name: "HIS",
//     path: "his",
//     command: () => {
//       hisLogin();
//     },
//     permission: "view-his",
//     menu: [
//       {
//         name: "Patient",
//         path: "his/patient",
//         icon: PersonIcon,
//       },
//     ],
//   });
// }

// else if (role_id && role_id === 7) {
//   navigation.push(
//     // {
//     //   name: "Dashboard",
//     //   path: "dashboard",
//     //   permission: "view-dashboard",
//     // },
//     {
//       name: "Beneficiary",
//       path: "beneficiary",
//       permission: "view-beneficiary",
//     },
//     {
//       name: "Eligibility",
//       path: "eligibility",
//       permission: "view-eligibility",
//     },
//     // {
//     //   name: "Providers",
//     //   path: "providers",
//     //   permission: "view-providers",
//     // },
//     // {
//     //   name: "Pre-Authorization",
//     //   path: "preauth",
//     //   permission: "view-preauth",
//     // },
//     // {
//     //   name: "Claims",
//     //   path: "claims",
//     //   permission: "view-claims",
//     //   menu: [
//     //     {
//     //       name: "Transaction",
//     //       path: "claims",
//     //       icon: FormatListBulletedOutlinedIcon,
//     //     },
//     //     {
//     //       name: "Submissions",
//     //       path: "claims/submit",
//     //       icon: PublishedWithChangesOutlinedIcon,
//     //     },
//     //     {
//     //       name: "Excel Uploads",
//     //       path: "claims/uploads",
//     //       icon: FileUpload,
//     //     },
//     //     {
//     //       name: "History",
//     //       path: "claims/history",
//     //       icon: HistoryOutlined,
//     //     },
//     //   ],
//     // },
//     // {
//     //   name: "Payments / Reconciliation",
//     //   path: "payments",
//     //   permission: "view-payments",
//     // },
//     // {
//     //   name: "HIS",
//     //   path: "his",
//     //   command: () => {
//     //     hisLogin();
//     //   },
//     //   permission: "view-his",
//     //   menu: [
//     //     {
//     //       name: "Patient",
//     //       path: "his/patient",
//     //       icon: PersonIcon,
//     //     },
//     //   ],
//     // },
//     {
//       name: "ERP",
//       path: "erp",
//       command: () => {
//         emrLogin();
//       },
//       permission: "view-erp",
//       menu: [
//         {
//           name: "Invoice",
//           path: "erp/invoice",
//           icon: DescriptionOutlinedIcon,
//         },
//         // {
//         //   name: "Report",
//         //   path: "erp/report",
//         //   icon: ReceiptLongOutlinedIcon,
//         // },
//       ],
//     }
//   );
// }
