import React from "react";
import { Select, MenuItem, Box, Typography, IconButton } from "@mui/material";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

function CustomPagination({
  currentPage,
  rowsPerPage,
  totalRecords,
  onPageChange,
}) {
  const handlePageChange = (page) => {
    onPageChange(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (event) => {
    onPageChange(1, parseInt(event.target.value, 10));
  };

  const totalPages = Math.ceil(totalRecords / rowsPerPage);
  const maxPagesToShow = 5;

  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...");
      } else if (currentPage > totalPages - 3) {
        pages.push(
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pages.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }
    return pages;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      mt={2}
    >
      <IconButton
        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
        sx={{ color: currentPage === 1 ? "#ccc" : "#000" }}
      >
        <ArrowLongLeftIcon
          className="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <Typography variant="body2" className="text-gray-400">
          Previous
        </Typography>
      </IconButton>

      <Box display="flex" alignItems="center">
        {getPageNumbers().map((page, index) =>
          typeof page === "number" ? (
            <Box
              key={index}
              sx={{
                width: 32,
                height: 32,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: currentPage === page ? "#ffab2c" : "gray",
                fontWeight: "bold",
                mr: 1,
                cursor: "pointer",
              }}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Box>
          ) : (
            <Typography key={index} variant="body2">
              {page}
            </Typography>
          )
        )}
      </Box>

      <Box display="flex" alignItems="center">
        <Typography variant="body2" mr={1}>
          Rows per page:
        </Typography>
        <Select
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          variant="standard"
          sx={{ minWidth: 60, mr: 2 }}
        >
          {[5, 10, 15, 20].map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>

        <IconButton
          onClick={() =>
            handlePageChange(Math.min(totalPages, currentPage + 1))
          }
          disabled={currentPage === totalPages}
          sx={{ color: currentPage === totalPages ? "#ccc" : "#000" }}
        >
          <Typography variant="body2" className="text-gray-400">
            Next
          </Typography>
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CustomPagination;
