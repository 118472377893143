import { gql } from "@apollo/client";

const GET_PAYER_CLAIMS = gql`
  query listPayerClaims($input: ClaimFiltersInput) {
    listPayerClaims(filters: $input) {
      status
      message
      data {
        id
        physician_name
        member_id
        patient_document_id
        file_no
        claim_date
        total_net
        transaction_id
        patient_name
        payer_name
        date_ordered
        type
      }
      pages
      total
    }
  }
`;

const GET_CLAIMS_BY_FILTER = gql`
  query listClaimTransactions($input: ClaimTransactionsFilterInput!) {
    listClaimTransactions(input: $input) {
      message
      status
      data {
        patient_name
        response_date
        type
        disposition
        payer_name
        outcome
        status

        episode_no
        total_net
        transaction_id
        request_bundle_id
        response_bundle_id
        batch_id
        date_ordered
        tp_ref_no
        is_followup_req
        followup_ref_transaction_id
        communication_count
        is_valid
        errors {
          error_message
          error_code
        }
      }
      pages
      total
    }
  }
`;

const CREATE_CLAIM = gql`
  mutation createClaim($input: PreAuthInput!) {
    createClaim(input: $input) {
      message
      status
      data {
        transaction_id
      }
    }
  }
`;

const SUBMIT_CLAIMS = gql`
  mutation submitClaims($input: SubmitClaimsInput!) {
    submitClaims(input: $input) {
      message
      nphies_err {
        code
        value_string
        display
      }
      nphies_status
      status
    }
  }
`;

const GET_CLAIMS_TRAN_DETAILS_SI = gql`
  query getClaimTransaction($transactionId: String!) {
    getClaimTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        supporting_info {
          category
          code
          code_text
          start_date
          end_date
          value
          unit
          image_value
          image_title
          image_content_type
          image_creation_date
          reason
        }
      }
    }
  }
`;

const GET_CLAIMS_TRAN_DETAILS_WITHOUT_SI = gql`
  query getClaimTransaction($transactionId: String!) {
    getClaimTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        authorization_id
        transaction_id
        request_bundle_id
        response_bundle_id
        transaction_date
        date_ordered
        outcome
        status
        type
        subtype
        errors {
          error_message
          error_code
        }
        payer_name
        payer_id
        insurance_plan_id
        tpa_name
        pre_auth_ref_no
        pre_auth_ref_url
        pre_auth_ref_value
        pre_auth_period
        is_followup_req
        followup_ref_transaction_id
        priority
        related_pre_auth_req_identifier
        offline_verification_id
        offline_verification_date
        pre_auth_resp_value
        pre_auth_resp_url
        offline_pre_auth_no
        offline_pre_auth_date
        episode_no
        verification_resp_ident_value
        verification_resp_ident_url
        disposition
        is_referral
        is_new_born
        insurance_plan_id
        related_pre_auth_ref_no
        calcellation_status
        calcellation_status_reason
        patient_document_id
        patient_name
        patient_gender
        patient_dob
        patient_contact_no
        patient_email
        is_valid
        validation_errors {
          error_date
          errors {
            error_code
            error_message
            value_string
          }
        }

        insurance_plan {
          id
          member_card_id
          policy_number
          expiry_date
          relation_with_subscriber
          coverage_type
        }
        items {
          sequence_no
          type
          standard_code
          standard_description
          net
          tax_amount
          non_standard_code
          non_standard_description
          service_start_date
          service_end_date
          is_package
          body_site
          sub_site
          quantity
          unit_price
          discount_amount
          tax_amount
          patient_share
          payer_share
          patient_share_amount
          factor
          approved_value
          benefit_value
          benefit_currency
          status
          reject_reason_code
          reject_reason_display
          invoice_no
          supporting_info
          diagnosis
          care_teams
          medication_selection_reason
          prescribed_medication
        }
        care_teams {
          id
          name
          practitioner_role
          physician_id
          care_team_role
          qualification
          speciality
        }
        diagnosis {
          type
          code
          description
          on_admission
        }
        patient {
          id
          full_name
          document_id
          dob
          contact_number
          gender
          email
        }
      }
    }
  }
`;

const GET_CLAIMS_TRAN_DETAILS = gql`
  query getClaimTransaction($transactionId: String!) {
    getClaimTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        authorization_id
        transaction_id
        request_bundle_id
        response_bundle_id
        transaction_date
        date_ordered
        outcome
        status
        type
        subtype
        errors {
          error_message
          error_code
        }
        payer_name
        payer_id
        insurance_plan_id
        tpa_name
        pre_auth_ref_no
        pre_auth_ref_url
        pre_auth_ref_value
        pre_auth_period
        is_followup_req
        followup_ref_transaction_id
        priority
        related_pre_auth_req_identifier
        offline_verification_id
        offline_verification_date
        pre_auth_resp_value
        pre_auth_resp_url
        offline_pre_auth_no
        offline_pre_auth_date
        episode_no
        verification_resp_ident_value
        verification_resp_ident_url
        disposition
        is_referral
        is_new_born
        insurance_plan_id
        related_pre_auth_ref_no
        calcellation_status
        calcellation_status_reason
        patient_document_id
        patient_name
        patient_gender
        patient_dob
        patient_contact_no
        patient_email
        is_valid
        validation_errors {
          error_date
          errors {
            error_code
            error_message
            value_string
          }
        }

        insurance_plan {
          id
          member_card_id
          policy_number
          expiry_date
          relation_with_subscriber
          coverage_type
        }
        items {
          sequence_no
          type
          standard_code
          standard_description
          net
          tax_amount
          non_standard_code
          non_standard_description
          service_start_date
          service_end_date
          is_package
          body_site
          sub_site
          quantity
          unit_price
          discount_amount
          tax_amount
          patient_share
          payer_share
          patient_share_amount
          factor
          approved_value
          benefit_value
          benefit_currency
          status
          reject_reason_code
          reject_reason_display
          invoice_no
          supporting_info
          diagnosis
          care_teams
          medication_selection_reason
          prescribed_medication
        }
        care_teams {
          id
          name
          practitioner_role
          physician_id
          care_team_role
          qualification
          speciality
        }
        supporting_info {
          category
          code
          code_text
          start_date
          end_date
          value
          unit
          image_value
          image_title
          image_content_type
          image_creation_date
          reason
        }
        diagnosis {
          type
          code
          description
          on_admission
        }
        patient {
          id
          full_name
          document_id
          dob
          contact_number
          gender
          email
        }
      }
    }
  }
`;

const GET_CLAIMS_TRAN_DETAILS_LOGS = gql`
  query getClaimTransaction($transactionId: String!) {
    getClaimTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        logs {
          id
          transaction_id
          timestamp
          type
          log
        }
      }
    }
  }
`;

const CHECK_STATUS = gql`
  mutation checkStatusRequest($input: TransactionInput!) {
    checkStatusRequest(input: $input) {
      message
      nphies_err {
        code
        value_string
        display
      }
      nphies_status
      status
    }
  }
`;

const GET_PREAUTH_TO_CLAIMS_DATA = gql`
  query getPreAuthToClaimsData($input: PreAuthConversionFilters) {
    getAllPreAuthData(input: $input) {
      Status
      Message
      Data {
        PatientName
        PatientNphiesId
        PreAuthRefNo
        PreAuthId
        RequestedDate
        Payer
        TotalPatientShare
        TotalPayerShare
        TotalNet
        TotalTax
        TotalPayerShareWithTax
        TotalBenefit
        TotalDiscount
        Status
        Items {
          ID
          Type
          StandardCode
          StandardDescription
          PatientShare
          PayerShare
          Net
          TaxAmount
          PayerShareWithTax
          Benefit
          Discount
        }
      }
      Pages
    }
  }
`;

const CONVERT_PREAUTH_TO_CLAIMS = gql`
  mutation preAuthToClaimConversion($input: PreAuthToClaim!) {
    preAuthToClaimConversion(input: $input) {
      Message
      Status
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation uploadFile($input: UploadFileInput) {
    uploadFile(input: $input) {
      Message
      Status
    }
  }
`;

const CLAIMS_POLL_REQ = gql`
  query {
    pollRequest(request_name: "claims") {
      status
      message
      data
    }
  }
`;

const CLAIMS_REPORT_EXCEL = gql`
  query getClaimsReportExcel($input: ClaimsReportInput!) {
    getClaimsReportExcel(input: $input) {
      message
      status
      data
    }
  }
`;

// midtable_claims
// thrid_party_claims
// portal_claims

const CLAIMS_SUBMISSION_STATS = gql`
  query claimSubmissionStats($input: ClaimSubmissionStatsInput!) {
    claimSubmissionStats(input: $input) {
      message
      status
      data {
        month
        claims {
          payer_id
          payer_name
          tpa_name_en
          total_claims
          midtable_claims
          portal_claims
          third_party_claims
        }
      }
    }
  }
`;

const CLAIMS_STATS_HISTORY = gql`
  query claimsStats($input: ClaimSubmissionStatsInput!) {
    claimsStats(input: $input) {
      message
      status
      data {
        month
        claims {
          payer_id
          payer_name
          batch_id
          tpa_name_en
          tpa_id
          total_claims
          ready_claims
          approved_claims
          rejected_claims
          partial_claims
          pending_claims
          cancelled_claims
          errored_claims
          validation_errors_claims
          abnormal_claims
          pended_claims
        }
      }
    }
  }
`;

export default {
  GET_PAYER_CLAIMS,
  CREATE_CLAIM,
  SUBMIT_CLAIMS,
  GET_CLAIMS_BY_FILTER,
  GET_CLAIMS_TRAN_DETAILS,
  CHECK_STATUS,
  GET_PREAUTH_TO_CLAIMS_DATA,
  CONVERT_PREAUTH_TO_CLAIMS,
  UPLOAD_FILE,
  CLAIMS_POLL_REQ,
  CLAIMS_REPORT_EXCEL,
  GET_CLAIMS_TRAN_DETAILS_LOGS,
  CLAIMS_SUBMISSION_STATS,
  CLAIMS_STATS_HISTORY,
  GET_CLAIMS_TRAN_DETAILS_SI,
  GET_CLAIMS_TRAN_DETAILS_WITHOUT_SI,
};
