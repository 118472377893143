import { Button, IconButton, Dialog, LinearProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import JSZip from "jszip";
// import { RarArchive } from "rarjs";

import { styled } from "@mui/material/styles";
import axios from "axios";
import DragNdrop from "./DragNdrop";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function UploadClaimAttachmentModal({
  showUploadAttachmentModal,
  setShowUploadAttachmentModal,
  currentUploadData,
  setCurrentUploadData,
}) {
  const [loadingFile, setLoadingFile] = useState(false);

  const getCurrentDate = () => {
    const currentDate = new Date();

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear());

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const onImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        formik.setFieldValue("attachment", {
          content_type: file.type,
          data: reader.result?.split(",")[1],
          filename: file.name,
          creation_date: getCurrentDate(),
        });
      };
    }
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      data: [],
    },

    validationSchema: () => {
      return Yup.object().shape({
        file: Yup.mixed()
          .required("Attachment required")
          .test(
            "fileFormat",
            "Unsupported file format. Only ZIP files are allowed.",
            (value) => {
              if (value instanceof File) {
                return (
                  value.type === "application/zip" ||
                  value.type === "application/x-zip-compressed"
                );
              }
              return false;
            }
          )
          .test(
            "fileSize",
            "File size is too large. Maximum size is 1GB.",
            (value) => {
              if (value instanceof File) {
                return value.size <= 1 * 1024 * 1024 * 1024; // 1GB in bytes
              }
              return false;
            }
          ),
        data: Yup.array()
          .of(
            Yup.object().shape({
              claim_no: Yup.string().required(),
              attachment: Yup.object()
                .shape({
                  content_type: Yup.string().required(),
                  data: Yup.string().required(),
                  filename: Yup.string().required(),
                  creation_date: Yup.date().required(),
                })
                .required(),
            })
          )
          .test(
            "dataValidation",
            "All data entries must have claim_no, content_type, data, filename, and creation_date.",
            (value) => {
              if (!Array.isArray(value)) return false;
              for (const item of value) {
                if (
                  !item.claim_no ||
                  !item.attachment ||
                  !item.attachment.content_type ||
                  !item.attachment.data ||
                  !item.attachment.filename ||
                  !item.attachment.creation_date
                ) {
                  return false;
                }
              }
              return true;
            }
          )
          .required("Data is required."),
      });
    },

    onSubmit: async (values) => {
      delete values?.file;

      const token = localStorage.getItem("token");
      const tokenWithoutQuotes = token.replace(/"/g, "");

      if (!token) {
        console.error("Token not found in local storage.");
        return;
      }

      try {
        setLoadingFile(true);
        const response = await axios.post(
          `https://api.super-git.com/api/claim/excel/attachment?excel_id=${currentUploadData?.ReferenceNo}`,
          values?.data,
          {
            headers: {
              Authorization: `Bearer ${tokenWithoutQuotes}`,
            },
          }
        );
        setLoadingFile(false);
        toast.success(response.data?.message);
        formik.resetForm();
        setShowUploadAttachmentModal(false);
        setCurrentUploadData(null);
        // Handle success response
      } catch (error) {
        setLoadingFile(false);
        console.log();
        toast.error(error?.response?.data?.error);
        // Handle error response
      }
    },
  });

  const [formattedData, setFormattedData] = useState([]);

  const handleFileUpload = async (event) => {
    const file = formik.values.file;
    if (file && file.name.toLowerCase().endsWith(".zip")) {
      await handleZipFile(file);
    } else {
      alert("Please upload a ZIP file.");
    }
  };

  const handleZipFile = async (file) => {
    setLoadingFile(true);
    const zip = new JSZip();
    const zipContent = await file.arrayBuffer();
    const unzipped = await zip.loadAsync(zipContent);

    const entries = [];
    const currentDate = new Date().toISOString().split("T")[0]; // current date in YYYY-MM-DD format

    await Promise.all(
      Object.entries(unzipped.files).map(async ([relativePath, zipEntry]) => {
        if (!zipEntry.dir) {
          // Ignore directories
          const fileContent = await zipEntry.async("base64");
          const fileName = zipEntry.name;

          // Extract the base file name without extension and any numeric suffix in parentheses
          const baseFileName = fileName
            .replace(/\s*\(\d+\)\.\w+$/, "")
            .split(".")[0];
          const fileExtension = fileName.split(".").pop();
          const contentType = `application/${fileExtension}`;

          entries.push({
            claim_no: baseFileName,
            attachment: {
              content_type: contentType,
              data: fileContent,
              filename: fileName,
              creation_date: currentDate,
            },
          });
        }
      })
    );

    setLoadingFile(false);
    setFormattedData(entries);
    formik.setFieldValue("data", entries);
  };

  // const handleRarFile = async (file) => {
  //   const arrayBuffer = await file.arrayBuffer();
  //   const rar = new RarArchive(arrayBuffer);
  //   await rar.extractAll();
  //   const fileEntries = rar.files.map((rarFile) => ({
  //     name: rarFile.fileName,
  //     async getContent() {
  //       const content = await rarFile.extract();
  //       return new TextDecoder().decode(content);
  //     },
  //   }));
  //   setFiles(fileEntries);
  // };

  useEffect(() => {
    if (formik.values.file) {
      handleFileUpload();
    }
  }, [formik.values.file]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => setShowUploadAttachmentModal(false)}
        aria-labelledby="customized-dialog-title"
        open={showUploadAttachmentModal}
        PaperProps={{
          className:
            "w-full !max-w-[750px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl ",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Upload Attachment
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowUploadAttachmentModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="benefeciary-create">
            <div className="personal-data flex flex-col gap-4  !shadow-none !border-none">
              {loadingFile && (
                <LinearProgress
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#ffab2c",
                    },
                  }}
                  className="my-2"
                />
              )}

              {/* <div className="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="flex flex-col gap-1">
                  <label htmlFor="claim_no">Claim No</label>
                  <input
                    placeholder="Enter Claim No"
                    type="text"
                    name="claim_no"
                    value={formik.values?.claim_no}
                    onChange={formik.handleChange}
                    id="claim_no"
                    className="claim_no"
                  />

                  {formik.errors.claim_no && formik.touched.claim_no ? (
                    <p className=" max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.claim_no}
                    </p>
                  ) : null}
                </div>
              </div> */}

              <div className="text-sm">
                <h1 className="text-lg font-bold">Instructions</h1>
                <div className="bg-white p-2 rounded-lg shadow-md">
                  <ul className="list-decimal pl-5 space-y-2">
                    <li>
                      <strong>File Type:</strong> Only ZIP files are allowed.
                    </li>
                    <li>
                      <strong>Minimum Size:</strong> The File NamingZIP file must be at
                      least 10MB in size.
                    </li>
                    <li>
                      <strong>File Organization:</strong> All files within the
                      ZIP should not be placed inside any folders; they should
                      be at the root level.
                    </li>
                    <li>
                      <strong>File Naming:</strong> If there are two or more
                      files for the same claim number, use the following naming
                      convention:
                      <ul className="list-disc pl-5 mt-2 space-y-2">
                        <li>
                          For the first file: <code>claimnumber.pdf</code>{" "}
                          (e.g., <code>243344.pdf</code>)
                        </li>
                        <li>
                          For the second file: <code>claimnumber (1).pdf</code>{" "}
                          (e.g., <code>243344 (1).pdf</code>)
                        </li>
                        <li>
                          For the third file: <code>claimnumber (2).pdf</code>{" "}
                          (e.g., <code>243344 (2).pdf</code>)
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="section">
                <DragNdrop
                  formik={formik}
                  width="100%"
                  height="100%"
                  formattedData={formattedData}
                  setFormattedData={setFormattedData}
                />

                {formik.errors.file && formik.touched.file ? (
                  <p className=" max-w-2xl text-sm leading-6 text-red-500">
                    {formik.errors.file}
                  </p>
                ) : null}

                {formik.errors?.data && formik.touched?.data ? (
                  <p className=" max-w-2xl text-sm leading-6 text-red-500">
                    {formik.errors?.data}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            autoFocus
            disabled={loadingFile}
            onClick={() => {
              formik.handleSubmit();
            }}
            className="!text-primary hover:!bg-primary/20"
          >
            {loadingFile ? "..." : "Upload"}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* <AppModal isOpen={ loadingFile} /> */}
    </div>
  );
}
