import React, { useState } from "react";
import AppLoader from "../../../components/AppLoader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { createHisPayer, updatePayer } from "./ApiMethods";

const validationSchema = Yup.object({
  rcm_ref: Yup.string().required("RCM Reference is required"),
  type: Yup.string().required("Type is required"),
  name: Yup.string().required("Name is required"),
  license_id: Yup.string().required("License ID is required"),
});

export default function PayersForm() {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dataToUpdate = location?.state?.updateData;
  const payersData = JSON.parse(localStorage.getItem("payersData"));

  const initialValues = {
    rcm_ref: dataToUpdate?.rcm_ref || "",
    type: dataToUpdate?.type || "",
    name: dataToUpdate?.name || "",
    license_id: dataToUpdate?.license_id || "",
  };

  const handleSubmit = (values) => {
    if (dataToUpdate) {
      updatePayer(setShowLoader, {
        ...values,
        payer_id: dataToUpdate?.payer_id,
      }).then((res) => {
        navigate("/his/payers");
      });
    } else {
      createHisPayer(setShowLoader, values).then((res) => {
        navigate("/his/payers");
      });
    }
  };

  return (
    <div className="insurance-policy-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <div className="shadow-sm gap-4 items-center rounded-md flex p-3 mb-3 border">
              <div
                className="back"
                onClick={() => {
                  navigate("/his/payers");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                    fill="#4D4D53"
                  />
                </svg>
              </div>
              <div>
                <div className="text-2xl font-semibold">
                  <span>
                    {dataToUpdate ? "Update Payer" : "Create New Payer"}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 shadow-sm rounded-md p-3 border">
              <div className="grid grid-cols-4 gap-3">
                <div className="flex flex-col gap-1">
                  <label htmlFor="rcm_ref">RCM Reference*</label>
                  <Field
                    type="text"
                    name="rcm_ref"
                    id="rcm_ref"
                    placeholder="Enter RCM Reference"
                    className="w-full border rounded-lg"
                  />
                  <ErrorMessage
                    name="rcm_ref"
                    component="p"
                    className="max-w-2xl text-sm leading-6 text-red-500"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="type">Type*</label>
                  <Field
                    as="select"
                    name="type"
                    id="type"
                    className="w-full border rounded-lg"
                  >
                    <option value="">Select Type</option>
                    <option value="Tpa">TPA</option>
                    <option value="NonTpa">Non TPA</option>
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="p"
                    className="max-w-2xl text-sm leading-6 text-red-500"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="name">Name*</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                    className="w-full border rounded-lg"
                  />
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="max-w-2xl text-sm leading-6 text-red-500"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="license_id">License ID*</label>
                  <Field
                    type="text"
                    name="license_id"
                    id="license_id"
                    placeholder="Enter License ID"
                    className="w-full border rounded-lg"
                  />
                  <ErrorMessage
                    name="license_id"
                    component="p"
                    className="max-w-2xl text-sm leading-6 text-red-500"
                  />
                </div>
              </div>

              <div className="flex justify-center !w-full items-center">
                <div
                  className="rounded-md bg-gradient-to-r text-white from-[#f14f3e] to-[#fab768] w-[440px] h-[40px] mx-auto flex items-center justify-center cursor-pointer mt-[20px] mb-![10px]"
                  onClick={formik.handleSubmit}
                >
                  <span>{dataToUpdate ? "Update" : "Save"} Payer</span>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <AppLoader showLoader={showLoader} setShowLoader={setShowLoader} />
    </div>
  );
}
