import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "../../Physicians/styles/Physicians.css";
import { useLocation, useNavigate } from "react-router-dom";
import { createInsurancePolicy, updateInsurancePolicy } from "./ApiMethods";
import AppLoader from "../../../components/AppLoader";

export default function InsurancePolicyForm() {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dataToUpdate = location?.state?.updateData;
  const payersData = JSON.parse(localStorage.getItem("payersData"));

  const initialValues = {
    payer: dataToUpdate?.payer ? dataToUpdate?.payer : "",
    insurance_type: dataToUpdate?.insurance_type
      ? dataToUpdate?.insurance_type
      : "",
    policy_number: dataToUpdate?.policy_number
      ? dataToUpdate?.policy_number
      : "",
    policy_class: dataToUpdate?.policy_class ? dataToUpdate?.policy_class : "",
    policy_holder_name: dataToUpdate?.policy_holder_name
      ? dataToUpdate?.policy_holder_name
      : "",
    policy_network: dataToUpdate?.policy_network
      ? dataToUpdate?.policy_network
      : "",
    start_date: dataToUpdate?.start_date ? dataToUpdate?.start_date : "",
    end_date: dataToUpdate?.end_date ? dataToUpdate?.end_date : "",
  };

  const validationSchema = Yup.object({
    payer: Yup.string().required("Payer is required"),
    // insurance_type: Yup.string().required("Insurance type is required"),
    policy_number: Yup.string().required("Policy number is required"),
    policy_class: Yup.string().required("Policy class is required"),
    policy_holder_name: Yup.string().required("Policy holder name is required"),
    policy_network: Yup.string().required("Policy network is required"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date must be later than start date"),
  });
  const handleSubmit = (values, { resetForm }) => {
    if (dataToUpdate) {
      updateInsurancePolicy({
        setShowLoader: setShowLoader,
        data: { ...values, policy_id: dataToUpdate?._id },
      }).then((res) => {
        if (res?.message === "Insurance Policy updated successfully ") {
          resetForm();
          navigate("/receptionist/insurance_policy");
        }
      });
    } else {
      createInsurancePolicy({
        setShowLoader: setShowLoader,
        data: values,
      }).then((res) => {
        if (res?.message === "Insurance Policy Created successfully") {
          resetForm();
          navigate("/receptionist/insurance_policy");
        }
      });
    }
  };

  return (
    <div className="insurance-policy-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <div class=" shadow-sm gap-4 items-center rounded-md flex p-3 mb-3 border">
              <div
                class="back"
                onClick={() => {
                  navigate("/receptionist/insurance_policy");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                    fill="#4D4D53"
                  />
                </svg>
              </div>
              <div>
                <div class=" text-2xl font-semibold">
                  <span>Create New Policy</span>
                </div>
              </div>
            </div>
            <div className="mt-3 shadow-sm rounded-md p-3 border ">
              <div className="grid grid-cols-4 gap-3">
                <div className="flex flex-col gap-1">
                  <label htmlFor="payer_id">Payer*</label>
                  <select
                    id="payer"
                    name="payer"
                    onChange={formik.handleChange}
                    value={formik.values.payer}
                    autoComplete="payer"
                    className="w-full border rounded-lg"
                  >
                    <option value={""}>Select Payer</option>
                    {payersData?.map((category, index) => (
                      <optgroup
                        label={
                          category?.name_en
                            ? category?.name_en + ` (${category?.license_id})`
                            : ""
                        }
                        key={category.id}
                      >
                        {category.payers.map((insurance, index) => (
                          <option key={index} value={insurance.id}>
                            {insurance?.name_en} ({insurance?.license_id})
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                  {formik.errors.payer && formik.touched.payer ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.payer}
                    </p>
                  ) : null}
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="policy_number">Policy Number*</label>
                  <input
                    placeholder="Policy Number"
                    type="text"
                    name="policy_number"
                    value={formik.values.policy_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="policy_number"
                    autoComplete="policy_number"
                    className="w-full border rounded-lg"
                  />
                  {formik.errors.policy_number &&
                  formik.touched.policy_number ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.policy_number}
                    </p>
                  ) : null}
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="policy_class">Policy Class*</label>
                  <input
                    placeholder="Policy Class"
                    type="text"
                    name="policy_class"
                    value={formik.values.policy_class}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="policy_class"
                    autoComplete="policy_class"
                    className="w-full border rounded-lg"
                  />
                  {formik.errors.policy_class && formik.touched.policy_class ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.policy_class}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="policy_holder_name">
                    Policy Holder Name*
                  </label>
                  <input
                    placeholder="Policy Holder Name"
                    type="text"
                    name="policy_holder_name"
                    value={formik.values.policy_holder_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="policy_holder_name"
                    autoComplete="policy_holder_name"
                    className="w-full border rounded-lg"
                  />
                  {formik.errors.policy_holder_name &&
                  formik.touched.policy_holder_name ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.policy_holder_name}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="policy_network">Policy Network*</label>
                  <input
                    placeholder="Policy Network"
                    type="text"
                    name="policy_network"
                    value={formik.values.policy_network}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="policy_network"
                    autoComplete="policy_network"
                    className="w-full border rounded-lg"
                  />
                  {formik.errors.policy_network &&
                  formik.touched.policy_network ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.policy_network}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="start_date">Start Date*</label>
                  <input
                    placeholder="Start Date"
                    type="date"
                    name="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="start_date"
                    autoComplete="start_date"
                    className="w-full border rounded-lg"
                  />
                  {formik.errors.start_date && formik.touched.start_date ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.start_date}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="end_date">End Date*</label>
                  <input
                    placeholder="End Date"
                    type="date"
                    name="end_date"
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="start_date"
                    autoComplete="start_date"
                    className="w-full border rounded-lg"
                  />
                  {formik.errors.end_date && formik.touched.end_date ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.end_date}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-center !w-full items-center">
                <div
                  className="rounded-md bg-gradient-to-r text-white from-[#f14f3e] to-[#fab768] w-[440px] h-[40px] mx-auto flex items-center justify-center cursor-pointer mt-[20px] mb-![10px]"
                  onClick={formik.handleSubmit}
                >
                  <span>{dataToUpdate ? "Update" : "Save"} Policy</span>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <AppLoader showLoader={showLoader} setShowLoader={setShowLoader} />
    </div>
  );
}
