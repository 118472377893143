import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AppLoader from "../../../components/AppLoader";
import BeneficiaryInfo from "../../PreAuth/components/request/BeneficiaryInfo";
import PreAuthInfo from "../../PreAuth/components/request/PreAuthInfo";
import CareTeam from "../../PreAuth/components/request/CareTeam";
import Diagnosis from "../../PreAuth/components/request/Diagnosis";
import ItemsList from "../../PreAuth/components/request/ItemsList";
import IsAccident from "../../PreAuth/components/request/IsAccident";
import EncounterForm from "../../PreAuth/components/request/EncounterForm";
import EmrSupportingINfo from "./EmrSupportingINfo";
import { Switch } from "@mui/material";
import { Vaccines } from "@mui/icons-material";
import AllergyIntolerance from "./AllergyIntolerance";
import {
  CreateNewEmr,
  getPractitionerDetail,
  getRcmPatientByID,
} from "./ApiMethods";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EmrPreAuthModel from "./EmrPreAuthModel";
import { useMutation } from "@apollo/client";
import preAuth from "../../../gql/preAuth";
import SupportingInfoVitalSigns from "./SupportingInfoVitalSigns";

export default function EmrForm() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const navigate = useNavigate();
  const currentDate = new Date();
  const location = useLocation();
  const patient = location?.state?.appointment;
  const token = JSON.parse(localStorage.getItem("user")).jwt;
  const appointmentId = location?.state?.appointmentId;
  const [Loading, setLoading] = useState(false);
  const [detail, setDetail] = useState("");
  const [rcmDetail, setRcmDetail] = useState(null);
  useEffect(() => {
    getRcmPatientByID(patient?.patient?.rcm_ref).then((res) => {
      setRcmDetail(res?.data);
    });
    if (token) {
      getPractitionerDetail(setLoading, setDetail, token);
    }
  }, []);
  const formikRef = useRef(null);
  const formInitialValues = {
    patientID: patient?.patient?.patient_id,
    payerId: "",
    dateOrdered: currentDate.toISOString().substring(0, 10),
    claimType: "",
    careTeamsArr: [
      {
        practitioner: detail ? detail?.data?.name : "",
        practitionerName: detail ? detail?.data?.name : "",
        practitioner_role: detail ? detail?.data?.role : "",
        care_team_role: "",
        qualification: detail ? detail?.data?.speciality : "",
      },
    ],
    diagnosisArr: [],
    visit_id: patient?.visit_id,
    supportingInfos: [
      {
        category: "chief-complaint",
        codeText: "",
        code: "",
        valueUnit: "",
        value: "",
      },
    ],
    vitalSigns: [
      {
        category: "vital-sign-systolic",
        startDate: "",
        endDate: "",
        value: "",
        valueUnit: "mm[Hg]",
      },
      {
        category: "vital-sign-diastolic",
        startDate: "",
        endDate: "",
        value: "",
        valueUnit: "mm[Hg]",
      },
      {
        category: "vital-sign-height",
        startDate: "",
        endDate: "",
        value: "",
        valueUnit: "cm",
      },
      {
        category: "vital-sign-weight",
        startDate: "",
        endDate: "",
        value: "",
        valueUnit: "kg",
      },
      {
        category: "temperature",
        startDate: "",
        endDate: "",
        value: "",
        valueUnit: "Cel",
      },
      {
        category: "respiratory-rate",
        startDate: "",
        endDate: "",
        value: "",
        valueUnit: "/min",
      },
      {
        category: "pulse",
        startDate: "",
        endDate: "",
        value: "",
        valueUnit: "/min",
      },
    ],
    items: [],
    encounterStatus: null,
    immunization: {
      status: "",
      occurrenceDateTime: "",
      vaccine_code: "",
    },
    isImmunization: false,
    allergyIntolerance: [],
  };
  const statusValues = [
    {
      id: "compelete",
      value: "compeleted",
    },
    {
      id: "pending",
      value: "pending",
    },
  ];
  const [open, setOpen] = useState(false);
  const preAuthValidationSchema = Yup.object().shape({
    careTeamsArr: Yup.array().min(1, "Atleast One is reduired"),
    diagnosisArr: Yup.array().min(1, "Atleast One is reduired"),
    items: Yup.array().min(1, "Atleast One is reduired"),
    encounterStatus: Yup.string().nullable(),
    vitalSigns: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Category is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date cannot be before start date"),
        value: Yup.number()
          .required("Value is required")
          .positive("Value must be a positive number"),
        valueUnit: Yup.string().required("Value unit is required"),
      })
    ),
    is_accident: Yup.boolean().required("Accident is required"),
    accidentType: Yup.string().required("Accident Type is required"),
    street: Yup.string().required("Street name is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    date: Yup.date().required("Date is required"),
    // immunization: Yup.object().shape({
    //   status: Yup.string().required("Immunization status is required"),
    //   occurrenceDateTime: Yup.date()
    //     .required("Occurrence DateTime is required")
    //     .nullable(),
    //   vaccine_code: Yup.string().required("Vaccine Code is required"),
    // }),
    isImmunization: Yup.boolean(),
    // allergyIntolerance: Yup.array().min(1, "Atleast One is reduired"),
  });

  function convertClinicalData(inputData) {
    return inputData.map((item) => {
      const baseObject = {
        category: item.category,
        code: item.code || null,
        code_text: item.codeText || null,
        start_date: item.startDate || null,
        end_date: item.endDate || null,
        value: item.value || null,
        unit: item.valueUnit || null,
        reason: item.reason || null,
        image_content_type: item.imageContentType || null,
        image_title: item.imageTitle || null,
        image_creation_date: item.imageCreationDate || null,
        image_value: item.imageValue || null,
      };

      if (item.category.startsWith("vital-sign-")) {
        baseObject.code = null;
        baseObject.code_text = null;
      }

      if (item.image) {
        baseObject.image_content_type = item.image.contentType || null;
        baseObject.image_title = item.image.title || null;
        baseObject.image_creation_date = item.image.creationDate || null;
        baseObject.image_value = item.image.value || null;
      }

      return baseObject;
    });
  }
  const [requestPreAuth, { data, loading, error }] = useMutation(
    preAuth.REQUEST_AUTHORIZATION,
    {
      onCompleted(result) {
        toast.success(result?.preAuthRequest?.message);
        if (result?.preAuthRequest?.message === "data sent to payer") {
          CreateNewEmr(setLoading, formikRef?.current?.values).then(() => {
            navigate("/his/patient");
          });
        }
        if (result?.preAuthRequest?.nphies_err) {
          result?.preAuthRequest?.nphies_err.map((err) => {
            toast?.error(err);
          });
        }
        setOpen(false);
      },
      onError(err) {
        console.error("Pre-Authorization Request Error:", err);
      },
    }
  );
  const requestPreAuthApi = (values) => {
    const updateddiagnosis = values?.diagnosisArr?.map((des) => ({
      type: des?.type,
      on_admission: des?.on_admission === "false" ? false : true,
      code: des?.codeId,
      description: des?.codeDescription,
    }));
    const UpdatedItems = values?.items.map((item) => ({
      type: item.type,
      standard_code: item.standardCode,
      standard_description: item.standardCodeDescription,
      non_standard_code: item.non_standard_code,
      non_standard_description: item.non_standard_description,
      medication_selection_reason: item.medicationSelectionReason,
      prescribed_medication: Number(item.prescribedMedication),
      body_site: item.body_site || null,
      sub_site: item.sub_site || "",
      service_start_date: item.service_start_date,
      service_end_date: item.service_end_date || "",
      supporting_info: item.supporting_info || [],
      diagnosis: item.diagnosis,
      care_teams: item.care_teams,
      is_package:
        item.is_package === "false" ? false : Boolean(item.is_package),
      quantity: item.quantity,
      code: item.standardCode,
      unit_price: item.unit_price,
      tax_amount: item.tax_amount,
      factor: item.factor,
      net: String(item.net),
      discount_amount: item.discount_amount,
      patient_share: item.patient_share,
      patient_share_amount: item.patientShareAmount,
      payer_share: Number(item.payer_share),
    }));
    const mappedValues = {
      is_edit_request: false,
      ref_transaction_id: null,
      is_followup_req: false,
      prv_transaction_id: "",
      patient_id: rcmDetail?.id,
      insurance_plan_id: rcmDetail?.insurance_plans[0]?.id,
      is_new_born: false,
      is_referral: false,
      date_ordered: currentDate.toISOString().substring(0, 10),
      priority: "stat",
      type: "professional",
      subtype: "op",
      offline_verification_id: null,
      offline_verification_date: null,
      verification_resp_ident_value: null,
      verification_resp_ident_url: null,
      physicians_id: [detail?.data?.id],
      diagnosis: updateddiagnosis,
      supporting_info: convertClinicalData(values?.supportingInfos),
      is_accident: values?.is_accident,
      accident_info: {
        street_name: "",
        city: "",
        state: "",
        country: "",
        date: "",
      },
      items: UpdatedItems,
      vision_prescription: null,
      is_vision_prescription: false,
      encounter: null,
    };

    requestPreAuth({
      variables: {
        input: mappedValues,
      },
    });
  };
  console.log(formikRef?.current?.values);
  console.log(formikRef?.current?.errors);

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      innerRef={formikRef}
      onSubmit={(values, { setSubmitting }) => {
        if (!patient?.patient?.patient_id) {
          toast.error("Please select a patient");
          return;
        }
        const updatedVital = values?.vitalSigns.map((vt) => ({
          category: vt?.category,
          startDate: vt?.startDate,
          endDate: vt?.endDate,
          value: String(vt?.value),
          valueUnit: vt?.valueUnit,
        }));
        CreateNewEmr(setLoading, {
          patientID: patient?.patient?.patient_id,
          payerId: values?.payerId,
          dateOrdered: values?.dateOrdered,
          claimType: values?.claimType,
          careTeamsArr: values?.careTeamsArr,
          diagnosisArr: values?.diagnosisArr,
          visit_id: values?.visit_id,
          supportingInfos: [...values?.supportingInfos, ...updatedVital],
          items: values?.items,
          encounterStatus: values?.encounterStatus,
          immunization: values?.immunization,
          isImmunization: values?.isImmunization,
          allergyIntolerance: values?.allergyIntolerance,
        }).then(() => {
          navigate("/his/patient");
        });
        setSubmitting(false);
      }}
      validationSchema={preAuthValidationSchema}
    >
      {({
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        values,
        errors,
      }) => (
        <>
          <div className="pre_auth_req_page">
            <div className="pre_auth_details_top !p-3 !mb-3 flex w-full items-center flex-row">
              <div className="flex w-[30%] gap-3 items-center">
                <div
                  className="back"
                  onClick={() => {
                    navigate("/his/patient");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                      fill="#4D4D53"
                    />
                  </svg>
                </div>
                <div>
                  <div className="pre_auth_details_title">
                    <span>Electronic Medical Record</span>
                  </div>
                  <div className="pre_auth_details_content">
                    <span>EMR Form</span>
                  </div>
                </div>
              </div>
              <div className="w-[70%]">
                <div className="flex flex-col w-full gap-3 mt-3">
                  <div className="flex flex-row  justify-between items-center ">
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Full Name :
                      </span>
                      <span>{patient?.patient?.full_name || "N/A"}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Document ID :
                      </span>
                      <span> {patient?.patient?.document_id || "N/A"}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        City :
                      </span>
                      <span> {patient?.patient?.city || "N/A"}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Insurance Plan :
                      </span>
                      <span>
                        {patient?.patient?.insurance_plans?.length > 0
                          ? patient?.patient?.insurance_plans.map(
                              (plan, index) => (
                                <span key={index}>
                                  {plan.plan_name || "Unnamed Plan"}
                                  {index <
                                  patient?.patient?.insurance_plans.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              )
                            )
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row  justify-between items-center  ">
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        File No :
                      </span>
                      <span>{patient?.patient?.file_no || "N/A"}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Date Of Birth :
                      </span>
                      <span> {patient?.patient?.dob || "N/A"}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Doctor Name :
                      </span>
                      <span> {detail?.data?.name || "N/A"}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Doctor Speciality :
                      </span>
                      <span> {detail?.data?.speciality || "N/A"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="!grid !grid-cols-1 lg:!grid-cols-2 gap-2">
              {/* <CareTeam data={detail} /> */}
              <Diagnosis />
              <AllergyIntolerance />
            </div>

            <EmrSupportingINfo />
            <SupportingInfoVitalSigns />

            <ItemsList emr={true} patient={patient?.patient} />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              <IsAccident />
              <EncounterForm />
            </div>
            <div className=" shadow-md w-[50%] p-3 rounded-lg border border-gray-400 mb-3">
              {console.log(values)}

              <div class="flex gap-2 !mb-3">
                <div class="inventory-svg">
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
                    <path
                      d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                      fill="#F5793B"
                    ></path>
                    <path
                      d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                      fill="#F5793B"
                    ></path>
                  </svg>
                </div>
                <div class="personal-head">
                  <span>Immunization</span>
                  <div className="flex space-x-3 md:mt-0 md:ml-4 mr-2">
                    <Switch
                      checked={values.isImmunization}
                      onChange={() =>
                        setFieldValue("isImmunization", !values.isImmunization)
                      }
                      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2"
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                      />
                      <span
                        aria-hidden="true"
                        className={classNames(
                          values.isImmunization
                            ? "!bg-primary"
                            : "!bg-gray-200",
                          "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                        )}
                      />
                      <span
                        aria-hidden="true"
                        className={classNames(
                          values.isImmunization
                            ? "translate-x-5"
                            : "translate-x-0",
                          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              {values?.isImmunization && (
                <div className="grid grid-cols-2 gap-3">
                  <input
                    className="w-full rounded-md border-none"
                    type="datetime-local"
                    name="immunization.occurrenceDateTime"
                    value={values.immunization.occurrenceDateTime}
                    onChange={handleChange}
                    id="date"
                    placeholder="Date"
                  />
                  <input
                    className="w-full rounded-md border-none"
                    name="immunization.vaccine_code"
                    value={values.immunization.vaccine_code}
                    onChange={handleChange}
                    id="vaccine_code"
                    placeholder="Vaccine Code"
                  />
                  <select
                    className="w-full rounded-md border-none"
                    name="immunization.status"
                    onChange={handleChange}
                    value={values.immunization.status}
                    id="status"
                  >
                    <option value="">Status</option>
                    {statusValues.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div>
              <div className="flex justify-center gap-2">
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                  }}
                  onClick={handleSubmit}
                  startIcon={<CheckOutlinedIcon />}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
                {patient?.patient?.insurance_plans?.length > 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    className={` disabled:!text-white `}
                    onClick={() => requestPreAuthApi(values)}
                    startIcon={<DoneAllIcon />}
                  >
                    Request Pre Auth and Save Emr
                  </Button>
                )}
              </div>
            </div>
          </div>
          <AppLoader
            setShowLoader={setLoading}
            showLoader={Loading || loading}
          />
          {/* <EmrPreAuthModel
            open={open}
            setOpen={setOpen}
            values={values}
            rcmDetail={rcmDetail}
            Detail={detail}
          /> */}
        </>
      )}
    </Formik>
  );
}
