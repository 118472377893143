import React, { useState } from "react";
import CashTable from "./CashTable";
import InsuranceTable from "./InsuranceTable";

function InvoiceMain() {
  const [activeTab, setActiveTab] = useState("cash");

  const tabList = [
    {
      label: "Cash Invoice",
      comapre: "cash",
      command: () => setActiveTab("cash"),
    },
    {
      label: "Insurance Invoice",
      comapre: "insurance",
      command: () => setActiveTab("insurance"),
    },
  ];
  return (
    <>
      <div>
        <ul className="grid grid-flow-col mb-2 text-center border rounded-md text-gray-500 p-1">
          {tabList.map((tab) => (
            <li key={tab.comapre}>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === tab?.comapre
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => tab?.command()}
              >
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
        <div>
          {activeTab === "cash" && <CashTable />}
          {activeTab === "insurance" && <InsuranceTable />}
        </div>
      </div>
    </>
  );
}

export default InvoiceMain;
