//local server
//export const HIS_BACKEND_URL = "http://localhost:8080/api/v1";
// export const ERP_BACKEND_URL = "http://localhost:8081/api/v1";

//liver server
// export const HIS_BACKEND_URL =
//   "https://elegant-notably-mole.ngrok-free.app/api/v1";
// export const ERP_BACKEND_URL =
//   "https://fairly-notable-koala.ngrok-free.app/api/v1";

// //testing server
 export const HIS_BACKEND_URL ="https://api-his.super-git.com/api/v1";
 export const ERP_BACKEND_URL ="https://api-erp.super-git.com/api/v1";
