import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../../../../UcafForm/styles.css";
import myImage from "../../../../UcafForm/target001.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "90%",
    maxHeight: "90%",
    minWidth: "90%",
  },
};

function UCAFdata({ item }) {
  const headerOne = [
    { id: 1, name: "Code" },
    { id: 2, name: "Non-Standard Code" },
    { id: 3, name: "Description/Service" },
    { id: 4, name: "Type" },
    { id: 5, name: "Quality" },
    { id: 6, name: "Approved Quantity" },
    { id: 7, name: "Cost" },
    { id: 8, name: "Approved Cost" },
    { id: 8, name: "Status" },
  ];
  const headerthree = [
    { id: 1, name: "Code" },
    { id: 2, name: "Reject Reason Code" },
    { id: 3, name: "Reject Reason Description" },
  ];
  const headerTwo = [
    { id: 1, name: "Medication Name (Generic Name)" },
    { id: 2, name: "Type" },
    { id: 3, name: "Quantity" },
  ];
  const headerTwoData = [
    { id: 1, name: "Medication Name (Generic Name)" },
    { id: 2, name: "Type" },
    { id: 3, name: "Quantity" },
  ];
  return (
    <div className="">
      <h3 className="text-2xl text-center font-bold">UCAF 2.0</h3>
      <div className="grid grid-cols-2 gap-2 my-4">
        {/* md:col-start-1  md:col-end-7 col-span-12 */}
        <div className=" border-2 border-black py-3 px-2 rounded">
          <h3 className="text- font-black">
            <b>To be completed &amp; ID verified by the reception/nurse:</b>
          </h3>
          <div className="data py-2">
            <div className="flex flex-col gap-2">
              <div className="one flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">Provider Name :</p>

                <p className="text-gray-800  font-mendium border-b-2 border-dotted border-black">
                  {item?.payer_name}
                </p>
              </div>
              <div className="two flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">
                  Insurance Company Name :
                </p>

                <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                  {item?.payer_name}
                  {/* <sup className="text-red-900 text-xl">*</sup> */}
                </p>
              </div>
              <div className="three flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">
                  TPA Company Name :
                </p>

                <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                  {item?.tpa_name}
                </p>
              </div>
              <div className="four flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">Patient File No :</p>

                <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                  {item?.patient_document_id}
                </p>
              </div>
              <div className="five flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">
                  Single () Married () :
                </p>
                <div className="flex gap-2">
                  <p>Plan Type</p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.insurance_plan?.coverage_type}
                  </p>
                </div>
              </div>

              <div className="six flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">Date Of Visit :</p>

                <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                  No
                  {/* {item?.value} */}
                </p>
              </div>
              <div className="seven flex gap-4 items-center ">
                <p className="text-gray-800 ">New Visit()</p>
                <p className="text-gray-800"> FollowUp() </p>
                <p className="text-gray-800"> Refell() </p>
                <p className="text-gray-800"> WalkIn() </p>
              </div>

              <div className="eight flex gap-2 items-center ">
                <div className="flex gap-3 items-center">
                  <p className="text-gray-800 font-semibold">Referrel :</p>

                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item && item.is_referral ? "YES" : "NO"}
                    <sup className="text-red-900 text-xl"></sup>
                  </p>
                </div>
                <div className="flex gap-3 items-center">
                  <p className="text-gray-800 font-semibold">Refer To:</p>

                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.disposition}
                    <sup className="text-red-900 text-xl"></sup>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* md:col-start-1  md:col-end-7 col-span-12 */}
        <div className=" border-2 border-black py-3 px-2 rounded-md">
          <h3 className="text- font-black">
            <b>Print &amp; fill in clear letters or Emboss Card:</b>
          </h3>
          <div className="data py-2">
            <div className="flex flex-col gap-2">
              <div className="one flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">Insured Name :</p>

                <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black capitalize">
                  {item?.patient?.full_name}
                </p>
              </div>

              <div className="two flex gap-2 items-center justify-between">
                <div className="flex gap-2 ">
                  <p className="text-gray-800 font-semibold">
                    ID Card Number :
                  </p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.insurance_plan?.member_card_id}
                  </p>
                </div>
              </div>

              <div className="two flex gap-2 items-center justify-between">
                <div className="flex gap-2">
                  <p className="text-gray-800 font-semibold">Sex :</p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black capitalize">
                    {item?.patient?.gender}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-gray-800 font-semibold">Date of Birth :</p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.patient?.dob}
                  </p>
                </div>
              </div>

              <div className="three flex gap-2 items-center justify-between">
                <div className="flex gap-2">
                  <p className="text-gray-800 font-semibold">Policy Holder :</p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    YES
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className="text-gray-800 font-semibold">Policy Number :</p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.insurance_plan?.policy_number}
                  </p>
                </div>
              </div>
              <div className="four flex gap-2 items-center justify-between">
                <div className="flex gap-2">
                  <p className="text-gray-800 font-semibold">
                    Date Of Expiry :
                  </p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.insurance_plan?.expiry_date}
                  </p>
                </div>
                <div className="flex  gap-2">
                  <p className="text-gray-800 font-semibold">Class :</p>
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.className}
                  </p>
                </div>
              </div>
              <div className="five flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">Approval :</p>
                <div className="flex gap-3">
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {item?.pre_auth_ref_no}
                  </p>
                </div>
              </div>
              <div className="five flex gap-2 items-center ">
                <p className="text-gray-800 font-semibold">Approval Status :</p>
                <div className="flex gap-3">
                  <p className=" text-gray-800  font-mendium border-b-2 border-dotted border-black">
                    {/* {data?.getPreAuthTransactionDetails.Data.} */}
                    {item?.status}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="second-Row border-2 border-black py-3 px-2 rounded-md ">
        <h3 className="text- font-black">
          <b>To be completed by Attending PHYSICIAN : Please tick ()</b>
        </h3>
        <div>
          <div className="data-two py-1">
            <div className="sl flex justify-between items-center ">
              <div className="flex gap-4">
                <p className="text-gray-800 font-semibold"> Inpatient ()</p>
                <p className="text-gray-800 font-semibold"> Outpatient ()</p>
              </div>
              <div className="border-2 border-black rounded-md flex items-center p-3 gap-4">
                <p className="text-gray-800 font-semibold"> EmergencyCase ()</p>
                <div className="flex items-center">
                  <p className="text-gray-800 font-semibold">
                    {" "}
                    EmergencyCareLavel :
                  </p>
                  <div className="inner flex items-center gap-3">
                    <p className="text-gray-800 font-semibold"> 1 ( )</p>
                    <p className="text-gray-800 font-semibold"> 2 ( )</p>
                    <p className="text-gray-800 font-semibold"> 3 ( )</p>
                    <p className="text-gray-800 font-semibold"> 4 ( )</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="data-two py-2">
            <div className="flex gap-3 items-center">
              <div className="flex items-center gap-2">
                <p className="text-gray-800 font-semibold"> BP / :</p>
                <p className="border-b-2 border-black border-dotted">
                  {"    "}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-gray-800 font-semibold"> Pulse / :</p>
                <p className="border-b-2 border-black border-dotted">
                  {"    "}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-gray-800 font-semibold"> Temp / :</p>
                <p className="border-b-2 border-black border-dotted">
                  {"    "}
                  <sup>c</sup>
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-gray-800 font-semibold"> Weight / :</p>
                <p className="border-b-2 border-black border-dotted">
                  {"    "}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-gray-800 font-semibold"> Height / :</p>
                <p className="border-b-2 border-black border-dotted">
                  {"    "}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-gray-800 font-semibold"> R.R / :</p>
                <p className="border-b-2 border-black border-dotted">
                  {"      "}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-gray-800 font-semibold">
                  {" "}
                  Duration Of Line / :
                </p>
                <p className="border-b-2 border-black border-dotted">
                  {"      "}
                </p>
              </div>
            </div>
          </div>
          <div className="data-three py-2">
            <div className="flex gap-2">
              <p className="text-gray-800 font-semibold">
                Cheif Complaints and Main Symptoms :
              </p>

              <p className="text-gray-800 font-semibold">
                {item?.supporting_info
                  ?.filter((support) => support.category === "chief-complaint")
                  ?.map((support) => (
                    <p
                      key={support.sequence_no}
                      className="text-gray-800 font-semibold"
                    >
                      {support.code} {support.code_text} {support.value}
                    </p>
                  ))}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <div className="flex gap-4  ">
              <p className="text-gray-800 font-semibold ">
                {" "}
                Significiant Signs :
              </p>
              <div className="flex-1 border-b-2 border-black border-dotted">
                <p className="text-gray-800  font-semibold "> </p>
              </div>
            </div>
            <div className="flex gap-4  ">
              <p className="text-gray-800 font-semibold ">
                {" "}
                Other Conditions :
              </p>
              <div className="flex-1 border-b-2 border-black border-dotted">
                <p className="text-gray-800  font-semibold "> </p>
              </div>
            </div>
            <div className="flex gap-4  ">
              <p className="text-gray-800 font-semibold "> Diagnosis :</p>
              <div className="flex-1 border-b-2 border-black border-dotted">
                <p className="text-gray-800  font-semibold ">
                  {item?.diagnosis
                    .map((diag) => {
                      return `${diag.code} - ${diag.description}`;
                    })
                    .join(", ")}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex gap-4  flex-1">
                <p className="text-gray-800 font-semibold ">
                  {" "}
                  Principal Code :
                </p>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800  font-semibold ">
                    {item?.diagnosis
                      .map((diag) => {
                        return `${diag.code}`;
                      })
                      .join(", ")}
                  </p>
                </div>
              </div>
              <div className="flex gap-4  flex-1">
                <p className="text-gray-800 font-semibold "> 2nd Code :</p>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800  font-semibold "> </p>
                </div>
              </div>
              <div className="flex gap-4  flex-1">
                <p className="text-gray-800 font-semibold "> 3th Code :</p>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800  font-semibold "> </p>
                </div>
              </div>
              <div className="flex gap-4  flex-1">
                <p className="text-gray-800 font-semibold "> 4th Code :</p>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800  font-semibold "> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-2 my-3 border-black ">
          <hr className="border-b-2 boder-black" />
        </div>

        <div className="third-data">
          <h3 className="text- font-black">
            <b> Please tick () Where appropriate</b>
          </h3>

          <div className="flex flex-col py-2 gap-3">
            <div className="one flex gap-5 items-center">
              <p className="text-gray-800 font-semibold">Chronic ()</p>
              <p className="text-gray-800 font-semibold">Congenital ()</p>
              <p className="text-gray-800 font-semibold">Work Related ()</p>
              <p className="text-gray-800 font-semibold">Vaccination ()</p>
              <p className="text-gray-800 font-semibold">Check-Up ()</p>
            </div>
            <div className="two flex justify-between items-center">
              <div className="one flex gap-5 items-center">
                <p className="text-gray-800 font-semibold">Psychiatric ()</p>
                <p className="text-gray-800 font-semibold">Infertility ()</p>
                <p className="text-gray-800 font-semibold">Pregnancy ()</p>
              </div>
              <div className="flex items-center gap-2">
                <p>Indicate LMP :</p>
                <p className="border-b-2 border-dotted">334</p>
              </div>
            </div>
            <p>
              Suggestive lines of Managment : Kindlay enumerate the recommended
              investigations , and /or procedures{" "}
              <b className="font-black mx-2">For Outpatient approvals Only</b>
            </p>
            <table className="border- border-black table-hover">
              <thead className="border-2 border-black">
                <tr>
                  {headerOne.map((item, index) => {
                    return (
                      <th className="py text-center" key={index}>
                        {item.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="border-2 border-black">
                {item?.items?.map((item, index) => {
                  return (
                    <tr>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.standard_code}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.non_standard_code}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        <div>{item?.standard_description}</div>
                        {item?.reject_reason_display ? (
                          <div className="text-sm text-red-500 mt-1">
                            <span className="font-semibold">
                              Reject Reason:{" "}
                            </span>
                            {item?.reject_reason_display}
                          </div>
                        ) : null}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.type}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.quantity}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.approved_quantity}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.net}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.approved_value}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <p className="text-gray-800 font-semibold">
              Providing approvals /Coding Stff must review /Code the recommended
              Service and collect cose and complete the follwing :
            </p>
            <div className="flex items-center gap-3">
              <div className="flex gap-4  flex-1">
                <p className="text-gray-800 font-semibold ">
                  {" "}
                  Completed / Coded By :
                </p>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800  font-semibold "> </p>
                </div>
              </div>
              <div className="flex gap-4  flex-1">
                <p className="text-gray-800 font-semibold "> Signature :</p>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800  font-semibold "> </p>
                </div>
              </div>
              <div className="flex gap-4  flex-1">
                <p className="text-gray-800 font-semibold "> Date :</p>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800  font-semibold "> </p>
                </div>
              </div>
            </div>

            <table className="border- border-black table-hover">
              <thead className="border-2 border-black">
                <tr>
                  {headerTwo.map((item, index) => {
                    return (
                      <th className="py text-center" key={index}>
                        {item.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="border-2 border-black">
                {item?.items?.map((item, index) => {
                  return (
                    <tr>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {`(${item?.standard_code}) - ${item?.standard_description}`}
                      </td>

                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.type}
                      </td>
                      <td
                        className="text-center py-2
          border-1 border-r-2 border-r-black"
                      >
                        {item?.quantity}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex gap-5 items-center">
              <p className="text-gray-700">
                In Case management form (CMF 1.0) included
              </p>
              <b className="text-black">Yes () No()</b>
            </div>
            <div className="flex gap-5 items-center">
              <div className="">
                <p className="text-gray-700">
                  Please specify Possible line of management when applicable :
                </p>
              </div>
              <div className="flex-1 border-b-2 border-black border-dotted">
                <p className="text-gray-800"></p>
              </div>
            </div>
            <div className="flex gap-5 items-center">
              <div className="flex-1 flex items-center gap-3">
                <div className="">
                  <p className="text-gray-800 font-black">
                    Estimated length of Stay :
                  </p>
                </div>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800"></p>
                </div>
              </div>
              <div className="flex-1 flex items-center gap-3">
                <div className="">
                  <p className="text-gray-800 font-black">
                    Estimated length of Stay :
                  </p>
                </div>
                <div className="flex-1 border-b-2 border-black border-dotted">
                  <p className="text-gray-800"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* THIRD ROW */}
      <div className="second-Row border-2 border-black py-3 px-2 rounded-md mt-2">
        <div className="flex gap-5">
          <div
            className="flex-1 border-r-2 border-gray
  p-4"
          >
            <p className="text-gray-700 font-semibold">
              I hereby certify that ALL information mentioned are correct and
              that the medical services shown on this form were medically
              indicated and necessary for the management of this case.
            </p>
            <div className="flex mt-3 justify-between">
              <div className="flex-col gap-4 flex ">
                <p className="text-gray-700 font-black">Physicians</p>
                <div className="border-b-2 flex-1 border-black border-dotted">
                  {item?.care_teams
                    .map((care) => {
                      return `${care.name}`;
                    })
                    .join(", ")}
                </div>
              </div>
              <div className="flex-col gap-4 flex">
                <p className="text-gray-700 font-black">Signature</p>
                <div className="border-b-2 flex-1 border-black border-dotted"></div>
              </div>
              <div className="flex-col gap-4 flex">
                <p className="text-gray-700 font-black">Stamp</p>
                <div className=" ml-4 ">
                  <input type="radio" />
                </div>
              </div>
              <div className="flex-col gap-4 flex">
                <p className="text-gray-700 font-black">Date</p>
                <div className="border-b-2 flex-1 border-black border-dotted"></div>
              </div>
            </div>
          </div>
          <div
            className="flex-1
  p-4"
          >
            <p className="text-gray-700 font-semibold ">
              I hereby certify that ALL statements and information provided
              concerning patient identification and the present illness or
              injury are TRUE.
            </p>
            <div className="mt-4">
              <div className="flex gap-4">
                <p className="text-gray-800 font-black flex-2">
                  Name (and relationship (if guardian)):
                </p>
                <div className="border-b-2 border-black border-dotted flex-1"></div>
              </div>
              <div className="flex mt-4">
                <div className="flex gap-4 flex-1">
                  <p className="text-gray-800 font-black flex-2">Signature:</p>
                  <div className="border-b-2 border-black border-dotted flex-1"></div>
                </div>
                <div className="flex gap-4 flex-1">
                  <p className="text-gray-800 font-black flex-2">Date:</p>
                  <div className="border-b-2 border-black border-dotted flex-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FOURTH ROW */}
      <div className="second-Row border-2 border-black p-4 rounded-md mt-2">
        <p className="text-gray-700 font-semibold">
          For Insurance Company Use Only: Approved ( ) Not Approved ( ) Approval
          No.: Approval validity: 03/05/2023 - 08/05/2023 -432000000 Days
          Comments (include approved days/services if different from the
          requested):
        </p>

        <hr className="border-b-4 mt-3 border-black font-bold" />

        <div className="flex mt-4">
          <div className="one flex gap-3 flex-1">
            <p className="text-gray-700  font-black">
              Approved/Disapproved By:
            </p>
            <div className="border-b-2 border-black border-dotted flex-1"></div>
          </div>
          <div className="two flex gap-3 flex-1">
            <p className="text-gray-700  font-black">Signature:</p>
            <div className="border-b-2 border-black border-dotted flex-1"></div>
          </div>
          <div className="two flex gap-3 flex-1">
            <p className="text-gray-700  font-black">Date :</p>
            <div className="border-b-2 border-black border-dotted flex-1"></div>
          </div>
        </div>
      </div>
      {/*LAST ROW  */}
      <p
        className="text-gray-900
font-black
py-1
"
      >
        (*) This is applicable only in case of manual UCAF
      </p>
    </div>
  );
}

function UcafForm({ showUCAF, setShowUCAF, item }) {
  const handlePrint = () => {
    setShowUCAF(false);
    const printContent = document.getElementById("print-content");
    const originalContents = document.body.innerHTML;

    const elements = printContent.querySelectorAll("*");
    elements.forEach((element) => {
      element.style.fontSize = "10px"; // Change the font size to your desired value
    });

    // Temporarily replace the body content with the modified print content
    document.body.innerHTML = printContent.innerHTML;

    // Print the modified page
    window.print();

    // Restore the original body content
    setTimeout(() => {
      document.body.innerHTML = originalContents;
    }, 100);
  };

  // const [originalContents, setOriginalContents] = useState(
  //   document.body.innerHTML
  // );
  // const x1handlePrint = () => {
  //   const printContent = document.getElementById("print-content");

  //   // Temporarily replace the body content with the print content
  //   document.body.innerHTML = printContent.innerHTML;

  //   // Print the modified page
  //   window.print();
  // };

  // useEffect(() => {
  //   const restoreOriginalContent = () => {
  //     document.body.innerHTML = originalContents;
  //   };

  //   // Restore the original body content after printing is finished
  //   window.onafterprint = restoreOriginalContent;

  //   return () => {
  //     // Clean up the event listener
  //     window.onafterprint = null;
  //   };
  // }, []); // Run this effect only once

  return (
    <Modal
      // isOpen={true}
      isOpen={showUCAF}
      onRequestClose={() => setShowUCAF(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div id="print-content">
        <UCAFdata item={item} />
      </div>
      <div className="sticky bottom-0 p-4 pt-3">
        <div className="flex justify-end">
          <button
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => setShowUCAF(!showUCAF)}
          >
            Close
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => handlePrint()}
          >
            Print
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default UcafForm;
