import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState, useCallback } from "react";
import {
  getAppoinmentsByID,
  getMedicalByID,
  getPatientList,
  SearchPatient, // Assuming this is the function for searching patients
} from "./components/ApiMethods";
import { Avatar } from "@mui/material";
import { FemaleOutlined, MaleOutlined } from "@mui/icons-material";
import PatientAppoinmentHistory from "./components/PatientAppoinmentHistory";
import PatientMedicalHistory from "./components/PatientMedicalHistory";
import AppLoader from "../../components/AppLoader";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import toast from "react-hot-toast";

export default function Patient() {
  const [searchText, setSearchText] = useState("");
  const [Loading, setloading] = useState(false);
  const [patientList, setPatientList] = useState(null);
  const [activeTab, setActiveTab] = useState("appointment");
  const [open, setOpen] = useState(false);
  const [patientID, setPatientID] = useState(null);
  const [patientMedicalHistory, setPatientMedicalHistory] = useState(null);
  const [appointmentslist, setAppointmentList] = useState(null);
  const practitioner_id = localStorage.getItem("practitioner_id");
  const user = localStorage?.getItem("user");
  const businessId = JSON.parse(user)?.providers_id;
  const branchId = JSON.parse(user)?.providers_branch_id;

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchPatients = (text) => {
    SearchPatient(setloading, setPatientList, {
      query: text,
    });
  };

  const debouncedFetchPatients = useCallback(debounce(fetchPatients, 500), []);

  useEffect(() => {
    getPatientList(setloading, setPatientList, {
      practitioner_id: practitioner_id || "PRA-0001",
    });
  }, [practitioner_id]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value) {
      debouncedFetchPatients(value);
    } else {
      getPatientList(setloading, setPatientList, {
        practitioner_id: practitioner_id || "PRA-0001",
      });
    }
  };

  const tabList = [
    {
      label: "Visit History",
      comapre: "appointment",
      command: () => setActiveTab("appointment"),
    },
    {
      label: "Medical History",
      comapre: "medical",
      command: () => setActiveTab("medical"),
    },
  ];

  return (
    <>
      <div className="grid grid-cols-6 gap-2">
        <div
          className={`col-span-1 hidden xl:block bg-gray-100 border border-gray-100 p-2 rounded-md h-[75vh] block `}
        >
          <div className="relative rounded-md shadow-sm mb-2">
            <input
              type="text"
              name="search"
              id="search"
              value={searchText}
              onChange={handleSearchChange}
              className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Search"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <ul className="h-[65vh] overflow-y-auto">
              {patientList?.data?.map((patient, index) => (
                <li
                  key={index}
                  className={`p-2 shadow-sm  mb-2 flex rounded-md hover:shadow-2xl cursor-pointer ${
                    patientID === patient.patient_id
                      ? " bg-orange-500 text-white"
                      : "bg-white "
                  }`}
                  onClick={() => {
                    setPatientID(patient.patient_id);
                    getAppoinmentsByID(
                      setloading,
                      {
                        status: "All",
                        patient_id: patient.patient_id,
                        page: 1,
                        perPage: 3,
                      },
                      setAppointmentList
                    );
                    getMedicalByID(
                      setloading,
                      { patientID: patient.patient_id },
                      setPatientMedicalHistory
                    );
                  }}
                >
                  <div className="flex items-center">
                    <Avatar
                      label={patient?.data ? patient?.full_name.charAt(0) : "S"}
                      className="mr-2 text-black w-[2.5rem] h-[2.5rem]"
                      size="large"
                    />
                  </div>
                  <div>
                    <p className="font-bold flex text-sm gap-2 items-center justify-center">
                      {patient?.full_name}
                      <span className="mt-0.5">
                        {patient?.gender === "M" ? (
                          <MaleOutlined />
                        ) : (
                          <FemaleOutlined />
                        )}
                      </span>
                    </p>
                    <p className="text-xs">ID: {patient.patient_id}</p>
                    <p className="text-xs">DID: {patient.document_id}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={` xl:col-span-5 col-span-6 border bg-gray-100 p-2 border-gray-100 rounded-md shadow-inner h-[75vh] ${"col-span-5"}`}
        >
          <ul className="grid grid-flow-col mb-2 text-center border rounded-md text-gray-500 p-1">
            <span
              className="flex justify-center items-center cursor-pointer xl:hidden"
              onClick={() => setOpen(true)}
            >
              <MenuIcon className="w-[35px] h-[35px]" />
            </span>
            {tabList.map((tab) => (
              <li key={tab.comapre}>
                <a
                  className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                    activeTab === tab?.comapre
                      ? "gradient-background rounded shadow text-white"
                      : ""
                  }`}
                  onClick={() => tab?.command()}
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
          <div className="h-[59vh] sm:h-[62vh] overflow-y-auto">
            {activeTab === "appointment" ? (
              <PatientAppoinmentHistory
                data={appointmentslist}
                patientID={patientID}
                setAppointmentList=""
                setAppApiloading={setloading}
                doctor={true}
              />
            ) : activeTab === "medical" ? (
              <PatientMedicalHistory
                patientMedicalHistory={patientMedicalHistory}
                setloading={setloading}
                Loading={Loading}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div className="col-span-1 bg-gray-100 border border-gray-100 p-2  h-[100vh]">
          <div className="relative shadow-sm mb-2">
            <input
              type="text"
              name="search"
              id="search"
              value={searchText}
              onChange={handleSearchChange}
              className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Search"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <ul className="h-[90vh] overflow-y-auto">
              {patientList?.data?.map((patient, index) => (
                <li
                  key={index}
                  className={`p-2 shadow-sm mb-2 flex rounded-md hover:shadow-2xl cursor-pointer ${
                    patientID === patient.patient_id
                      ? " bg-orange-500 text-white"
                      : "bg-white "
                  }`}
                  onClick={() => {
                    setPatientID(patient.patient_id);
                    getAppoinmentsByID(
                      setloading,
                      {
                        status: "All",
                        patient_id: patient.patient_id,
                        page: 1,
                        perPage: 3,
                      },
                      setAppointmentList
                    );
                    getMedicalByID(
                      setloading,
                      { patientID: patient.patient_id },
                      setPatientMedicalHistory
                    );
                    setOpen(false);
                  }}
                >
                  <div className="flex items-center">
                    <Avatar
                      label={patient?.data ? patient?.full_name.charAt(0) : "S"}
                      className="mr-2 text-black w-[2.5rem] h-[2.5rem]"
                      size="large"
                    />
                  </div>
                  <div>
                    <p className="font-bold flex text-sm gap-2 items-center justify-center">
                      {patient?.full_name}
                      <span className="mt-0.5">
                        {patient?.gender === "M" ? (
                          <MaleOutlined />
                        ) : (
                          <FemaleOutlined />
                        )}
                      </span>
                    </p>
                    <p className="text-xs">ID: {patient.patient_id}</p>
                    <p className="text-xs">DID: {patient.document_id}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Drawer>
      {Loading && <AppLoader />}
    </>
  );
}
