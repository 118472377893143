import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import {
  getAppoinmentsByID,
  getAppoinmentsList,
  getMedicalByID,
  getPatientByID,
  getPatientList,
} from "./ApiMethods";
import { Avatar } from "@mui/material";
import { FemaleOutlined, MaleOutlined } from "@mui/icons-material";
import PatientAppoinmentHistory from "./PatientAppoinmentHistory";
import PatientMedicalHistory from "./PatientMedicalHistory";
import AppLoader from "../../../components/AppLoader";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import EmrForm from "./EmrForm";

export default function NewAppointmentMain() {
  const [searchText, setSearchText] = useState("");
  const [Loading, setloading] = useState(false);
  const [patientList, setPatientList] = useState(null);
  const [activeTab, setActiveTab] = useState("appointment");
  const [open, setOpen] = useState(false);
  const [patientID, setPatientID] = useState(null);
  const [patientMedicalHistory, setPatientMedicalHistory] = useState(null);
  const [appointmentslist, setAppointmentList] = useState(null);
  const practitioner_id = localStorage.getItem("practitioner_id");
  const user = localStorage?.getItem("user");
  const businessId = JSON.parse(user)?.providers_id;
  const branchId = JSON.parse(user)?.providers_branch_id;
  useEffect(() => {
    getPatientList(setloading, setPatientList, {
      practitioner_id: practitioner_id || "PRA-0001",
    });
    // getAppoinmentsList(setloading, setAppointmentList, {
    //   status: "All",
    //   page: 1,
    //   perPage: 3,
    // });
  }, []);
  const tabList = [
    {
      label: "Appointment History",
      comapre: "appointment",
      command: () => setActiveTab("appointment"),
    },
    {
      label: "Medical History",
      comapre: "medical",
      command: () => setActiveTab("medical"),
    },

    {
      label: "New EMR",
      comapre: "emr",
      command: () => setActiveTab("emr"),
    },
  ];

  const filteredPatients = patientList?.data?.filter((patient) =>
    patient.full_name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <div className="grid grid-cols-6 gap-2">
        <div
          className={`col-span-1 hidden xl:block bg-gray-100 border border-gray-100 p-2 rounded-md h-[75vh] block `}
        >
          <div className="relative rounded-md shadow-sm mb-2">
            <input
              type="text"
              name="search"
              id="search"
              onChange={(e) => setSearchText(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={"Search "}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <ul className="h-[65vh] overflow-y-auto">
              {filteredPatients?.map((patient, index) => (
                <li
                  key={index}
                  className={`p-2 shadow-sm  mb-2 flex rounded-md hover:shadow-2xl cursor-pointer ${
                    patientID === patient.patient_id
                      ? " bg-orange-500 text-white"
                      : "bg-white "
                  } `}
                  onClick={() => {
                    setPatientID(patient.patient_id);
                    getAppoinmentsByID(
                      setloading,
                      {
                        status: "All",
                        patient_id: patient.patient_id,
                        page: 1,
                        perPage: 3,
                      },
                      setAppointmentList
                    );
                    getMedicalByID(
                      setloading,
                      { patientID: patient.patient_id },
                      setPatientMedicalHistory
                    );
                  }}
                >
                  <div className="flex items-center">
                    <Avatar
                      label={patient?.data ? patient?.full_name.charAt(0) : "S"}
                      className="mr-2 text-black w-[2.5rem] h-[2.5rem]"
                      size="large"
                    />
                  </div>
                  <div>
                    <p className="font-bold flex text-sm gap-2 items-center justify-center">
                      {patient?.full_name}

                      <span className="mt-0.5">
                        {patient?.gender === "male" ? (
                          <MaleOutlined />
                        ) : (
                          <FemaleOutlined />
                        )}
                      </span>
                    </p>

                    <p className="text-xs ">ID: {patient.patient_id}</p>
                    <p className="text-xs ">DID: {patient.document_id}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={` xl:col-span-5 col-span-6 border bg-gray-100 p-2 border-gray-100 rounded-md shadow-inner h-[75vh] ${"col-span-5"}`}
        >
          <div className="h-[59vh] sm:h-[73vh] overflow-y-auto">
            <PatientAppoinmentHistory
              data={appointmentslist}
              patientID={patientID}
              setAppointmentList={setAppointmentList}
              setAppApiloading={setloading}
              app="true"
            />
          </div>
        </div>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div className=" col-span-1 bg-gray-100 border border-gray-100 p-2  h-[100vh]">
          <div className="relative  shadow-sm mb-2">
            <input
              type="text"
              name="search"
              id="search"
              onChange={(e) => setSearchText(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={"Search "}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <ul className="h-[90vh] overflow-y-auto">
              {filteredPatients?.map((patient, index) => (
                <li
                  key={index}
                  className={`p-2 shadow-sm  mb-2 flex rounded-md hover:shadow-2xl cursor-pointer ${
                    patientID === patient.patient_id
                      ? " bg-orange-500 text-white"
                      : "bg-white "
                  } `}
                  onClick={() => {
                    setPatientID(patient.patient_id);
                    // getAppoinmentsByID(
                    //   setloading,
                    //   {
                    //     status: "All",
                    //     patient_id: patient.patient_id,
                    //     page: 1,
                    //     perPage: 3,
                    //   },
                    //   setAppointmentList
                    // );
                    getMedicalByID(
                      setloading,
                      { patientID: patient.patient_id },
                      setPatientMedicalHistory
                    );
                  }}
                >
                  <div className="flex items-center">
                    <Avatar
                      label={
                        patient.patient_name
                          ? patient.patient_name.charAt(0)
                          : "S"
                      }
                      className="mr-2 text-black w-[2.5rem] h-[2.5rem]"
                      size="large"
                    />
                  </div>
                  <div>
                    <p className="font-bold flex text-sm gap-2 items-center justify-center">
                      {patient?.full_name}

                      <span className="mt-0.5">
                        {patient?.gender === "M" ? (
                          <MaleOutlined />
                        ) : (
                          <FemaleOutlined />
                        )}
                      </span>
                    </p>

                    <p className="text-xs text-gray-600">
                      ID: {patient.patient_id}
                    </p>
                    <p className="text-xs text-gray-600">
                      DID: {patient.document_id}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Drawer>
      <AppLoader showLoader={Loading} setShowLoader={setloading} />
    </>
  );
}
