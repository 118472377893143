import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";
import _ from "lodash";

import { FunnelIcon } from "@heroicons/react/20/solid";
import DownloadExcel from "../../components/DownloadExcel";
import gqlClaims from "../../gql/claims";
import gqlPreAuth from "../../gql/preAuth";
import { appContext } from "../../context/ContextProvider";
import Pagination from "../../components/Pagination";
import Filter from "./components/filters/SubmitClaimsFilter";
import AppErrorAlert from "../../components/AppErrorAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
import AppModal from "../../components/AppModal";
import { Button, Tooltip } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { LuTrash2 } from "react-icons/lu";
import { TbEditCircle } from "react-icons/tb";
// import ClaimsResponseModal from "./components/responseModal/ClaimsResponseModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import ClaimReportModal from "./components/ClaimReportModal";
import ClaimsSubmitModal from "./components/ClaimsSubmitModal";

function SubmitClaims(props) {
  const { setShowLoader, setShowErrorAlert } = useContext(appContext);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const currentDate = new Date();

  const [errorData, setErrorData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [submitClaimData, setSubmitClaimData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalSubmits, setTotalSubmits] = useState(0);

  const [selectedValues, setSelectedValues] = useState([]);

  const [item, setItem] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showEligibilityModal, setShowEligibilityModal] = useState(false);
  const [showPreAuthResponseModal, setShowPreAuthResponseModal] =
    useState(false);

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );

  const [limit, setLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);

  const [refetchFilterVariables, setRefetchFilterVariables] = useState();

  // AAaa
  const [submitOpen, setSubmitOpen] = useState(false);
  const handleSubmitOpen = () => {
    setSubmitOpen(true);
  };
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(gqlClaims.GET_PAYER_CLAIMS, {
    variables: {
      input: {
        from_date: fromDate,
        to_date: toDate,
        status: "Ready for submission",
        limit: limit,
        page: pageNo,
      },
    },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.listPayerClaims?.data) {
      setSubmitClaimData(data?.listPayerClaims?.data);
      setTotalPages(data?.listPayerClaims?.pages);
      setTotalSubmits(data?.listPayerClaims?.total);
    }
  }, [data]);

  const [
    submitClaims,
    { data: addData, loading: addLoading, error: addError },
  ] = useMutation(gqlClaims.SUBMIT_CLAIMS, {
    update(proxy, result) {
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    },
  });

  // const { loading, error, data } = useQuery(usersGQL.GET_USER);

  const onSuccessfulRequestResponse = (data) => {
    if (!data.submitClaims.nphies_err) {
      toast.success("Successfully Requested!");
    } else {
      let tempErrors = [
        {
          Code: "DT-01293",
          ValueString: "Bundle.entry[1].resource.supportingInfo[0].timingDate",
          Display:
            "The provided value does not match the specified element data type",
          __typename: "SimpleEligibilityNphiesErr",
        },
      ];
      setErrorData(data?.submitClaims?.nphies_err);
      setShowErrorAlert(true);
    }

    // refetch({
    //   input: {
    //     from_date: fromDate,
    //     to_date: toDate,
    //     status: "Ready for submission",
    //     limit: limit,
    //     page: pageNo,
    //   },
    // });
  };

  const onResponseError = (error) => {
    toast.error(`${error.message}`);
    // refetch({
    //   input: {
    //     from_date: fromDate,
    //     to_date: toDate,
    //     status: "Ready for submission",
    //     limit: limit,
    //     page: pageNo,
    //   },
    // });
  };

  // const {
  //   loading: excelDataLoading,
  //   error: excelDataError,
  //   data: excelDataResponse,
  //   refetch: refetchExcelDataResponse,
  // } = useQuery(gqlPreAuth.GET_PREAUTH_EXCEL_DATA, {
  //   variables: {
  //     input: {
  //       fromDate: currentDate.toISOString().substring(0, 10),
  //       toDate: currentDate.toISOString().substring(0, 10),
  //     },
  //   },
  // });

  // useEffect(() => {
  //   if (excelDataResponse) {
  //     setExcelData(
  //       JSON.parse(
  //         excelDataResponse?.getAllPreAuthTransactionsByFilterInExcel?.Data
  //       )
  //     );
  //   }
  // }, [excelDataResponse]);

  const tableHeaders = [
    { name: "SQ #" },
    { name: "Claim Date" },
    { name: "Type" },
    { name: "Docuemnt Id" },
    // { name: "Claim Transaction #" },
    { name: "Payer Name" },
    { name: "Order Date" },
    { name: "Total Net" },
    {
      name: "Action",
    },
  ];

  const onPageChange = async (currentPage) => {
    setPageNo(currentPage);
    let filterInput = {
      ...refetchFilterVariables,
      limit: limit,
      page: currentPage,
      from_date: fromDate,
      to_date: toDate,
      status: "Ready for submission",
    };

    try {
      setLoading(true);
      await refetch({
        input: filterInput,
      }).then((res) => {
        if (res?.data?.listPayerClaims?.data) {
          setSubmitClaimData(res?.data?.listPayerClaims?.data);
          setTotalPages(res?.data?.listPayerClaims?.pages);
          setTotalSubmits(res?.data?.listPayerClaims?.total);
        }
      });
    } finally {
      setLoading(false);
    }
  };

  function handleCheckboxChange(event, value) {
    // const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      setSelectedValues([...selectedValues, value]);
    } else {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    }
  }

  function handleSelectAll(event) {
    const checked = event.target.checked;

    if (checked && submitClaimData) {
      let temp = _.map(submitClaimData, "id");
      setSelectedValues(temp);
    } else {
      setSelectedValues([]);
    }
  }

  const handleSubmitClaims = () => {
    if (submitClaimData?.length > 0 && selectedValues.length > 0) {
      toast.loading("Requesting!");
      submitClaims({
        variables: {
          // input: replaceEmptyStringWithNull(formatInputData(postData)), //temp
          input: { id: selectedValues },
        },
      });
    } else {
    }
  };

  //when row clicked below
  const onDataRowClicked = (event, item) => {
    navigate("/claims/details", { state: { currentItem: item } });
  };

  //cancel request
  const [
    cancelReq,
    { data: cancelData, loading: cancelLoading, error: cancelError },
  ] = useMutation(gqlPreAuth.CANCEL_REQUEST, {
    update(proxy, result) {
      onSuccessfulCancelRequestResponse(result.data);
    },
    onError(error) {
      onCancelResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulCancelRequestResponse = (data) => {
    if (data.cancelRequest) {
      window.location?.reload();
      toast.success("Successfully Cancelled!");
    } else {
    }
  };

  const onCancelResponseError = (error) => {
    toast.error(`${error.message}`);
    window.location?.reload();
  };

  const cancelClicked = (item) => {
    cancelReq({
      variables: {
        input: {
          transaction_id: item.transaction_id,
          request_name: "claims",
        },
      },
    });
  };
  //end Cancel Request

  const formatDateTime = (time) => {
    // const time = "2023-05-15T09:30:00";
    const dateTime = new Date(time);

    return dateTime.toLocaleString();
  };
  const formik = useFormik({
    initialValues: {
      is_batch: false,
      batch_start_date: "",
      batch_end_date: "",
    },
    validationSchema: Yup.object({
      is_batch: Yup.boolean().required("Is Batch Required"),

      batch_start_date: Yup.string().test(
        "is-batch",
        "Batch Start Date Required",
        function (value) {
          if (this.parent.is_batch) {
            return !!value;
          }
          return true;
        }
      ),

      batch_end_date: Yup.string().test(
        "is-batch",
        "Batch End Date Required",
        function (value) {
          if (this.parent.is_batch) {
            return !!value;
          }
          return true;
        }
      ),
    }),

    onSubmit: async (values) => {
      if (submitClaimData?.length > 0 && selectedValues.length > 0) {
        toast.loading("Requesting!");
        submitClaims({
          variables: {
            // input: replaceEmptyStringWithNull(formatInputData(postData)), //temp
            input: { id: selectedValues, ...values },
          },
        }).then((res) => {
          console.log(res);
        });
      }
    },
  });

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
    if (addError?.graphQLErrors[0]?.message) {
      toast.error(addError?.graphQLErrors[0]?.message);
    }
  }, [error, addError]);

  return (
    <>
      <div className="claimsSubmission_container">
        <div className="claimsSubmission_page">
          <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
            <div className="">
              <Tooltip
                placement="right"
                title="Keep track of your claims submissions"
                className="my-auto !mt-2"
              >
                <span className="font-semibold text-black/70 text-lg ">
                  Submission Claims
                  <span className="italic text-lg ml-1">
                    ({totalSubmits || 0}{" "}
                    <span className="text-xs font-bold italic">Entries</span>)
                  </span>
                </span>
              </Tooltip>
            </div>

            <div class=" flex gap-3 flex-col md:flex-row">
              {submitClaimData?.length > 0 && selectedValues.length > 0 && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setSubmitOpen(true)}
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                  }}
                  startIcon={<AddIcon />}
                >
                  Submit Claim
                </Button>
              )}

              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowFilter(() => !showFilter)}
                className="!border-primary !text-primary hover:!bg-primary/10"
                startIcon={<FilterAltOutlinedIcon />}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        </div>

        {/* search-filter */}
        <div className="">
          {/* <div className="search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search"
            />
          </div> */}

          <Filter
            refetch={refetch}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setPageNo={setPageNo}
            setLimit={setLimit}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setLoading={setLoading}
            setRefetchFilterVariables={setRefetchFilterVariables}
            pageNo={pageNo}
            limit={limit}
            setSubmitClaimData={setSubmitClaimData}
            setTotalPages={setTotalPages}
            setTotalSubmits={setTotalSubmits}
          />
        </div>

        {/* table */}
        <div className="super-git-table">
          <table className="table-super-git">
            <thead className="!p-0">
              <tr className="!p-0">
                <th className="!p-2">
                  <input
                    id="offers"
                    aria-describedby="offers-description"
                    name="offers"
                    type="checkbox"
                    className="rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                    onChange={(event) => handleSelectAll(event)}
                  />
                </th>
                {tableHeaders.map((el, index) => {
                  return (
                    <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                      {el.name}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {submitClaimData ? (
                submitClaimData?.map((el, index) => {
                  return (
                    <tr
                      key={"beneficiariesData_" + index}
                      // className="cursor-pointer"
                    >
                      <td className="!p-1 !text-sm">
                        <input
                          id="offers"
                          aria-describedby="offers-description"
                          name="offers"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                          onChange={(event) =>
                            handleCheckboxChange(event, el.id)
                          }
                          checked={selectedValues.includes(el.id)}
                        />
                      </td>
                      <td className="!p-1 !text-sm">
                        {pageNo * limit + index + 1 - limit}
                      </td>

                      <td className="!p-1 !text-sm">
                        {formatDateTime(el?.claim_date)}
                      </td>

                      <td className="!p-1 !text-sm" title={el?.type}>
                        {el?.type}
                      </td>

                      {/* 4 */}
                      <td className="!p-1 !text-sm" title={el?.patient_name}>
                        {el?.patient_document_id}
                      </td>

                      {/* 5 */}
                      <td className="!p-1 !text-sm capitalize">
                        {el?.payer_name?.length > 100 ? (
                          <Tooltip title={el?.payer_name}>
                            <span>{el?.payer_name.slice(0, 100)}...</span>
                          </Tooltip>
                        ) : (
                          el?.payer_name
                        )}
                      </td>

                      <td className="!p-1 !text-sm">{el?.date_ordered}</td>
                      <td className="!p-1 !text-sm">{el?.total_net}</td>
                      <td className="user-action flex gap-2 mx-2 justify-center !p-1">
                        <Tooltip title="View">
                          <div
                            onClick={(event) => onDataRowClicked(event, el)}
                            className="text-xl"
                          >
                            <MdOutlineRemoveRedEye />
                          </div>
                        </Tooltip>

                        <Tooltip title="Edit">
                          <div
                            onClick={() =>
                              navigate("/claims/edit/form/" + el.transaction_id)
                            }
                            className="text-xl"
                          >
                            <TbEditCircle />
                          </div>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <div
                            onClick={() => cancelClicked(el)}
                            className="text-xl"
                          >
                            <LuTrash2 />
                          </div>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colspan="6">
                    <div className="flex justify-center">
                      <h2 className="text-center text-lg">No Data Found</h2>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div>
          <Pagination
            currentPage={pageNo}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      </div>

      <AppModal isOpen={loading || dataLoading || addLoading} />
      {/* <ConformationModal
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onDelete={() => cancelClicked(item)}
        text={"Cancel"}
      /> */}

      {/* <ClaimReportModal
        open={reportOpen}
        setOpen={setReportOpen}
        handleClose={handleReportClose}
        handleClickOpen={handleReportOpen}
      /> */}

      <ClaimsSubmitModal
        open={submitOpen}
        setOpen={setSubmitOpen}
        handleClose={handleSubmitClose}
        handleClickOpen={handleSubmitOpen}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        refetch={refetch}
        data={data}
        setSubmitClaimData={setSubmitClaimData}
        setTotalPages={setTotalPages}
        setTotalSubmits={setTotalSubmits}
      />
    </>
  );

  return (
    <div className="my-8">
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card bg-white shadow">
              <div className="card-body rounded-md ">
                <div className="pb-2 md:flex md:items-center md:justify-between">
                  <h4 className="card-title">Claims Transactions</h4>
                  <div className="flex space-x-3 md:mt-0 md:ml-4">
                    <DownloadExcel
                      data={excelData}
                      fileName={"PreAuthorizationTransactions"}
                    />
                    <button
                      type="button"
                      onClick={() => setShowFilter(() => !showFilter)}
                      className=" inline-flex justify-center rounded-md border border-transparent bg-cyan-600 
                    py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 w-fit"
                    >
                      <FunnelIcon
                        className="h-3 w-3 text-white-900 text-xl hover:cursor-pointer  "
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
                {/* {showFilter && (
                  <SubmitClaimsFilter
                    refetch={refetch}
                    setPageNo={setPageNo}
                    setLimit={setLimit}
                    // refetchExcelDataResponse={refetchExcelDataResponse}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    setSelectedValues={setSelectedValues}
                    setLoading={setLoading}
                    setRefetchFilterVariables={setRefetchFilterVariables}
                  />
                )} */}
                {data?.listPayerClaims?.data.length > 0 &&
                  selectedValues.length > 0 && (
                    <div className="my-2">
                      <button
                        onClick={handleSubmitClaims}
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                      >
                        Submit Claims
                      </button>
                    </div>
                  )}
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>
                          <input
                            id="offers"
                            aria-describedby="offers-description"
                            name="offers"
                            type="checkbox"
                            className="rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                            onChange={(event) => handleSelectAll(event)}
                          />
                        </th>
                        {tableHeaders.map((el, index) => {
                          return (
                            <th className="py-2" key={"tableHeaders_" + index}>
                              {el.name}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    {(loading || dataLoading) && <p>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    <tbody>
                      {data?.listPayerClaims?.data?.map((el, index) => {
                        return (
                          <tr
                            key={"claims_" + index}
                            onClick={(event) => onDataRowClicked(event, el)}
                            className="cursor-pointer"
                          >
                            <td>
                              <input
                                id="offers"
                                aria-describedby="offers-description"
                                name="offers"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                                onChange={(event) =>
                                  handleCheckboxChange(event, el.id)
                                }
                                checked={selectedValues.includes(el.id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{formatDateTime(el.ClaimDate)}</td>
                            <td>{el.PhysicianName}</td>
                            <td>{el.PatientDocumentId}</td>
                            <td>{el.TotalNet}</td>
                            <td>
                              <div className="dropdown">
                                <div
                                  id="dropdownMenuIconButton1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="ti-arrow-circle-down"></i>
                                  {/* <i className="mdi mdi-arrow-down-drop-circle"></i> */}
                                </div>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuIconButton1"
                                >
                                  <h6
                                    className="dropdown-header"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => cancelClicked(el)}
                                  >
                                    Cancel
                                  </h6>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <AppErrorAlert data={errorData} />
                {data?.listPayerClaims?.data?.length > 0 && (
                  <div className="mt-5">
                    <Pagination
                      currentPage={pageNo}
                      totalPages={data?.listPayerClaims?.Pages}
                      onPageChange={onPageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <ClaimsResponseModal
        showPreAuthResponseModal={showPreAuthResponseModal}
        setShowPreAuthResponseModal={setShowPreAuthResponseModal}
        currentItem={item}
      /> */}
    </div>
  );
}

export default SubmitClaims;

// return (
//   <>

//     <div className="pre_auth_container ">
//       <div className="pre_auth_req_page !p-0">

//         <div className="pre_auth_content">  ---commentWhole
//           <div className="pre_auth_title">
//             <span>Submit Claims Transactions</span>
//             <div className="track_pre_auth">
//               <span>Keep track of your Claims Transactions</span>
//             </div>
//           </div>
//           <div className="create_pre_auth">
//             <DownloadExcel data={excelData} fileName={"ClaimsTransactions"} />
//           </div>
//           {data?.listPayerClaims?.data.length > 0 &&
//             selectedValues.length > 0 && (
//               <div className="create-btn" onClick={handleSubmitClaims}>
//                 <span>Submit Claims</span>
//               </div>
//             )}
//         </div>

//         <div className="personal-data">
//           <div className="personal-fields gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
//             <div class="pre_auth_checkbox my-auto">
//               <div class="toggle-button-ref flex gap-6">
//                 <input
//                   type="checkbox"
//                   name="is_batch"
//                   onChange={() =>
//                     formik.setFieldValue("is_batch", !formik.values.is_batch)
//                   }
//                   id="is_batch"
//                   checked={formik.values.is_batch}
//                 />
//                 <label htmlFor="is_batch"></label>
//                 <span>Is Batch</span>
//               </div>
//             </div>
//             <div className="flex flex-col gap-1">
//               <label htmlFor="batch_start_date">Batch Start Date*</label>
//               <input
//                 placeholder="Batch Start Date*"
//                 type="date"
//                 name="batch_start_date"
//                 value={formik.values.batch_start_date}
//                 onChange={formik.handleChange}
//                 onFocus={(e) => (e.target.type = "date")}
//                 onBlur={(e) => (e.target.type = "text")}
//                 disabled={!formik.values.is_batch}
//                 id="batch_start_date"
//                 autoComplete="off"
//                 className="dob"
//               />

//               {formik.errors.batch_start_date &&
//               formik.touched.batch_start_date ? (
//                 <p className=" max-w-2xl text-sm leading-6 text-red-500">
//                   {formik.errors.batch_start_date}
//                 </p>
//               ) : null}
//             </div>

//             <div className="flex flex-col gap-1">
//               <label htmlFor="batch_end_date">Batch End Date*</label>
//               <input
//                 placeholder="Batch End Date*"
//                 type="date"
//                 name="batch_end_date"
//                 value={formik.values.batch_end_date}
//                 onChange={formik.handleChange}
//                 onFocus={(e) => (e.target.type = "date")}
//                 onBlur={(e) => (e.target.type = "text")}
//                 disabled={!formik.values.is_batch}
//                 id="batch_end_date"
//                 autoComplete="off"
//                 className="dob"
//               />

//               {formik.errors.batch_end_date &&
//               formik.touched.batch_end_date ? (
//                 <p className=" max-w-2xl text-sm leading-6 text-red-500">
//                   {formik.errors.batch_end_date}
//                 </p>
//               ) : null}
//             </div>

//             handleSubmitClaims  ---comment

//             <div className="mt-auto">
//               {data?.listPayerClaims?.data.length > 0 &&
//                 selectedValues.length > 0 && (
//                   <div className="create-btn" onClick={formik.handleSubmit}>
//                     <span>Submit Claims</span>
//                   </div>
//                 )}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="search-filter">

//         <div className="search">   ---commentWholw
//           <input
//             type="search"
//             name="search"
//             id="search"
//             placeholder="Search"
//           />
//         </div>

//         <div className="flex gap-2 my-auto">
//           <div className="font-semibold text-lg">Entries:</div>
//           <div className="font-semibold text-lg">
//             {data?.listPayerClaims?.total}
//           </div>
//         </div>
//         <div className="apply-filter">
//           <div
//             className="btn"
//             onClick={() => setShowFilter(() => !showFilter)}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="20"
//               height="20"
//               viewBox="0 0 20 20"
//               fill="none"
//             >
//               <path
//                 d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
//                 fill="black"
//               />
//             </svg>
//             Apply Filter
//           </div>
//         </div>
//         <Filter
//           refetch={refetch}
//           showFilter={showFilter}
//           setShowFilter={setShowFilter}
//           setPageNo={setPageNo}
//           setLimit={setLimit}

//           refetchExcelDataResponse={refetchExcelDataResponse} ---comment

//           setFromDate={setFromDate}
//           setToDate={setToDate}
//           setLoading={setLoading}
//           setRefetchFilterVariables={setRefetchFilterVariables}
//         />
//       </div>
//       <div className="super-git-table">
//         <table className="table-super-git">
//           <thead>
//             <tr>
//               <th>
//                 <input
//                   id="offers"
//                   aria-describedby="offers-description"
//                   name="offers"
//                   type="checkbox"
//                   className="rounded border-gray-300 text-amber-600 focus:ring-amber-600"
//                   onChange={(event) => handleSelectAll(event)}
//                 />
//               </th>
//               {tableHeaders.map((el, index) => {
//                 return (
//                   <th className="" key={"tableHeaders_" + index}>
//                     {el.name}
//                   </th>
//                 );
//               })}
//             </tr>
//           </thead>

//           {(loading || dataLoading) && <p>Loading...</p>}  ---comment
//           {error && <p>Error: {error.message}</p>}    ---comment

//           <tbody>
//             {data?.listPayerClaims?.data ? (
//               data?.listPayerClaims?.data?.map((el, index) => {
//                 return (
//                   <tr
//                     key={"beneficiariesData_" + index}
//                     className="cursor-pointer"
//                   >
//                     <td>
//                       <input
//                         id="offers"
//                         aria-describedby="offers-description"
//                         name="offers"
//                         type="checkbox"
//                         className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
//                         onChange={(event) =>
//                           handleCheckboxChange(event, el.id)
//                         }
//                         checked={selectedValues.includes(el.id)}
//                       />
//                     </td>
//                     <td>{pageNo * limit + index + 1 - limit}</td>
//                     <td>{formatDateTime(el?.claim_date)}</td>
//                     <td>{el?.patient_name}</td>
//                     <td>{el?.payer_name}</td>
//                     <td>{el?.patient_document_id}</td>
//                     <td>{el?.total_net}</td>
//                     <td className="user-action flex gap-2 mx-2 justify-end">
//                       <Tooltip title="View">
//                         <div
//                           onClick={(event) => onDataRowClicked(event, el)}
//                           className="text-xl"
//                         >
//                           <MdOutlineRemoveRedEye />
//                         </div>
//                       </Tooltip>

//                       <Tooltip title="Edit">
//                         <div
//                           onClick={() =>
//                             navigate("/claims/edit/form/" + el.transaction_id)
//                           }
//                           className="text-xl"
//                         >
//                           <TbEditCircle />
//                         </div>
//                       </Tooltip>

//                       <Tooltip title="Delete">
//                         <div
//                           onClick={() => cancelClicked(el)}
//                           className="text-xl"
//                         >
//                           <LuTrash2 />
//                         </div>
//                       </Tooltip>
//                     </td>
//                   </tr>
//                 );
//               })
//             ) : (
//               <tr>
//                 <td colspan="6">
//                   <div className="flex justify-center">
//                     <h2 className="text-center text-lg">No Data Found</h2>
//                   </div>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//       <div>
//         <Pagination
//           currentPage={pageNo}
//           totalPages={data?.listPayerClaims?.pages}
//           onPageChange={onPageChange}
//         />
//       </div>
//     </div>

//     <AppModal isOpen={loading || dataLoading || addLoading} />

//     <ConformationModal   ---commentWhole
//       open={showConfirmModal}
//       setOpen={setShowConfirmModal}
//       onDelete={() => cancelClicked(item)}
//       text={"Cancel"}
//     />

//   </>
// );
