import React, { useState } from "react";
import Modal from "react-modal";
import { Formik } from "formik";

import { Switch } from "@headlessui/react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { XMarkIcon } from "@heroicons/react/20/solid";
import eligibility from "../../../gql/eligibility";
import { useQuery } from "@apollo/client";
import CommunicationDetails from "./CommunicationDetails";
import ResponseDetailsComponent from "./response/ResponseDetailsComponent";
import ItemClassesAndBenefeciaries from "./response/ItemClassesAndBenefeciaries";
import ResponseDetailsItemsAccordian from "./response/ResponseDetailsItemsAccordian";
import JsonTable from "./response/JsonTable";
import ItemsList from "./response/ItemsList";
import CareTeamsList from "./response/CareTeamsList";
import DiagnosisList from "./response/DiagnosisList";
import UcafForm from "./ucaf/UcafForm";
import claimsGQL from "../../../gql/claims";
import TotalItem from "./TotalItem";
import SupportingInfo from "./response/SupportingInfo";
import Encounter from "./response/Encounter";
import AccidentInfo from "./response/AccidentInfo";
import VisionPrescription from "./response/VisionPrescription";
import Validations from "./response/Details/Validations";

import "../styles/pre-auth.css";
import AppModal from "../../../components/AppModal";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DcafForm from "./ucaf/DcafForm";
import ItemRejectReason from "./ItemRejectReason";
import { TbEditCircle } from "react-icons/tb";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "95vh",
    minWidth: "85%",
    maxHeight: "75vh",
    overflowY: "scroll",
    paddingTop: "0 !important",
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let subtitle;
const ClaimsDetails = ({}) => {
  const currentDate = new Date();
  const navigate = useNavigate();
  const location = useLocation();
  const currentItem = location.state?.currentItem
    ? location.state.currentItem
    : null;

  const { id } = useParams();

  const [showUCAF, setShowUCAF] = useState(false);
  const [showDCAF, setShowDCAF] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [myFirstName, setmyFirstName] = useState(false);
  const [showInfoItem, setshowInfoItem] = useState(false);
  const [currentTab, setCurrentTab] = useState("details");
  const [expanded, setExpanded] = React.useState("detail");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const CustomTabs = (props) => (
    <Tabs
      {...props}
      sx={{
        "& .MuiTabs-indicator": {
          backgroundColor: "#ffab2c", // Customize indicator color
        },
        "& .Mui-selected": {
          color: "#ffab2c", // Customize text color for selected tab
        },
        "& .MuiTabs-flexContainer": {
          "& > *": {
            color: "black", // Set default text color to black
          },
        },
      }}
    />
  );

  const {
    loading: loadingDetails,
    error: detailsError,
    data: detailsData,
    refetch: refetchDetails,
  } = useQuery(claimsGQL.GET_CLAIMS_TRAN_DETAILS, {
    variables: {
      transactionId: id ? id : currentItem?.transaction_id,
    },
  });

  const {
    loading: loadingSupportingInfo,
    error: errorSupportingInfo,
    data: details,
    refetch: refetchSupportingInfo,
  } = useQuery(claimsGQL.GET_CLAIMS_TRAN_DETAILS_SI, {
    variables: {
      transactionId: id,
    },
    skip: expanded !== "supportinginfo" || true,
  });

  function afterOpenModal() {}

  const tabs = [
    { label: "Details", value: "details" },
    { label: "Communications", value: "communications" },
    // { name: "JSON", href: "#", current: false },
    { label: "Encounter", value: "encounter" },
    { label: "Accident", value: "accident" },
    { label: "Vision Prescription", value: "visionPrescription" },
    { label: "Validations", value: "validations" },
    { label: "Reject Reason", value: "rejectReason" },
  ];

  const types = [
    { id: 1, name: "HCP" },
    { id: 2, name: "Public HCP" },
  ];

  const tableHeaders = [
    { name: "Payer" },
    { name: "Expiry Date" },
    { name: "Member Card ID" },
    { name: "Policy Number" },
    { name: "Relation With Subscriber" },
  ];
  let firstName = "Payer";

  const [eligibilityResponseData, setEligibilityResponseData] = useState([
    {
      id: 1,
      InForce: "Yes",
      BenefitDate: "01/01/2023 - 31/12/2023",
      MemberId: 35444349,
      PolicyNumber: 22001814,
      Type: "Extended HealthCare",
      Status: true,
      RelationWithSubscriber: "self",
      SubscriberMemberId: "",
      Network: "0222",
      Subrogation: false,
      SiteEligibility: "eligible",
      visible: false,
    },
  ]);

  const claimsItem = {
    payer: "XYZ",
    expiryDate: "23/3/2024",
    memberCardId: "12223",
    policyNumber: "12223",
    relationShipWithSubscriber: "ssdd",
  };

  return (
    <div class="pre_auth_detail_page">
      <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
        <div class="flex !gap-5">
          <div class="back my-auto" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                fill="#4D4D53"
              ></path>
            </svg>
          </div>

          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your Claim details"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-lg ">
                Claim Details
                <br />
                <span className="text-base">
                  Claim:{" "}
                  <span className="font-medium">
                    {/* {detailsData?.getClaimTransaction?.data?.transaction_id} */}
                    {detailsData?.getClaimTransaction?.data?.transaction_id}
                  </span>
                </span>
                {/* <span className="italic text-base ml-1">
                    ({data?.listPayerPriceList?.total || 0}{" "}
                    <span className="text-xs font-bold italic">Entries</span>)
                  </span> */}
              </span>
            </Tooltip>
          </div>
        </div>

        <div className="my-auto">
          <div class=" flex gap-3 flex-col md:flex-row">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigate(
                  `/claims/edit/form/${detailsData?.getClaimTransaction?.data?.transaction_id}`
                );
              }}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<TbEditCircle />}
            >
              Edit Claim
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() =>
                detailsData?.getClaimTransaction?.data?.type === "oral"
                  ? setShowDCAF(true)
                  : setShowUCAF(true)
              }
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<AddIcon />}
            >
              {detailsData?.getClaimTransaction?.data?.type === "oral"
                ? "DCAF"
                : "UCAF"}
            </Button>

            {detailsData?.getClaimTransaction?.data?.is_followup_req &&
            detailsData?.getClaimTransaction?.data?.status === "rejected" ? (
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate(
                    `/claims/edit/form/${
                      detailsData?.getClaimTransaction?.data?.transaction_id
                    }/${"follow-up"}`
                  );
                }}
                className="!border-primary !text-primary hover:!bg-primary/10"
                startIcon={<AddIcon />}
              >
                Follow Up
              </Button>
            ) : !detailsData?.getClaimTransaction?.data?.is_followup_req ? (
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate(
                    `/claims/edit/form/${
                      detailsData?.getClaimTransaction?.data?.transaction_id
                    }/${"follow-up"}`
                  );
                }}
                className="!border-primary !text-primary hover:!bg-primary/10"
                startIcon={<AddIcon />}
              >
                Follow Up
              </Button>
            ) : null}
          </div>
        </div>
      </div>

      <div class="pre_auth_details_nav_menu !flex !justify-center flex-col lg:flex-row gap-8 !mt-2">
        <div class="pre_auth_details_nav_menu_">
          {/* <ul>
            {tabs.map((tab) => (
              <li
                className={currentTab == tab.name ? "active" : ""}
                onClick={() => setCurrentTab(tab.name)}
              >
                {tab.name}
              </li>
            ))}
          </ul> */}
          <Box
            sx={{ maxWidth: { xs: 320, sm: 480, md: 720, lg: 980, xl: 1260 } }}
          >
            <CustomTabs
              value={currentTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {tabs.map((tab, i) => (
                <Tab key={i} value={tab.value} label={tab.label} />
              ))}
            </CustomTabs>
          </Box>
        </div>
      </div>

      <>
        {currentTab == "details" && (
          <>
            <Accordion
              expanded={expanded === "detail"}
              onChange={handleChange("detail")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "detail" ? "!bg-primary/80" : "!bg-white"
                } !rounded-t-lg !border-t-0`}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">Detail</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ResponseDetailsComponent
                    item={detailsData?.getClaimTransaction?.data}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* <Accordion
            expanded={expanded === "total"}
            onChange={handleChange("total")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={`${
                expanded === "total" ? "!bg-primary/80" : "!bg-white"
              } `}
            >
              <Typography
                className=" flex gap-3"
                sx={{ width: "50%", flexShrink: 0 }}
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                  <path
                    d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                    fill="white"
                  ></path>
                  <path
                    d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                    fill="white"
                  ></path>
                  <path
                    d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                    fill="white"
                  ></path>
                </svg>
                <span className="!font-semibold my-auto text-lg"> Total</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <TotalItem item={detailsData?.getClaimTransaction?.Data} />
              </Typography>
            </AccordionDetails>
          </Accordion> */}

            <Accordion
              expanded={expanded === "items"}
              onChange={handleChange("items")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "items" ? "!bg-primary/80" : "!bg-white"
                } `}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg"> Items</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ItemsList item={detailsData} />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "careteam"}
              onChange={handleChange("careteam")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "careteam" ? "!bg-primary/80" : "!bg-white"
                } `}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">
                    {" "}
                    Care Teams
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <CareTeamsList item={detailsData} />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "diagnosis"}
              onChange={handleChange("diagnosis")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "diagnosis" ? "!bg-primary/80" : "!bg-white"
                } `}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">
                    {" "}
                    Diagnosis
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <DiagnosisList item={detailsData} />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "supportinginfo"}
              onChange={handleChange("supportinginfo")}
              className="!border-none"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={` ${
                  expanded === "supportinginfo"
                    ? "!bg-primary/80"
                    : "!rounded-b-lg !bg-white"
                } !border-b-0`}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">
                    Supporting Info
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <SupportingInfo item={detailsData} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {currentTab == "JSON" && (
          <div className="claims-form ">
            <div>
              <JsonTable item={detailsData} />
            </div>
          </div>
        )}
        {currentTab == "communications" && (
          <>
            <CommunicationDetails
              currentItem={currentItem}
              item={detailsData}
            />
          </>
        )}
        {currentTab == "encounter" && (
          <>
            <Encounter
              item={detailsData?.getClaimTransaction?.data?.encounter}
            />
          </>
        )}
        {currentTab == "validations" && (
          <>
            <Validations item={detailsData?.getClaimTransaction?.data} />
          </>
        )}
        {currentTab == "visionPrescription" && (
          <>
            <VisionPrescription item={detailsData?.getClaimTransaction?.data} />
          </>
        )}
        {currentTab == "accident" && (
          <>
            {/* <div className="py-2">
                <h4 className="font-bold text-xl text-cyan-600">
                  Accident Info.
                </h4>
              </div>
              <hr /> */}
            <AccidentInfo item={detailsData?.getClaimTransaction?.data} />
          </>
        )}
        {currentTab == "rejectReason" && (
          <>
            <ItemRejectReason item={detailsData?.getClaimTransaction?.data} />
          </>
        )}
      </>

      <UcafForm
        item={detailsData?.getClaimTransaction?.data}
        showUCAF={showUCAF}
        setShowUCAF={setShowUCAF}
      />
      <DcafForm
        item={detailsData?.getClaimTransaction?.data}
        showDCAF={showDCAF}
        setShowDCAF={setShowDCAF}
      />
      <AppModal isOpen={loadingDetails || loadingSupportingInfo} />
    </div>
  );

  return (
    <>
      <div className="pt-2  ">
        <div className="  sticky pt-2 -top-10  bg-white">
          <div className="flex justify-between items-center py-1  ">
            <div>
              <h2 className="font-bold text-xl text-cyan-600">
                Claims -{detailsData?.getClaimTransaction?.Data?.TransactionId}
              </h2>
            </div>
          </div>
          {/* TABS */}
          <div className="py-1  ">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                onChange={(e) => setCurrentTab(e.target.value)}
                className="block w-full rounded-md border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex space-x-4" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      currentTab == tab.name
                        ? "bg-cyan-100 text-cyan-600"
                        : "text-gray-500 hover:text-gray-700",
                      "px-3 py-2 font-medium text-sm rounded-md"
                    )}
                    onClick={(e) => setCurrentTab(tab.name)}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <hr />
        {loadingDetails ? (
          <p>Loading...</p>
        ) : (
          <>
            {currentTab == "Details" && (
              <div className="claims-form  ">
                <form className=" divide-gray-200">
                  <div className=" mt-2">
                    <div className="">
                      <ResponseDetailsComponent
                        item={detailsData?.getClaimTransaction?.Data}
                      />
                    </div>

                    <hr />
                  </div>

                  <TotalItem item={detailsData?.getClaimTransaction?.Data} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">Items</h4>
                  </div>
                  <hr />

                  <ItemsList item={detailsData} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">
                      Care Teams
                    </h4>
                  </div>
                  <hr />
                  <CareTeamsList item={detailsData} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">
                      Diagnosis
                    </h4>
                  </div>

                  <hr />

                  <DiagnosisList item={detailsData} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">
                      Supporting Info
                    </h4>
                  </div>
                  <hr />
                  <SupportingInfo item={detailsData} />

                  <div className="bottom-list sticky bottom-2 flex justify-end   right-20">
                    <div className="flex justify-end gap-2  my-4 w-fit bg-gray-100 p-2 ">
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => setShowUCAF(true)}
                      >
                        UCAF
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => {
                          //   setShowPreAuthResponseModal(false);
                          navigate("/pre-auth/form", {
                            state: {
                              item: detailsData?.getClaimTransaction?.Data,
                            },
                          });
                        }}
                      >
                        Follow Up
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {currentTab == "JSON" && (
              <div className="claims-form ">
                <div>
                  <JsonTable item={detailsData} />
                </div>
              </div>
            )}
            {currentTab == "Communications" && (
              <>
                <CommunicationDetails
                  currentItem={currentItem}
                  item={detailsData}
                />
              </>
            )}
            {currentTab == "Encounter" && (
              <>
                <div className="py-2">
                  <h4 className="font-bold text-xl text-cyan-600">Encounter</h4>
                </div>
                <hr />
                <Encounter
                  item={detailsData?.getClaimTransaction?.Data?.Encounter}
                />
              </>
            )}
            {currentTab == "VisionPrescription" && (
              <>
                <VisionPrescription
                  item={detailsData?.getClaimTransaction?.Data}
                />
              </>
            )}
            {currentTab == "Accident" && (
              <>
                <div className="py-2">
                  <h4 className="font-bold text-xl text-cyan-600">
                    Accident Info.
                  </h4>
                </div>
                <hr />
                <AccidentInfo item={detailsData?.getClaimTransaction?.Data} />
              </>
            )}
          </>
        )}
      </div>
      <UcafForm
        item={detailsData?.getClaimTransaction?.Data}
        showUCAF={showUCAF}
        setShowUCAF={setShowUCAF}
      />
    </>
  );
};

export default ClaimsDetails;
